import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import auth0Client from './Auth/Auth';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './components/Home/Home';
import Callback from './components/Callback/Callback';
import SecuredRoute from './components/SecuredRoute/SecuredRoute';
import Dashboard from './components/Dashboard/Dashboard';
import FacultyProfile from './components/FacultyProfile/FacultyProfile';
import StudentProfile from './components/StudentProfile/StudentProfile';
import FacilityProfile from './components/FacilityProfile/FacilityProfile';
import ClinicalSuperProfile from './components/ClinicalSuperProfile/ClinicalSuperProfile';
// import Account from './components/Account/Account';
import asFacultyReadout from './components/Forms/asFacultyReadout/asFacultyReadout';
import ASE from './components/Forms/ase/ASE';
// import DRE from './components/Forms/dre/DRE';
// import PSE from './components/Forms/pse/PSE';
import saceFacultyReadout from './components/Forms/saceFacultyReadout/saceFacultyReadout';
import saceFacultyReadoutV2 from './components/Forms/saceFacultyReadoutV2/saceFacultyReadoutV2';
// import SAD from './components/Forms/sad/SAD';
import sprFacultyReadout from './components/Forms/sprFacultyReadout/sprFacultyReadout';
// import SSS from './components/Forms/sss/SSS';

import './App.scss';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkingSession: true,
    };
  }

  async componentDidMount() {
    const { location } = this.props;
    if (location.pathname === '/callback') {
      this.setState({ checkingSession: false });
      return;
    }
    try {
      await auth0Client.silentAuth();
      this.forceUpdate();
    } catch (err) {
      // eslint-disable-next-line no-console
      if (err.error !== 'login_required') console.log(err.error);
    }
    this.setState({ checkingSession: false });
  }

  render() {
    const { checkingSession } = this.state;
    return (
      <div className="main-container">
        <Header />
        <Route exact path="/" component={Home} />
        <Route exact path="/callback" component={Callback} />
        {/* <Route exact path="/test" component={PSE} /> */}
        <SecuredRoute
          exact
          path="/dashboard"
          component={Dashboard}
          checkingSession={checkingSession}
        />
        <SecuredRoute
          exact
          path="/student/:studentid"
          component={StudentProfile}
          checkingSession={checkingSession}
        />
        <SecuredRoute
          exact
          path="/faculty/:faculty"
          component={FacultyProfile}
          checkingSession={checkingSession}
        />
        <SecuredRoute
          exact
          path="/facility/:facilityid"
          component={FacilityProfile}
          checkingSession={checkingSession}
        />
        <SecuredRoute
          exact
          path="/clinicalSupervisor/:superid"
          component={ClinicalSuperProfile}
          checkingSession={checkingSession}
        />
        <SecuredRoute
          exact
          path="/form/AS/:formid"
          component={asFacultyReadout}
          checkingSession={checkingSession}
        />
        <SecuredRoute
          exact
          path="/form/ASE/:formid"
          component={ASE}
          checkingSession={checkingSession}
        />
        {/* <SecuredRoute
          exact
          path="/form/DRE/:formid"
          component={DRE}
          checkingSession={checkingSession}
        /> */}
        {/* <SecuredRoute
          exact
          path="/form/PSE/:formid"
          component={PSE}
          checkingSession={checkingSession}
        /> */}
        <SecuredRoute
          exact
          path="/form/SACE/:formid"
          component={saceFacultyReadout}
          checkingSession={checkingSession}
        />
        <SecuredRoute
          exact
          path="/form/SACEv2/:formid"
          component={saceFacultyReadoutV2}
          checkingSession={checkingSession}
        />
        {/* <SecuredRoute
          exact
          path="/form/SAD/:formid"
          component={SAD}
          checkingSession={checkingSession}
        /> */}
        <SecuredRoute
          exact
          path="/form/SPR/:formid"
          component={sprFacultyReadout}
          checkingSession={checkingSession}
        />
        {/* <SecuredRoute
          exact
          path="/form/SSS/:formid"
          component={SSS}
          checkingSession={checkingSession}
        /> */}
        <Footer />
      </div>
    );
  }
}

App.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withRouter(App);
