/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint class-methods-use-this: ["error", { "exceptMethods": ["renderLoading"] }] */

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Table,
  Container,
  Row,
  Col,
  Collapse,
  CardBody,
  Card,
  Button,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import axios from 'axios';
import auth0Client from '../../Auth/Auth';
import Widget from '../Widget/Widget';

import '../Forms/forms.scss';

import iUser from '../../images/facility.png';
import loadingSpinner from '../../images/Eclipse-1s-200px.svg';

class FacilityProfile extends React.Component {
  constructor(props) {
    super(props);

    this.getFacilityInformation = this.getFacilityInformation.bind(this);
    this.renderProfile = this.renderProfile.bind(this);
    this.renderLoading = this.renderLoading.bind(this);
    this.toggleSupervisors = this.toggleSupervisors.bind(this);
    this.toggleStudents = this.toggleStudents.bind(this);

    this.state = {
      collapseSupervisors: false,
      collapseStudents: false,
      loading: true,
    };
  }

  componentDidMount() {
    this.getFacilityInformation();
  }

  getFacilityInformation() {
    const {
      match: { params },
    } = this.props;

    const url = `${process.env.REACT_APP_API_URL}/facility/${params.facilityid}`;

    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        const { name, department, address, address2, city, state, zip } = response.data;
        console.log(response.data);
        this.setState({
          name,
          department,
          address,
          address2,
          city,
          state,
          zip,
          loading: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  toggleSupervisors() {
    this.setState((state) => ({ collapseSupervisors: !state.collapseSupervisors }));
  }

  toggleStudents() {
    this.setState((state) => ({ collapseStudents: !state.collapseStudents }));
  }

  renderProfile() {
    const {
      name,
      department,
      address,
      address2,
      city,
      state,
      zip,
      collapseSupervisors,
      collapseStudents,
    } = this.state;
    return (
      <Container fluid>
        <Row>
          <Col lg={{ size: 10, offset: 1 }}>
            <div className="crumbs">
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to="/dashboard">Dashboard</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>Facility Profile</BreadcrumbItem>
              </Breadcrumb>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={{ size: 10, offset: 1 }}>
            <h2 className="page-title">Facility Profile</h2>
          </Col>
        </Row>
        <Row>
          <Col lg={{ size: 10, offset: 1 }}>
            <Widget>
              <Row>
                <Col md={4}>
                  <div className="text-center">
                    <img
                      className="rounded-circle"
                      style={{ height: `${112} + px` }}
                      src={iUser}
                      alt="64x64"
                    />
                  </div>
                </Col>
                <Col md={8}>
                  <h3 className="mt-md mb-xs">{`${name}`}</h3>
                  <Table hover>
                    <tbody>
                      <tr>
                        <th scope="row">Department:</th>
                        <td width="5" />
                        <td>{department}</td>
                      </tr>
                      <tr>
                        <th scope="row">Address:</th>
                        <td />
                        <td>{address}</td>
                      </tr>
                      <tr>
                        <th scope="row">Address 2:</th>
                        <td />
                        <td>{address2}</td>
                      </tr>
                      <tr>
                        <th scope="row">City:</th>
                        <td />
                        <td>{city}</td>
                      </tr>
                      <tr>
                        <th scope="row">State:</th>
                        <td />
                        <td>{state}</td>
                      </tr>
                      <tr>
                        <th scope="row">Zip:</th>
                        <td />
                        <td>{zip}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row>
                <Col lg={{ size: 10, offset: 1 }}>
                  <Button
                    color="inverse"
                    size="lg"
                    onClick={this.toggleSupervisors}
                    style={{ marginBottom: '1rem' }}
                    block
                  >
                    Clinical Supervisors
                  </Button>
                  <Collapse isOpen={collapseSupervisors}>
                    <Card>
                      <CardBody className="text-center">
                        <strong>Facility currently has no assigned Clinical Supervisors</strong>
                      </CardBody>
                    </Card>
                  </Collapse>
                </Col>
              </Row>
              <Row>
                <Col lg={{ size: 10, offset: 1 }}>
                  <Button
                    color="inverse"
                    size="lg"
                    onClick={this.toggleStudents}
                    style={{ marginBottom: '1rem' }}
                    block
                  >
                    Students
                  </Button>
                  <Collapse isOpen={collapseStudents}>
                    <Card>
                      <CardBody className="text-center">
                        <strong>Facility currently has no assigned Students</strong>
                      </CardBody>
                    </Card>
                  </Collapse>
                </Col>
              </Row>
            </Widget>
          </Col>
        </Row>
      </Container>
    );
  }

  renderLoading() {
    return (
      <Container>
        <Row>
          <Col lg={{ size: 10, offset: 1 }}>
            <div className="crumbs">
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to="/dashboard">Dashboard</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>Facility Profile</BreadcrumbItem>
              </Breadcrumb>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={{ size: 10, offset: 1 }}>
            <h2 className="page-title">Facility Profile</h2>
          </Col>
        </Row>
        <Row className="text-center">
          <Col>
            <img src={loadingSpinner} alt="loading spinner" className="img-responsive" />
          </Col>
        </Row>
      </Container>
    );
  }

  render() {
    const { loading } = this.state;

    if (!loading) {
      return <div>{this.renderProfile()}</div>;
    }
    return <div>{this.renderLoading()}</div>;
  }
}

FacilityProfile.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withRouter(FacilityProfile);
