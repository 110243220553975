/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint class-methods-use-this: ["error", { "exceptMethods": ["renderLoading"] }] */

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Table,
  Container,
  Row,
  Col,
  Collapse,
  CardBody,
  Card,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  // Input,
} from 'reactstrap';
import axios from 'axios';
import auth0Client from '../../Auth/Auth';
import Widget from '../Widget/Widget';

import '../Forms/forms.scss';

import iUser from '../../images/user.png';
import loadingSpinner from '../../images/Eclipse-1s-200px.svg';

class ClinicalSuperProfile extends React.Component {
  constructor(props) {
    super(props);

    this.getClinicalSuperInformation = this.getClinicalSuperInformation.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.renderProfile = this.renderProfile.bind(this);
    this.renderLoading = this.renderLoading.bind(this);

    this.state = {
      collapseForms: true,
      collapseFacilities: true,
      loading: true,
    };
  }

  componentDidMount() {
    this.getClinicalSuperInformation();
  }

  getClinicalSuperInformation() {
    const {
      match: { params },
    } = this.props;

    const url = `${process.env.REACT_APP_API_URL}/clinical-supervisor/${params.superid}`;

    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        const { firstName, lastName, title, email, phone, facilities, forms } = response.data;
        console.log(response.data);
        this.setState({
          firstName,
          lastName,
          title,
          email,
          phone,
          facilities,
          forms,
          loading: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  toggleCollapse(name) {
    this.setState((state) => ({ [name]: !state[name] }));
  }

  renderProfile() {
    const {
      firstName,
      lastName,
      title,
      email,
      phone,
      // facilities,
      forms,
      collapseForms,
      collapseFacilities,
    } = this.state;
    return (
      <Container fluid>
        <Row>
          <Col lg={{ size: 10, offset: 1 }}>
            <div className="crumbs">
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to="/dashboard">Dashboard</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>Clinical Supervisor Profile</BreadcrumbItem>
              </Breadcrumb>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={{ size: 10, offset: 1 }}>
            <h2 className="page-title">Clinical Supervisor Profile</h2>
          </Col>
        </Row>
        <Row>
          <Col lg={{ size: 10, offset: 1 }}>
            <Widget>
              <Row>
                <Col md={4}>
                  <div className="text-center">
                    <img
                      className="rounded-circle"
                      style={{ height: `${112} + px` }}
                      src={iUser}
                      alt="64x64"
                    />
                  </div>
                </Col>
                <Col md={8}>
                  <h3 className="mt-md mb-xs">{`${firstName} ${lastName}`}</h3>
                  <Table hover>
                    <tbody>
                      <tr>
                        <th scope="row">TITLE:</th>
                        <td />
                        <td>{title}</td>
                      </tr>
                      <tr>
                        <th scope="row">EMAIL:</th>
                        <td />
                        <td>{email[0].emailAddress}</td>
                      </tr>
                      <tr>
                        <th scope="row">PHONE:</th>
                        <td />
                        <td>{`${`${phone[0].phoneNumber.substring(
                          0,
                          3,
                        )}-${phone[0].phoneNumber.substring(3, 6)}-${phone[0].phoneNumber.substring(
                          6,
                          10,
                        )}`}`}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col lg={{ size: 10, offset: 1 }}>
                  <Button
                    color="inverse"
                    size="lg"
                    onClick={() => this.toggleCollapse('collapseForms')}
                    style={{ marginBottom: '1rem' }}
                    block
                  >
                    Forms
                  </Button>
                  <Collapse isOpen={collapseForms}>
                    <Card>
                      <CardBody>
                        <Row>
                          <Col>
                            {forms.length === 0 && (
                              <div>
                                <h5 className="text-danger">No Forms</h5>
                              </div>
                            )}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Collapse>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col lg={{ size: 10, offset: 1 }}>
                  <Button
                    color="inverse"
                    size="lg"
                    onClick={() => this.toggleCollapse('collapseFacilities')}
                    style={{ marginBottom: '1rem' }}
                    block
                  >
                    Facilities
                  </Button>
                  <Collapse isOpen={collapseFacilities}>
                    <Card>
                      <CardBody>
                        <Row>
                          <Col>
                            <div>
                              <h5 className="text-danger">No Facilities</h5>
                            </div>
                            {/* {forms.length === 0 && (
                              <div>
                                <h5 className="text-danger">Add Facility</h5>
                                <Input type="select" name="select" id="exampleSelect">
                                  <option>1</option>
                                  <option>2</option>
                                  <option>3</option>
                                  <option>4</option>
                                  <option>5</option>
                                </Input>
                              </div>
                            )} */}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Collapse>
                </Col>
              </Row>
            </Widget>
          </Col>
        </Row>
      </Container>
    );
  }

  renderLoading() {
    return (
      <Container>
        <Row>
          <Col lg={{ size: 10, offset: 1 }}>
            <div className="crumbs">
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to="/dashboard">Dashboard</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>Student Profile</BreadcrumbItem>
              </Breadcrumb>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={{ size: 10, offset: 1 }}>
            <h2 className="page-title">Student Profile</h2>
          </Col>
        </Row>
        <Row className="text-center">
          <Col>
            <img src={loadingSpinner} alt="loading spinner" className="img-responsive" />
          </Col>
        </Row>
      </Container>
    );
  }

  render() {
    const { loading } = this.state;

    if (!loading) {
      return <div>{this.renderProfile()}</div>;
    }
    return <div>{this.renderLoading()}</div>;
  }
}

ClinicalSuperProfile.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withRouter(ClinicalSuperProfile);
