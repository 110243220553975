/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'reactstrap';
import axios from 'axios';
import auth0Client from '../../Auth/Auth';
import Widget from '../Widget/Widget';

import loadingSpinner from '../../images/Eclipse-1s-200px.svg';
import iFacility from '../../images/facility.png';

class FacilityList extends React.Component {
  constructor(props) {
    super(props);
    this.getFacilities = this.getFacilities.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.renderFacilities = this.renderFacilities.bind(this);

    this.state = {
      facilityData: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.getFacilities();
  }

  handleRefresh(e) {
    e.target.blur();
    this.setState({ loading: true });
    this.getFacilities();
  }

  getFacilities() {
    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    const url = `${process.env.REACT_APP_API_URL}/facility`;
    axios
      .get(url, config)
      .then((response) => {
        const facilityData = response.data;
        console.log(facilityData);
        this.setState({ loading: false, facilityData });
      })
      .catch((error) => {
        console.error(error);
        // this.setState({ currentView: 'maintenance' });
      });
  }

  renderFacilities(idx) {
    const { facilityData } = this.state;
    let filteredFacilities = [];

    if (idx === 'even') {
      filteredFacilities = facilityData.filter(function filterEven(v, i) {
        // check the index is even
        return i % 2 === 0;
      });
    } else {
      filteredFacilities = facilityData.filter(function filterOdd(v, i) {
        // check the index is odd
        return i % 2 !== 0;
      });
    }

    return (
      <div>
        <ul className="newsList newsListNoHover">
          {filteredFacilities.map((facility) => (
            <li key={facility._id}>
              <img src={iFacility} alt="" className="rounded-circle float-left" />
              <div className="newsItemInfo">
                <div className="name">
                  <Link to={`/facility/${facility._id}`}>{facility.name}</Link>
                </div>
                <div className="position">{facility.department}</div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  render() {
    const { loading } = this.state;
    return (
      <div>
        <Widget
          title={
            <h5>
              {' '}
              <i className="fa fa-align-left" /> Facilities
              <Button className="pull-right" color="link" onClick={this.handleRefresh}>
                <i className="fa fa-repeat pull-right" />
              </Button>
            </h5>
          }
        >
          {loading && (
            <div>
              <Row className="text-center">
                <Col>
                  <img src={loadingSpinner} alt="loading spinner" className="img-responsive" />
                </Col>
              </Row>
            </div>
          )}
          {!loading && (
            <div>
              <Row className="mt-4">
                <Col md={6}>{this.renderFacilities('even')}</Col>
                <Col md={6}>{this.renderFacilities('odd')}</Col>
              </Row>
            </div>
          )}
        </Widget>
      </div>
    );
  }
}

export default FacilityList;
