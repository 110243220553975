/* eslint-disable react/function-component-definition */
import React from 'react';
import { Container, Navbar, NavbarBrand } from 'reactstrap';

import './Footer.scss';

function Footer() {
  return (
    <div className="footer">
      <Navbar>
        <Container className="brand-center justify-content-center">
          <NavbarBrand style={{ color: 'white', fontSize: '0.8em' }}>
            <h6>
              Student Evaluation System ~ all rights reserved ~ designed + developed by chris &
              brandon
            </h6>
          </NavbarBrand>
        </Container>
      </Navbar>
    </div>
  );
}

export default Footer;
