/* eslint-disable react/function-component-definition */
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import './Widget.scss';

const Widget = (props) => {
  const { title, className, children } = props;
  return (
    <section className={cx('widget', className)}>
      {title &&
        (typeof title === 'string' ? (
          <h5 className="title">{title}</h5>
        ) : (
          <header className="title">{title}</header>
        ))}
      <div>{children}</div>
    </section>
  );
};

Widget.propTypes = {
  title: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

Widget.defaultProps = {
  title: null,
  className: '',
  children: [],
};

export default Widget;
