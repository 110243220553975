/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
/* eslint-disable react/no-unescaped-entities */
/* eslint no-nested-ternary: "off" */

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
} from 'reactstrap';
import axios from 'axios';
import auth0Client from '../../../Auth/Auth';

import Widget from '../../Widget/Widget';

import '../forms.scss';

import loadingSpinner from '../../../images/Eclipse-1s-200px.svg';

class sprFacultyReadout extends React.Component {
  constructor(props) {
    super(props);
    this.getUploadedFiles = this.getUploadedFiles.bind(this);
    this.getForm = this.getForm.bind(this);
    this.renderUploadedFiles = this.renderUploadedFiles.bind(this);
    this.renderTextArray = this.renderTextArray.bind(this);
    this.renderReadout = this.renderReadout.bind(this);

    this.state = {
      formData: [],
      dateLeftProgram: '',
      expectedReturnDate: '',
      reasonLeftProgramSelect: 'Successful completion of program',
      otherReasonLeftProgram: '',
      GPA: '',
      teachingAssistantClassSelect: 'PSYC 501 Advanced Statistics I',
      teachingAssistantClasses: [],
      professionalSocietyInput: '',
      professionalSocieties: [],
      leadershipRoleInput: '',
      leadershipRoles: [],
      psychologicalTopicInput: '',
      psychologicalTopics: [],
      doctoralProjectTitle: '',
      doctoralCommitteeMemberInput: '',
      doctoralCommitteeMembers: [],
      doctoralDefensePassedDate: '',
      doctoralProposalPassedDate: '',
      dissertationProjectTitle: '',
      dissertationCommitteeMemberInput: '',
      dissertationCommitteeMembers: [],
      dissertationPassedDate: '',
      dissertationProposalPassedDate: '',
      comprehensiveExamDate: '',
      comprehensiveExamScore: '',
      internshipNameInput: '',
      accreditingBodyNameInput: '',
      selectedFile: null,
      uploadPercentage: 0,
      uploadedFiles: [],
      status: '',
      studentID: '',
      studentName: '',
      program: '',
      loading: true,
      error: false,
    };
  }

  componentDidMount() {
    this.getForm();
  }

  getUploadedFiles() {
    const {
      match: { params },
    } = this.props;
    const url = `${process.env.REACT_APP_API_URL}/file/${params.formid}`;

    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        this.setState({ uploadedFiles: response.data, loading: false });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        this.setState({
          loading: false,
          error: true,
        });
      });
  }

  getForm() {
    const {
      match: { params },
    } = this.props;
    const url = `${process.env.REACT_APP_API_URL}/form/${params.formid}`;

    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        // check if data is valid
        const { data, status, student } = response.data;
        const { currentProgram, _id } = student;
        const studentName = `${student.firstName} ${student.lastName}`;

        let {
          formData,
          dateLeftProgram,
          expectedReturnDate,
          reasonLeftProgramSelect,
          otherReasonLeftProgram,
          GPA,
          teachingAssistantClasses,
          professionalSocieties,
          leadershipRoles,
          psychologicalTopics,
          doctoralProjectTitle,
          doctoralCommitteeMembers,
          doctoralDefensePassedDate,
          doctoralProposalPassedDate,
          dissertationProjectTitle,
          dissertationCommitteeMembers,
          dissertationPassedDate,
          dissertationProposalPassedDate,
          comprehensiveExamDate,
          comprehensiveExamScore,
          internshipNameInput,
          accreditingBodyNameInput,
        } = this.state;

        // check if data was returned in a array
        if (data.length === 6) {
          formData = data;
        } else {
          // eslint-disable-next-line prefer-destructuring
          formData = data[0];
        }

        dateLeftProgram = formData[0].data[2].value;
        expectedReturnDate = formData[0].data[3].value;
        if (formData[0].data[4].value !== '') {
          reasonLeftProgramSelect = formData[0].data[4].value;
        }
        otherReasonLeftProgram = formData[0].data[5].value;
        GPA = formData[1].data[0].value;
        if (formData[1].data[4].value !== '') {
          teachingAssistantClasses = formData[1].data[4].value;
        }
        if (formData[2].data[1].value !== '') {
          professionalSocieties = formData[2].data[1].value;
        }
        if (formData[2].data[3].value !== '') {
          leadershipRoles = formData[2].data[3].value;
        }
        if (formData[2].data[5].value !== '') {
          psychologicalTopics = formData[2].data[5].value;
        }
        doctoralProjectTitle = formData[3].data[1].value;
        if (formData[3].data[5].value !== '') {
          doctoralCommitteeMembers = formData[3].data[5].value;
        }
        doctoralDefensePassedDate = formData[3].data[2].value;
        doctoralProposalPassedDate = formData[3].data[4].value;
        dissertationProjectTitle = formData[4].data[1].value;
        if (formData[4].data[5].value !== '') {
          dissertationCommitteeMembers = formData[4].data[5].value;
        }
        dissertationPassedDate = formData[4].data[2].value;
        dissertationProposalPassedDate = formData[4].data[4].value;
        comprehensiveExamDate = formData[5].data[1].value;
        comprehensiveExamScore = formData[5].data[2].value;
        internshipNameInput = formData[5].data[5].value;
        accreditingBodyNameInput = formData[5].data[8].value;

        // form inProgress/completed, set student info and formData
        this.setState({
          formData,
          dateLeftProgram,
          expectedReturnDate,
          reasonLeftProgramSelect,
          otherReasonLeftProgram,
          GPA,
          teachingAssistantClasses,
          professionalSocieties,
          leadershipRoles,
          psychologicalTopics,
          doctoralProjectTitle,
          doctoralCommitteeMembers,
          doctoralDefensePassedDate,
          doctoralProposalPassedDate,
          dissertationProjectTitle,
          dissertationCommitteeMembers,
          dissertationPassedDate,
          dissertationProposalPassedDate,
          comprehensiveExamDate,
          comprehensiveExamScore,
          internshipNameInput,
          accreditingBodyNameInput,
          studentID: _id,
          studentName,
          program: currentProgram,
          status,
        });
        this.getUploadedFiles();
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        this.setState({
          loading: false,
          error: true,
        });
      });
  }

  renderUploadedFiles(uploadType) {
    const { uploadedFiles } = this.state;

    // get files for selected uploadType
    const filteredFiles = uploadedFiles.filter((val) => val.uploadType === uploadType);

    return (
      <div>
        <FormGroup className="submitBlock">
          <Row className="justify-content-center">
            <Col md={6} className="text-center">
              <Label>
                <i className="fa fa-chevron-right" /> Uploaded files
              </Label>
              {filteredFiles.map((file) => (
                <InputGroup key={file._id} className="mt-2">
                  <Input type="text" id="q5-s1-q1-t-file" placeholder={file.filename} disabled />
                </InputGroup>
              ))}
            </Col>
          </Row>
        </FormGroup>
      </div>
    );
  }

  renderTextArray(arrayName) {
    const textArray = this.state[arrayName];

    return (
      <div>
        {textArray.map((value) => (
          <InputGroup key={value} className="mt-2">
            <Input type="text" id="q5-s1-q1-t-file" placeholder={value} disabled />
          </InputGroup>
        ))}
      </div>
    );
  }

  renderReadout() {
    const { formData, program } = this.state;

    return (
      <div>
        <Row>
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Enrollment Information
                </h5>
              }
            >
              {/* Enrollment Information - Date started program */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Date started program: <b className="ml-2">N/A</b>
                  </p>
                </Col>
              </Row>
              {/* // Enrollment Information - Date started program */}
              {/* Enrollment Information - Academic Status */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Academic Status:{' '}
                    <b className="ml-2">
                      {formData[0].data[1].value === '' ? 'N/A' : formData[0].data[1].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Enrollment Information - Academic Status */}
              {/* Enrollment Information - Date left program */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Date left program:{' '}
                    <b className="ml-2">
                      {formData[0].data[2].value === '' ? 'N/A' : formData[0].data[2].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Enrollment Information - Date left program */}
              {/* Enrollment Information - Expected return date */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Expected return date:{' '}
                    <b className="ml-2">
                      {formData[0].data[3].value === '' ? 'N/A' : formData[0].data[3].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Enrollment Information - Expected return date */}
              {/* Enrollment Information - Reason left program */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Reason left program:{' '}
                    <b className="ml-2">
                      {formData[0].data[4].value === '' ? 'N/A' : formData[0].data[4].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Enrollment Information - Reason left program */}
              {/* Enrollment Information - other reasons */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Please specify other reasons:{' '}
                    <b className="ml-2">
                      {formData[0].data[5].value === '' ? 'N/A' : formData[0].data[5].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Enrollment Information - other reasons */}
            </Widget>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Coursework
                </h5>
              }
            >
              {/* Coursework - Q1 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q1</b>
                    </Label>{' '}
                    What is your current GPA?{' '}
                    <b className="ml-2">
                      {formData[1].data[0].value === '' ? 'N/A' : formData[1].data[0].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Coursework - Q1 */}
              {/* Coursework - Q2 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q2</b>
                    </Label>{' '}
                    Have you been first author or co-author on a poster presentation?{' '}
                    <b className="ml-2">
                      {formData[1].data[1].value === '' ? 'N/A' : formData[1].data[1].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Coursework - Q2 */}
              {/* Coursework - Q2 - sub Q1  */}
              {formData[1].data[1].value === 'Yes' && (
                <div>{this.renderUploadedFiles('posterPresentation')}</div>
              )}
              {/* // Coursework - Q2 - sub Q1 */}
              {/* Coursework - Q3 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q3</b>
                    </Label>{' '}
                    Have you been first author or co-author on a published paper?{' '}
                    <b className="ml-2">
                      {formData[1].data[2].value === '' ? 'N/A' : formData[1].data[2].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Coursework - Q3 */}
              {/* Coursework - Q3 - sub Q1  */}
              {formData[1].data[2].value === 'Yes' && (
                <div>{this.renderUploadedFiles('publishedPaper')}</div>
              )}
              {/* // Coursework - Q3 - sub Q1 */}
              {/* Coursework - Q4 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q4</b>
                    </Label>{' '}
                    Have you been a teaching assistant for any courses?{' '}
                    <b className="ml-2">
                      {formData[1].data[3].value === '' ? 'N/A' : formData[1].data[3].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Coursework - Q4 */}
              {/* Coursework - Q4 - sub Q1  */}
              {formData[1].data[3].value === 'Yes' && (
                <div>
                  <Row className="justify-content-center">
                    <Col md={6}> {this.renderTextArray('teachingAssistantClasses')}</Col>
                  </Row>
                </div>
              )}
              {/* // Coursework - Q4 - sub Q1 */}
            </Widget>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Professional Activities
                </h5>
              }
            >
              {/* Professional Activities - Q5 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q5</b>
                    </Label>{' '}
                    Did you join or are you a current member of a professional society?{' '}
                    <b className="ml-2">
                      {formData[2].data[0].value === '' ? 'N/A' : formData[2].data[0].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Professional Activities - Q5 */}
              {/* Professional Activities - Q5 - sub Q1  */}
              {formData[2].data[0].value === 'Yes' && (
                <div>
                  <Row className="justify-content-center">
                    <Col md={6}> {this.renderTextArray('professionalSocieties')}</Col>
                  </Row>
                </div>
              )}
              {/* // Professional Activities - Q5 - sub Q1 */}
              {/* Professional Activities - Q5 - sub Q2 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Are you involved in any leadership roles??{' '}
                    <b className="ml-2">
                      {formData[2].data[2].value === '' ? 'N/A' : formData[2].data[2].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Professional Activities - Q5 - sub Q2 */}
              {/* Professional Activities - Q5 - sub Q3  */}
              {formData[2].data[2].value === 'Yes' && (
                <div>
                  <Row className="justify-content-center">
                    <Col md={6}> {this.renderTextArray('leadershipRoles')}</Col>
                  </Row>
                </div>
              )}
              {/* // Professional Activities - Q5 - sub Q3 */}
              {/* Professional Activities - Q6 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q6</b>
                    </Label>{' '}
                    Have you presented a psychological topic to a lay or community audience?{' '}
                    <b className="ml-2">
                      {formData[2].data[4].value === '' ? 'N/A' : formData[2].data[4].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Professional Activities - Q6 */}
              {/* Professional Activities - Q6 - sub Q1  */}
              {formData[2].data[4].value === 'Yes' && (
                <div>
                  <Row className="justify-content-center">
                    <Col md={6}> {this.renderTextArray('psychologicalTopics')}</Col>
                  </Row>
                </div>
              )}
              {/* // Professional Activities - Q6 - sub Q1 */}
            </Widget>
            {program === 'Psy.D.' && (
              <Widget
                title={
                  <h5>
                    <i className="fa fa-align-left" /> Doctoral Project
                  </h5>
                }
              >
                {/* Doctoral Project - Q7 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      <Label>
                        <b>Q7</b>
                      </Label>{' '}
                      Have you successfully passed your doctoral project defense?{' '}
                      <b className="ml-2">
                        {formData[3].data[0].value === '' ? 'N/A' : formData[3].data[0].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Doctoral Project - Q7 */}
                {/* Doctoral Project - Q7 - sub Q4 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Has your committee passed your doctoral project proposal?{' '}
                      <b className="ml-2">
                        {formData[3].data[3].value === '' ? 'N/A' : formData[3].data[3].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Doctoral Project - Q7 - sub Q4 */}
                {/* Doctoral Project - Q7 - sub Q1 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Doctoral project title:{' '}
                      <b className="ml-2">
                        {formData[3].data[1].value === '' ? 'N/A' : formData[3].data[1].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Doctoral Project - Q7 - sub Q1 */}
                {/* Doctoral Project - Q7 - sub Q2 */}{' '}
                <Row className="justify-content-center mt-4">
                  <Col md={6} className="text-center">
                    <p className="readout">
                      <u>Committee Members</u>
                    </p>
                    {formData[3].data[5].value.length === 0 ? (
                      <b className="ml-2">N/A</b>
                    ) : (
                      this.renderTextArray('doctoralCommitteeMembers')
                    )}
                  </Col>
                </Row>
                {/* // Doctoral Project - Q7 - sub Q2 */}
                {/* Doctoral Project - Q7 - sub Q3 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Defense passed date:{' '}
                      <b className="ml-2">
                        {formData[3].data[2].value === '' ? 'N/A' : formData[3].data[2].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Doctoral Project - Q7 - sub Q3 */}
                {/* Doctoral Project - Q7 - sub Q5 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Proposal passed date:{' '}
                      <b className="ml-2">
                        {formData[3].data[4].value === '' ? 'N/A' : formData[3].data[4].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Doctoral Project - Q7 - sub Q5 */}
                {/* Doctoral Project - Q7 - sub Q6 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Have you started working on completing your doctoral project for the final
                      defense?{' '}
                      <b className="ml-2">
                        {formData[3].data[6].value === '' ? 'N/A' : formData[3].data[6].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Doctoral Project - Q7 - sub Q6 */}
                {/* Doctoral Project - Q7 - sub Q7 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Have you identified a faculty chair for your doctoral project?{' '}
                      <b className="ml-2">
                        {formData[3].data[7].value === '' ? 'N/A' : formData[3].data[7].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Doctoral Project - Q7 - sub Q7 */}
                {/* Doctoral Project - Q7 - sub Q8 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Have you identified a topic area for your doctoral project?{' '}
                      <b className="ml-2">
                        {formData[3].data[8].value === '' ? 'N/A' : formData[3].data[8].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Doctoral Project - Q7 - sub Q8 */}
                {/* Doctoral Project - Q7 - sub Q9 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Have you settled on a general design or plan for conducting your doctoral
                      project?{' '}
                      <b className="ml-2">
                        {formData[3].data[9].value === '' ? 'N/A' : formData[3].data[9].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Doctoral Project - Q7 - sub Q9 */}
                {/* Doctoral Project - Q7 - sub Q10 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Have you started writing your doctoral project proposal?{' '}
                      <b className="ml-2">
                        {formData[3].data[10].value === '' ? 'N/A' : formData[3].data[10].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Doctoral Project - Q7 - sub Q10 */}
              </Widget>
            )}
            {program === 'Ph.D.' && (
              <Widget
                title={
                  <h5>
                    <i className="fa fa-align-left" /> Dissertation Questions
                  </h5>
                }
              >
                {/* Dissertation Questions - Q7 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      <Label>
                        <b>Q7</b>
                      </Label>{' '}
                      Have you successfully passed your dissertation defense?{' '}
                      <b className="ml-2">
                        {formData[4].data[0].value === '' ? 'N/A' : formData[4].data[0].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Dissertation Questions - Q7 */}
                {/* Dissertation Questions - Q7 - sub Q4 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Has your committee passed your dissertation proposal?{' '}
                      <b className="ml-2">
                        {formData[4].data[3].value === '' ? 'N/A' : formData[4].data[3].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Dissertation Questions - Q7 - sub Q4 */}
                {/* Dissertation Questions - Q7 - sub Q1 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Dissertation project title:{' '}
                      <b className="ml-2">
                        {formData[4].data[1].value === '' ? 'N/A' : formData[4].data[1].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Dissertation Questions - Q7 - sub Q1 */}
                {/* Dissertation Questions - Q7 - sub Q2 */}{' '}
                <Row className="justify-content-center mt-4">
                  <Col md={6} className="text-center">
                    <p className="readout">
                      <u>Committee Members</u>
                    </p>
                    {formData[4].data[5].value.length === 0 ? (
                      <b className="ml-2">N/A</b>
                    ) : (
                      this.renderTextArray('dissertationCommitteeMembers')
                    )}
                  </Col>
                </Row>
                {/* // Dissertation Questions - Q7 - sub Q2 */}
                {/* Dissertation Questions - Q7 - sub Q3 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Defense passed date:{' '}
                      <b className="ml-2">
                        {formData[4].data[2].value === '' ? 'N/A' : formData[4].data[2].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Dissertation Questions - Q7 - sub Q3 */}
                {/* Dissertation Questions - Q7 - sub Q5 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Proposal passed date:{' '}
                      <b className="ml-2">
                        {formData[4].data[4].value === '' ? 'N/A' : formData[4].data[4].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Dissertation Questions - Q7 - sub Q5 */}
                {/* Dissertation Questions - Q7 - sub Q6 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Have you started writing up your results for your dissertation defense?{' '}
                      <b className="ml-2">
                        {formData[4].data[6].value === '' ? 'N/A' : formData[4].data[6].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Dissertation Questions - Q7 - sub Q6 */}
                {/* Dissertation Questions - Q7 - sub Q7 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Have you finished data collection for your dissertation? If your dissertation
                      does not involve data collection, have you started your analysis or other
                      required work?{' '}
                      <b className="ml-2">
                        {formData[4].data[7].value === '' ? 'N/A' : formData[4].data[7].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Dissertation Questions - Q7 - sub Q7 */}
                {/* Dissertation Questions - Q7 - sub Q8 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Have you started any data collection for your dissertation?{' '}
                      <b className="ml-2">
                        {formData[4].data[8].value === '' ? 'N/A' : formData[4].data[8].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Dissertation Questions - Q7 - sub Q8 */}
                {/* Dissertation Questions - Q7 - sub Q9 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Have you identified a faculty chair for your dissertation?{' '}
                      <b className="ml-2">
                        {formData[4].data[9].value === '' ? 'N/A' : formData[4].data[9].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Dissertation Questions - Q7 - sub Q9 */}
                {/* Dissertation Questions - Q7 - sub Q10 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Have you identified a topic area for your dissertation?{' '}
                      <b className="ml-2">
                        {formData[4].data[10].value === '' ? 'N/A' : formData[4].data[10].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Dissertation Questions - Q7 - sub Q10 */}
                {/* Dissertation Questions - Q7 - sub Q11 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Have you settled on a general design or plan for conducting the dissertation?{' '}
                      <b className="ml-2">
                        {formData[4].data[11].value === '' ? 'N/A' : formData[4].data[11].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Dissertation Questions - Q7 - sub Q11 */}
                {/* Dissertation Questions - Q7 - sub Q12 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Have you started writing your dissertation proposal?{' '}
                      <b className="ml-2">
                        {formData[4].data[12].value === '' ? 'N/A' : formData[4].data[12].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Dissertation Questions - Q7 - sub Q12 */}
              </Widget>
            )}
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Doctoral Internship
                </h5>
              }
            >
              {/* Doctoral Internship - Q8 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q8</b>
                    </Label>{' '}
                    Have you taken your comprehensive exam (EPPP)?{' '}
                    <b className="ml-2">
                      {formData[5].data[0].value === '' ? 'N/A' : formData[5].data[0].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Doctoral Internship - Q8 */}
              {/* Doctoral Internship - Q8 - sub Q1 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Date Exam Was Taken:{' '}
                    <b className="ml-2">
                      {formData[5].data[1].value === '' ? 'N/A' : formData[5].data[1].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Doctoral Internship - Q8 - sub Q1 */}
              {/* Doctoral Internship - Q8 - sub Q2 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Score Received:{' '}
                    <b className="ml-2">
                      {formData[5].data[2].value === '' ? 'N/A' : formData[5].data[2].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Doctoral Internship - Q8 - sub Q2 */}
              {/* Doctoral Internship - Q9 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q9</b>
                    </Label>{' '}
                    Did you apply for internship in the past year?{' '}
                    <b className="ml-2">
                      {formData[5].data[3].value === '' ? 'N/A' : formData[5].data[3].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Doctoral Internship - Q9 */}
              {/* Doctoral Internship - Q9 - sub Q1 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Did you successfully match for an internship?{' '}
                    <b className="ml-2">
                      {formData[5].data[4].value === '' ? 'N/A' : formData[5].data[4].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Doctoral Internship - Q9 - sub Q1 */}
              {/* Doctoral Internship - Q9 - sub Q2 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Name of Matched Internship Program{' '}
                    <b className="ml-2">
                      {formData[5].data[5].value === '' ? 'N/A' : formData[5].data[5].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Doctoral Internship - Q9 - sub Q2 */}
              {/* Doctoral Internship - Q9 - sub Q3 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Is the internship program APA-accredited?{' '}
                    <b className="ml-2">
                      {formData[5].data[6].value === '' ? 'N/A' : formData[5].data[6].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Doctoral Internship - Q9 - sub Q3 */}
              {/* Doctoral Internship - Q9 - sub Q4 */}
              <Row className="justify-content-center mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Please upload justification for non-APA accredited internship:
                  </p>
                </Col>
              </Row>
              {formData[5].data[6].value === 'Yes' && (
                <div>{this.renderUploadedFiles('internshipJustification')}</div>
              )}
              {/* // Doctoral Internship - Q9 - sub Q4 */}
              {/* Doctoral Internship - Q9 - sub Q5 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Is the internship program accredited by another body (i.e., CPA, PCSAS)?{' '}
                    <b className="ml-2">
                      {formData[5].data[7].value === '' ? 'N/A' : formData[5].data[7].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Doctoral Internship - Q9 - sub Q5 */}
              {/* Doctoral Internship - Q9 - sub Q6 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Name of accrediting body:{' '}
                    <b className="ml-2">
                      {formData[5].data[8].value === '' ? 'N/A' : formData[5].data[8].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Doctoral Internship - Q9 - sub Q6 */}
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    const { loading, error, studentID, studentName } = this.state;

    /* render readout */
    if (!loading && !error) {
      return (
        <div>
          <Row>
            <Col xs={{ size: 10, offset: 1 }}>
              <Row>
                <Col xs="12">
                  <div className="crumbs">
                    <Breadcrumb>
                      <BreadcrumbItem>
                        <Link to="/dashboard">Dashboard</Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem>
                        <Link to={`/student/${studentID}`}>{studentName}</Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem active>Student Progress Report</BreadcrumbItem>
                    </Breadcrumb>
                  </div>
                </Col>
              </Row>
              <h2 className="page-title">Student Progress Report: {studentName}</h2>
              {this.renderReadout()}
            </Col>
          </Row>
        </div>
      );
    }
    /* render error */
    if (error) {
      return (
        <div>
          <Row className="mt-4">
            <Col xs={{ size: 10, offset: 1 }}>
              <Widget>
                <Row className="mt-4 mb-4 text-center">
                  <Col>
                    <h4>
                      There was an error loading the form, please reload the page and try again.
                    </h4>
                  </Col>
                </Row>
              </Widget>
            </Col>
          </Row>
        </div>
      );
    }
    /* render loading as default */
    return (
      <div>
        <Row className="mt-4">
          <Col xs={{ size: 10, offset: 1 }}>
            <h2 className="page-title">Student Progress Report</h2>
          </Col>
        </Row>
        <Row className="text-center">
          <Col>
            <img src={loadingSpinner} alt="loading spinner" className="img-responsive" />
          </Col>
        </Row>
      </div>
    );
  }
}

sprFacultyReadout.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withRouter(sprFacultyReadout);
