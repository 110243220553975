/* eslint-disable react/function-component-definition */
import React from 'react';
import { Route } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

import auth0Client from '../../Auth/Auth';

import loadingSpinner from '../../images/Eclipse-1s-200px.svg';

function SecuredRoute(props) {
  const { component: Component, path, checkingSession } = props;

  return (
    <Route
      path={path}
      render={() => {
        if (checkingSession)
          return (
            <Row className="text-center">
              <Col>
                <img src={loadingSpinner} alt="loading spinner" className="img-responsive" />
              </Col>
            </Row>
          );
        if (!auth0Client.isAuthenticated()) {
          auth0Client.login();
          return <div />;
        }
        return <Component />;
      }}
    />
  );
}

SecuredRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  path: PropTypes.string.isRequired,
  checkingSession: PropTypes.bool.isRequired,
};

export default SecuredRoute;
