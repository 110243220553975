/* eslint-disable consistent-return */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Row, Col, Button } from 'reactstrap';

import Widget from '../Widget/Widget';

import StudentAdmin from '../StudentAdmin/StudentAdmin';
import StaffAdmin from '../StaffAdmin/StaffAdmin';
import ClinicalSuperAdmin from '../ClinicalSuperAdmin/ClinicalSuperAdmin';

class UserAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.onSectionBtnClick = this.onSectionBtnClick.bind(this);

    this.state = {
      currentView: 'Student Admin',
    };
  }

  onSectionBtnClick(sectionValue) {
    this.setState({
      currentView: sectionValue,
    });
  }

  render() {
    return (
      <div>
        <Row>
          {/* Selection */}
          <Col xl={{ size: 10, offset: 1 }}>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> User Administration{' '}
                </h5>
              }
            >
              <Row className="mt-4">
                {/* <Col sm={3} className="text-md-right">
                  <Label>User type</Label>
                </Col> */}
                <Col>
                  <Button
                    color={this.state.currentView === 'Student Admin' ? 'dark' : 'inverse'}
                    onClick={() => this.onSectionBtnClick('Student Admin')}
                    active={this.state.currentView === 'Student Admin'}
                  >
                    Student Admin
                  </Button>
                  <Button
                    className="ml-2"
                    color={this.state.currentView === 'Faculty Admin' ? 'dark' : 'inverse'}
                    onClick={() => this.onSectionBtnClick('Faculty Admin')}
                    active={this.state.currentView === 'Faculty Admin'}
                  >
                    Faculty/Staff Admin
                  </Button>
                  <Button
                    className="ml-2"
                    color={
                      this.state.currentView === 'Clinical Supervisor Admin' ? 'dark' : 'inverse'
                    }
                    onClick={() => this.onSectionBtnClick('Clinical Supervisor Admin')}
                    active={this.state.currentView === 'Clinical Supervisor Admin'}
                  >
                    Clinical Supervisor Admin
                  </Button>
                </Col>
              </Row>
              <p>
                <br />
              </p>
            </Widget>
            {(() => {
              if (this.state.currentView === 'Student Admin') {
                return <StudentAdmin />;
              }
              if (this.state.currentView === 'Faculty Admin') {
                return <StaffAdmin />;
              }
              if (this.state.currentView === 'Clinical Supervisor Admin') {
                return <ClinicalSuperAdmin />;
              }
            })()}
          </Col>
        </Row>
      </div>
    );
  }
}

export default UserAdmin;
