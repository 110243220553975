/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, InputGroup, InputGroupAddon, Row, Col, Button } from 'reactstrap';
import axios from 'axios';
import auth0Client from '../../Auth/Auth';
import Widget from '../Widget/Widget';

import loadingSpinner from '../../images/Eclipse-1s-200px.svg';
import iUser from '../../images/user.png';

class ClinicalSuperSearch extends React.Component {
  constructor(props) {
    super(props);
    this.onSearchBtnClick = this.onSearchBtnClick.bind(this);
    this.searchClinicalSupervisors = this.searchClinicalSupervisors.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderClinicalSupervisors = this.renderClinicalSupervisors.bind(this);

    this.state = {
      searchValue: '',
      noResults: false,
      clinicalSupervisorData: [],
      loading: false,
    };
  }

  handleInputChange(e) {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.searchClinicalSupervisors();
  }

  onSearchBtnClick(e) {
    e.preventDefault();
    e.target.blur();
    this.setState({
      loading: true,
    });
    this.searchClinicalSupervisors();
  }

  searchClinicalSupervisors() {
    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    const { searchValue } = this.state;
    const url = `${process.env.REACT_APP_API_URL}/clinical-supervisor/search`;

    axios
      .post(url, { searchValue }, config)
      .then((response) => {
        const clinicalSupervisorData = response.data;

        // set noResults to true if no results are returned
        const noResults = !clinicalSupervisorData.length > 0;

        this.setState({ loading: false, clinicalSupervisorData, noResults });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  renderClinicalSupervisors(idx) {
    const { clinicalSupervisorData } = this.state;
    let filteredClinicalSupervisors = [];

    if (idx === 'even') {
      filteredClinicalSupervisors = clinicalSupervisorData.filter(function filterEven(v, i) {
        // check the index is even
        return i % 2 === 0;
      });
    } else {
      filteredClinicalSupervisors = clinicalSupervisorData.filter(function filterOdd(v, i) {
        // check the index is odd
        return i % 2 !== 0;
      });
    }

    return (
      <div>
        <ul className="newsList newsListNoHover">
          {filteredClinicalSupervisors.map((supervisor) => (
            <li key={supervisor._id}>
              <img src={iUser} alt="" className="rounded-circle float-left" />
              <div className="newsItemInfo">
                <div className="name">
                  <Link to={`/clinicalSupervisor/${supervisor._id}`}>
                    {supervisor.firstName} {supervisor.lastName}
                  </Link>
                </div>
                <div className="position">{supervisor.title}</div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  render() {
    const { loading, searchValue, noResults } = this.state;
    return (
      <div>
        <Widget
          title={
            <h5>
              <i className="fa fa-search" /> Clinical Supervisor Search
            </h5>
          }
        >
          <Row className="mt-4">
            <Col md={8}>
              <Form onSubmit={this.handleSubmit}>
                <InputGroup>
                  <Input
                    type="text"
                    id="searchValue"
                    name="searchValue"
                    value={searchValue}
                    onChange={this.handleInputChange}
                  />
                  <InputGroupAddon addonType="append">
                    <Button color="inverse" onClick={(e) => this.onSearchBtnClick(e)}>
                      Search
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </Form>
            </Col>
            <Col md={4} />
          </Row>
          {loading && (
            <div>
              <Row className="text-center">
                <Col>
                  <img src={loadingSpinner} alt="loading spinner" className="img-responsive" />
                </Col>
              </Row>
            </div>
          )}
          {!loading && (
            <div>
              <Row className="mt-4">
                <Col md={6}>{this.renderClinicalSupervisors('even')}</Col>
                <Col md={6}>{this.renderClinicalSupervisors('odd')}</Col>
              </Row>
            </div>
          )}
          {noResults && (
            <Row>
              <Col>
                <p className="text-danger">No results found.</p>
              </Col>
            </Row>
          )}
        </Widget>
      </div>
    );
  }
}

export default ClinicalSuperSearch;
