import React from 'react';
import { Form, FormGroup, Input, Label, Row, Col, Button } from 'reactstrap';
import axios from 'axios';
import auth0Client from '../../Auth/Auth';
import Widget from '../Widget/Widget';

import loadingSpinner from '../../images/Eclipse-1s-200px.svg';
// import iFacility from '../../images/facility.png';

class AddFacility extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmitBtnClick = this.onSubmitBtnClick.bind(this);
    this.onAddNewFacilityBtnClick = this.onAddNewFacilityBtnClick.bind(this);
    this.onAddAnotherFacilityBtnClick = this.onAddAnotherFacilityBtnClick.bind(this);
    this.onNewFacilityCancelBtnClick = this.onNewFacilityCancelBtnClick.bind(this);
    this.addFacility = this.addFacility.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

    this.state = {
      name: '',
      department: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      addNewFacility: false,
      showConfirmation: false,
      loading: false,
      errorAddingFacility: false,
    };
  }

  handleInputChange(e) {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  onAddNewFacilityBtnClick() {
    const { addNewFacility } = this.state;
    this.setState({
      addNewFacility: !addNewFacility,
    });
  }

  onSubmitBtnClick() {
    this.setState({
      loading: true,
    });
    this.addFacility();
  }

  onAddAnotherFacilityBtnClick() {
    this.setState({
      addNewFacility: true,
      name: '',
      department: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      showConfirmation: false,
      errorAddingFacility: false,
    });
  }

  onNewFacilityCancelBtnClick() {
    this.setState({
      addNewFacility: false,
      name: '',
      department: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      showConfirmation: false,
      errorAddingFacility: false,
    });
  }

  addFacility() {
    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    const url = `${process.env.REACT_APP_API_URL}/facility`;

    const { name, department, address, address2, city, state, zip } = this.state;

    axios
      .post(url, { name, department, address, address2, city, state, zip }, config)
      // eslint-disable-next-line no-unused-vars
      .then((response) => {
        this.setState({ loading: false, showConfirmation: true });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ loading: false, errorAddingFacility: true });
      });
  }

  renderFacilityForm() {
    const { name, department, address, address2, city, state, zip, errorAddingFacility } =
      this.state;
    return (
      <div className="mt-4">
        <Form>
          {/* Add Facility - Q1 */}
          <Row>
            <Col sm={3} className="text-md-right">
              <Label for="name">Name</Label>
            </Col>
            <Col sm={6}>
              <Input
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={this.handleInputChange}
              />
            </Col>
          </Row>
          {/* // Add Facility - Q1 */}
          {/* Add Facility - Q2 */}
          <Row className="mt-3">
            <Col sm={3} className="text-md-right">
              <Label for="department">Department</Label>
            </Col>
            <Col sm={6}>
              <Input
                type="text"
                id="department"
                name="department"
                placeholder="Required, enter 'None' if Facility has no department"
                value={department}
                onChange={this.handleInputChange}
              />
            </Col>
          </Row>
          {/* // Add Facility - Q2 */}
          {/* Add Facility - Q3 */}
          <Row className="mt-3">
            <Col sm={3} className="text-md-right">
              <Label for="address">Address</Label>
            </Col>
            <Col sm={6}>
              <Input
                type="text"
                id="address"
                name="address"
                value={address}
                onChange={this.handleInputChange}
              />
            </Col>
          </Row>
          {/* // Add Facility - Q3 */}
          {/* Add Facility - Q4 */}
          <Row className="mt-3">
            <Col sm={3} className="text-md-right">
              <Label for="address2">Address 2</Label>
            </Col>
            <Col sm={6}>
              <Input
                type="text"
                id="address2"
                name="address2"
                placeholder="Optional"
                value={address2}
                onChange={this.handleInputChange}
              />
            </Col>
          </Row>
          {/* // Add Facility - Q4 */}
          {/* Add Facility - Q5 */}
          <Row className="mt-3">
            <Col sm={3} className="text-md-right">
              <Label for="city">City</Label>
            </Col>
            <Col sm={6}>
              <Input
                type="text"
                id="city"
                name="city"
                value={city}
                onChange={this.handleInputChange}
              />
            </Col>
          </Row>
          {/* // Add Facility - Q5 */}
          {/* Add Facility - Q6 */}
          <Row className="mt-3">
            <Col sm={3} className="text-md-right">
              <Label for="state">State</Label>
            </Col>
            <Col sm={6}>
              <Input
                type="text"
                id="state"
                name="state"
                value={state}
                onChange={this.handleInputChange}
              />
            </Col>
          </Row>
          {/* // Add Facility - Q6 */}
          {/* Add Facility - Q7 */}
          <Row className="mt-3">
            <Col sm={3} className="text-md-right">
              <Label for="zip">Zip</Label>
            </Col>
            <Col sm={6}>
              <Input
                type="text"
                id="zip"
                name="zip"
                value={zip}
                onChange={this.handleInputChange}
              />
            </Col>
          </Row>
          {/* // Add Facility - Q7 */}
          <FormGroup row className="submitBlock">
            <Col md={12} className="text-center">
              <Button color="info" onClick={this.onSubmitBtnClick} className="ml-xs">
                Add Facility
              </Button>
              <Button color="inverse" onClick={this.onNewFacilityCancelBtnClick} className="ml-xs">
                Cancel
              </Button>
            </Col>
          </FormGroup>
        </Form>
        {errorAddingFacility && (
          <Row className="mt-4 text-center">
            <Col>
              <p className="text-danger">
                There was an error adding the Facility. Please verify the information is correct and
                try again.
              </p>
            </Col>
          </Row>
        )}
      </div>
    );
  }

  renderFacilityAddedConfirmation() {
    return (
      <div>
        <Row>
          <Col className="text-center">
            <h3>Facility Added!</h3>
            <Form>
              <FormGroup row className="submitBlock">
                <Col md={12} className="text-center">
                  <Button
                    color="info"
                    onClick={this.onAddAnotherFacilityBtnClick}
                    className="ml-xs"
                  >
                    Click Here to Add Another Facility
                  </Button>
                </Col>
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <p>
          <br />
        </p>
      </div>
    );
  }

  render() {
    const { loading, showConfirmation, addNewFacility } = this.state;
    return (
      <div>
        <Widget
          title={
            <h5>
              <i className="fa fa-plus" /> Add New Facility
            </h5>
          }
        >
          {loading && (
            <div>
              <Row className="text-center">
                <Col>
                  <img src={loadingSpinner} alt="loading spinner" className="img-responsive" />
                </Col>
              </Row>
            </div>
          )}
          {(() => {
            if (!loading && !showConfirmation) {
              return (
                <div>
                  <Row>
                    <Col>
                      <Button
                        color={addNewFacility === true ? 'dark' : 'inverse'}
                        onClick={this.onAddNewFacilityBtnClick}
                        active={addNewFacility === true}
                      >
                        Add Facility Form
                      </Button>
                      {/* <Button className="ml-2" color="inverse">
                        Import Facilities From Excel Template
                      </Button>
                      <a href="addFacility.xls" download="addFacility.xls">
                        <h6 className="pt-2">Download Excel Template</h6>
                      </a> */}
                    </Col>
                  </Row>
                  {addNewFacility && (
                    <Row>
                      <Col>{this.renderFacilityForm()}</Col>
                    </Row>
                  )}
                </div>
              );
            }
            if (!loading && showConfirmation) {
              return this.renderFacilityAddedConfirmation();
            }
            return null;
          })()}
        </Widget>
      </div>
    );
  }
}

export default AddFacility;
