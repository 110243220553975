/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'reactstrap';
import axios from 'axios';
import auth0Client from '../../Auth/Auth';

import Widget from '../Widget/Widget';

import loadingSpinner from '../../images/Eclipse-1s-200px.svg';
import iUser from '../../images/user.png';

class ClinicalSuperList extends React.Component {
  constructor(props) {
    super(props);
    this.getClinicalSupervisors = this.getClinicalSupervisors.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.renderClinicalSupervisors = this.renderClinicalSupervisors.bind(this);

    this.state = {
      clinicalSupervisorData: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.getClinicalSupervisors();
  }

  handleRefresh(e) {
    e.target.blur();
    this.setState({ loading: true });
    this.getClinicalSupervisors();
  }

  getClinicalSupervisors() {
    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    const url = `${process.env.REACT_APP_API_URL}/clinical-supervisor`;
    axios
      .get(url, config)
      .then((response) => {
        const clinicalSupervisorData = response.data;
        console.log(clinicalSupervisorData);
        this.setState({ loading: false, clinicalSupervisorData });
      })
      .catch((error) => {
        console.error(error);
        // this.setState({ currentView: 'maintenance' });
      });
  }

  renderClinicalSupervisors(idx) {
    const { clinicalSupervisorData } = this.state;
    let filteredClinicalSupervisors = [];

    if (idx === 'even') {
      filteredClinicalSupervisors = clinicalSupervisorData.filter(function filterEven(v, i) {
        // check the index is even
        return i % 2 === 0;
      });
    } else {
      filteredClinicalSupervisors = clinicalSupervisorData.filter(function filterOdd(v, i) {
        // check the index is odd
        return i % 2 !== 0;
      });
    }

    return (
      <div>
        <ul className="newsList newsListNoHover">
          {filteredClinicalSupervisors.map((supervisor) => (
            <li key={supervisor._id}>
              <img src={iUser} alt="" className="rounded-circle float-left" />
              <div className="newsItemInfo">
                <div className="name">
                  <Link to={`/clinicalSupervisor/${supervisor._id}`}>
                    {supervisor.firstName} {supervisor.lastName}
                  </Link>
                </div>
                <div className="position">{supervisor.title}</div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  render() {
    const { loading } = this.state;
    return (
      <div>
        <Row>
          <Col>
            <Widget
              title={
                <h5>
                  {' '}
                  <i className="fa fa-align-left" /> Clinical Supervisor Administration
                  <Button className="pull-right" color="link" onClick={this.handleRefresh}>
                    <i className="fa fa-repeat pull-right" />
                  </Button>
                </h5>
              }
            >
              {loading && (
                <div>
                  <Row className="text-center">
                    <Col>
                      <img src={loadingSpinner} alt="loading spinner" className="img-responsive" />
                    </Col>
                  </Row>
                </div>
              )}
              {!loading && (
                <div>
                  <Row className="mt-4">
                    <Col md={6}>{this.renderClinicalSupervisors('even')}</Col>
                    <Col md={6}>{this.renderClinicalSupervisors('odd')}</Col>
                  </Row>
                </div>
              )}
            </Widget>
          </Col>
        </Row>
        <p>
          <br />
        </p>
      </div>
    );
  }
}

export default ClinicalSuperList;
