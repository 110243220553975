/* eslint-disable consistent-return */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';

import Widget from '../Widget/Widget';
import '../Forms/forms.scss';

class AddUser extends React.Component {
  constructor(props) {
    super(props);
    this.onUserTypeBtnClick = this.onUserTypeBtnClick.bind(this);
    this.onTrackBtnClick = this.onTrackBtnClick.bind(this);
    this.onSuperUserBtnClick = this.onSuperUserBtnClick.bind(this);
    this.onSubmitBtnClick = this.onSubmitBtnClick.bind(this);
    this.onAddAnotherUserBtnClick = this.onAddAnotherUserBtnClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

    this.state = {
      userType: 'Student',
      firstName: '',
      lastName: '',
      emailAddress: '',
      track: '',
      yearSelect: '',
      advisorSelect: '',
      concentrationSelect: '',
      superuser: '',
      showConfirmation: false,
    };
  }

  handleInputChange(e) {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  onUserTypeBtnClick(sectionValue) {
    this.setState({
      userType: sectionValue,
    });
  }

  onTrackBtnClick(sectionValue) {
    this.setState({
      track: sectionValue,
    });
  }

  onSuperUserBtnClick(sectionValue) {
    this.setState({
      superuser: sectionValue,
    });
  }

  onSubmitBtnClick() {
    this.setState({
      showConfirmation: true,
    });
  }

  onAddAnotherUserBtnClick() {
    this.setState({
      userType: 'Student',
      firstName: '',
      lastName: '',
      emailAddress: '',
      track: '',
      yearSelect: '',
      advisorSelect: '',
      concentrationSelect: '',
      showConfirmation: false,
    });
  }

  renderFacultyForm() {
    return (
      <div>
        {/* Add Faculty - Q1 */}
        <Row>
          <Col sm={3} className="text-md-right">
            <Label for="firstName">First name</Label>
          </Col>
          <Col sm={4}>
            <Input
              type="text"
              id="firstName"
              name="firstName"
              value={this.state.firstName}
              onChange={this.handleInputChange}
            />
            <br />
          </Col>
        </Row>
        {/* // Add Faculty - Q1 */}
        {/* Add Faculty - Q2 */}
        <Row>
          <Col sm={3} className="text-md-right">
            <Label for="lastName">Last name</Label>
          </Col>
          <Col sm={4}>
            <Input
              type="text"
              id="lastName"
              name="lastName"
              value={this.state.lastName}
              onChange={this.handleInputChange}
            />
            <br />
          </Col>
        </Row>
        {/* // Add Faculty - Q2 */}
        {/* Add Faculty - Q3 */}
        <Row>
          <Col sm={3} className="text-md-right">
            <Label for="email">Email</Label>
          </Col>
          <Col sm={4}>
            <Input
              type="text"
              id="emailAddress"
              name="emailAddress"
              value={this.state.emailAddress}
              onChange={this.handleInputChange}
            />
            <br />
          </Col>
        </Row>
        {/* // Add Faculty - Q3 */}
        {/* Add Faculty - Q4 */}
        <Row>
          <Col sm={3} className="text-md-right">
            <Label for="email">Title</Label>
          </Col>
          <Col sm={4}>
            <Input
              type="text"
              id="title"
              name="title"
              value={this.state.title}
              onChange={this.handleInputChange}
            />
            <br />
          </Col>
        </Row>
        {/* // Add Faculty - Q4 */}
        {/* Add Faculty - Q5 */}
        <Row>
          <Col sm={3} className="text-md-right">
            <Label>Superuser</Label>
          </Col>
          <Col sm={7}>
            <Button
              className="btn-form"
              color={this.state.superuser === 'Yes' ? 'dark' : 'inverse'}
              onClick={() => this.onSuperUserBtnClick('Yes')}
              active={this.state.superuser === 'Yes'}
            >
              Yes
            </Button>
            <Button
              className="btn-form"
              color={this.state.superuser === 'No' ? 'dark' : 'inverse'}
              onClick={() => this.onSuperUserBtnClick('No')}
              active={this.state.superuser === 'No'}
            >
              No
            </Button>
          </Col>
        </Row>
        <p>
          <br />
        </p>
        {/* // Add Student - Q5 */}
      </div>
    );
  }

  renderStaffForm() {
    return (
      <div>
        {/* Add Staff - Q1 */}
        <Row>
          <Col sm={3} className="text-md-right">
            <Label for="firstName">First name</Label>
          </Col>
          <Col sm={4}>
            <Input
              type="text"
              id="firstName"
              name="firstName"
              value={this.state.firstName}
              onChange={this.handleInputChange}
            />
            <br />
          </Col>
        </Row>
        {/* // Add Staff - Q1 */}
        {/* Add Staff - Q2 */}
        <Row>
          <Col sm={3} className="text-md-right">
            <Label for="lastName">Last name</Label>
          </Col>
          <Col sm={4}>
            <Input
              type="text"
              id="lastName"
              name="lastName"
              value={this.state.lastName}
              onChange={this.handleInputChange}
            />
            <br />
          </Col>
        </Row>
        {/* // Add Staff - Q2 */}
        {/* Add Staff - Q3 */}
        <Row>
          <Col sm={3} className="text-md-right">
            <Label for="email">Email</Label>
          </Col>
          <Col sm={4}>
            <Input
              type="text"
              id="emailAddress"
              name="emailAddress"
              value={this.state.emailAddress}
              onChange={this.handleInputChange}
            />
            <br />
          </Col>
        </Row>
        {/* // Add Staff - Q3 */}
        {/* Add Staff - Q4 */}
        <Row>
          <Col sm={3} className="text-md-right">
            <Label for="email">Title</Label>
          </Col>
          <Col sm={4}>
            <Input
              type="text"
              id="title"
              name="title"
              value={this.state.title}
              onChange={this.handleInputChange}
            />
            <br />
          </Col>
        </Row>
        <p>
          <br />
        </p>
        {/* // Add Staff - Q4 */}
      </div>
    );
  }

  renderStudentForm() {
    return (
      <div>
        {/* Add Student - Q1 */}
        <Row>
          <Col sm={3} className="text-md-right">
            <Label for="firstName">First name</Label>
          </Col>
          <Col sm={4}>
            <Input
              type="text"
              id="firstName"
              name="firstName"
              value={this.state.firstName}
              onChange={this.handleInputChange}
            />
            <br />
          </Col>
        </Row>
        {/* // Add Student - Q1 */}
        {/* Add Student - Q2 */}
        <Row>
          <Col sm={3} className="text-md-right">
            <Label for="lastName">Last name</Label>
          </Col>
          <Col sm={4}>
            <Input
              type="text"
              id="lastName"
              name="lastName"
              value={this.state.lastName}
              onChange={this.handleInputChange}
            />
            <br />
          </Col>
        </Row>
        {/* // Add Student - Q2 */}
        {/* Add Student - Q3 */}
        <Row>
          <Col sm={3} className="text-md-right">
            <Label for="email">Email</Label>
          </Col>
          <Col sm={4}>
            <Input
              type="text"
              id="emailAddress"
              name="emailAddress"
              value={this.state.emailAddress}
              onChange={this.handleInputChange}
            />
            <br />
          </Col>
        </Row>
        {/* // Add Student - Q3 */}
        {/* Add Student - Q4 */}
        <Row>
          <Col sm={3} className="text-md-right">
            <Label>Track</Label>
          </Col>
          <Col sm={7}>
            <Button
              className="btn-form"
              color={this.state.track === 'Ph.D.' ? 'dark' : 'inverse'}
              onClick={() => this.onTrackBtnClick('Ph.D.')}
              active={this.state.track === 'Ph.D.'}
            >
              Ph.D.
            </Button>
            <Button
              className="btn-form"
              color={this.state.track === 'Psy.D.' ? 'dark' : 'inverse'}
              onClick={() => this.onTrackBtnClick('Psy.D.')}
              active={this.state.track === 'Psy.D.'}
            >
              Psy.D.
            </Button>
          </Col>
        </Row>
        <p>
          <br />
        </p>
        {/* // Add Student - Q4 */}
        {/* Add Student - Q5 */}
        <Row>
          <Col sm={3} className="text-md-right">
            <Label for="yearSelect">Year</Label>
          </Col>
          <Col sm={4}>
            <Input
              type="select"
              name="yearSelect"
              id="yearSelect"
              value={this.state.yearSelect}
              onChange={this.handleInputChange}
            >
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
              <option>6</option>
              <option>7</option>
              <option>8</option>
              <option>9</option>
            </Input>
          </Col>
        </Row>
        <p>
          <br />
        </p>
        {/* // Add Student - Q5 */}
        {/* Add Student - Q6 */}
        <Row>
          <Col sm={3} className="text-md-right">
            <Label for="advisorSelect">Advisor</Label>
          </Col>
          <Col sm={4}>
            <Input
              type="select"
              name="advisorSelect"
              id="advisorSelect"
              value={this.state.advisorSelect}
              onChange={this.handleInputChange}
            >
              <option>David Vermeersch</option>
              <option>Kendal Boyd</option>
              <option>Holly Morrell</option>
              <option>Adam Arechiga</option>
              <option>Hector Betancourt</option>
              <option>Maya Boustani</option>
              <option>Colleen Brenner</option>
              <option>Patricia Flynn</option>
              <option>Paul Haerich</option>
              <option>Richard Hartman</option>
              <option>Grace Lee</option>
              <option>Kelly Morton</option>
              <option>Cameron Neece</option>
              <option>Tori Van Dyk</option>
            </Input>
          </Col>
        </Row>
        <p>
          <br />
        </p>
        {/* // Add Student - Q6 */}
        {/* Add Student - Q7 */}
        <Row>
          <Col sm={3} className="text-md-right">
            <Label for="concentrationSelect">Concentration</Label>
          </Col>
          <Col md={4}>
            <Input
              type="select"
              name="concentrationSelect"
              id="concentrationSelect"
              value={this.state.concentrationSelect}
              onChange={this.handleInputChange}
            >
              <option>Autism</option>
              <option>Behavior promotion</option>
              <option>Behavioral sleep medicine</option>
              <option>Childhood disorder</option>
              <option>Clinical neuropsychology</option>
              <option>Developmental psychology</option>
              <option>Electroencephalography</option>
              <option>Health disparities</option>
              <option>Obesity management</option>
            </Input>
          </Col>
        </Row>
        <p>
          <br />
        </p>
        {/* // Add Student - Q7 */}
      </div>
    );
  }

  renderClinicalSupervisorForm() {
    return (
      <div>
        {/* Add Clinical Supervisor - Q1 */}
        <Row>
          <Col sm={3} className="text-md-right">
            <Label for="firstName">First name</Label>
          </Col>
          <Col sm={4}>
            <Input
              type="text"
              id="firstName"
              name="firstName"
              value={this.state.firstName}
              onChange={this.handleInputChange}
            />
            <br />
          </Col>
        </Row>
        {/* // Add Clinical Supervisor - Q1 */}
        {/* Add Clinical Supervisor - Q2 */}
        <Row>
          <Col sm={3} className="text-md-right">
            <Label for="lastName">Last name</Label>
          </Col>
          <Col sm={4}>
            <Input
              type="text"
              id="lastName"
              name="lastName"
              value={this.state.lastName}
              onChange={this.handleInputChange}
            />
            <br />
          </Col>
        </Row>
        {/* // Add Clinical Supervisor - Q2 */}
        {/* Add Clinical Supervisor - Q3 */}
        <Row>
          <Col sm={3} className="text-md-right">
            <Label for="email">Email</Label>
          </Col>
          <Col sm={4}>
            <Input
              type="text"
              id="emailAddress"
              name="emailAddress"
              value={this.state.emailAddress}
              onChange={this.handleInputChange}
            />
            <br />
          </Col>
        </Row>
        <p>
          <br />
        </p>
        {/* // Add Clinical Supervisor - Q3 */}
      </div>
    );
  }

  renderAddUserForm() {
    return (
      <div>
        <FormGroup>
          <Form>
            {/* Add User - Q1 */}
            <Row>
              <Col sm={3} className="text-md-right">
                <Label>User type</Label>
              </Col>
              <Col sm={7}>
                <Button
                  className="btn-form"
                  color={this.state.userType === 'Faculty' ? 'dark' : 'inverse'}
                  onClick={() => this.onUserTypeBtnClick('Faculty')}
                  active={this.state.userType === 'Faculty'}
                >
                  Faculty
                </Button>
                <Button
                  className="btn-form"
                  color={this.state.userType === 'Staff' ? 'dark' : 'inverse'}
                  onClick={() => this.onUserTypeBtnClick('Staff')}
                  active={this.state.userType === 'Staff'}
                >
                  Staff
                </Button>
                <Button
                  className="btn-form"
                  color={this.state.userType === 'Student' ? 'dark' : 'inverse'}
                  onClick={() => this.onUserTypeBtnClick('Student')}
                  active={this.state.userType === 'Student'}
                >
                  Student
                </Button>
                <Button
                  className="btn-form"
                  color={this.state.userType === 'Clinical Supervisor' ? 'dark' : 'inverse'}
                  onClick={() => this.onUserTypeBtnClick('Clinical Supervisor')}
                  active={this.state.userType === 'Clinical Supervisor'}
                >
                  Clinical Supervisor
                </Button>
              </Col>
            </Row>
            <p>
              <br />
            </p>
            {/* // Add User - Q1 */}
            {(() => {
              if (this.state.userType === 'Faculty') {
                return this.renderFacultyForm();
              }
              if (this.state.userType === 'Staff') {
                return this.renderStaffForm();
              }
              if (this.state.userType === 'Student') {
                return this.renderStudentForm();
              }
              if (this.state.userType === 'Clinical Supervisor') {
                return this.renderClinicalSupervisorForm();
              }
            })()}
            <FormGroup row className="submitBlock">
              <Col md={12} className="text-center">
                <Button color="info" onClick={() => this.onSubmitBtnClick()} className="ml-xs">
                  Add User
                </Button>
              </Col>
            </FormGroup>
          </Form>
        </FormGroup>
        <p>
          <br />
        </p>
      </div>
    );
  }

  renderUserAddedConfirmation() {
    return (
      <div>
        <Row>
          <Col className="text-center">
            <p>
              <h3>User Added!</h3>
            </p>
            <p>A confirmation email containing the login information has been sent to the user. </p>
            <Form>
              <FormGroup row className="submitBlock">
                <Col md={12} className="text-center">
                  <Button
                    color="info"
                    onClick={() => this.onAddAnotherUserBtnClick()}
                    className="ml-xs"
                  >
                    Click Here to Add Another User
                  </Button>
                </Col>
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <p>
          <br />
        </p>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Row>
          {/* Selection */}
          <Col xl={10} xs={12}>
            <Widget
              title={
                <h4>
                  {' '}
                  <i className="fa fa-align-left" /> Add User{' '}
                </h4>
              }
            >
              {(() => {
                if (this.state.showConfirmation === false) {
                  return this.renderAddUserForm();
                }
                if (this.state.showConfirmation === true) {
                  return this.renderUserAddedConfirmation();
                }
              })()}
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }
}

export default AddUser;
