/* eslint-disable consistent-return */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import auth0Client from '../../Auth/Auth';

// import StudentAdmin from '../StudentAdmin/StudentAdmin';
import StaffAdmin from '../StaffAdmin/StaffAdmin';
import FacilityAdmin from '../FacilityAdmin/FacilityAdmin';
import FormAdmin from '../FormAdmin/FormAdmin';
import Reporting from '../Reporting/Reporting';
import AddUser from '../AddUser/AddUser';
import UserAdmin from '../UserAdmin/UserAdmin';

import './Dashboard.scss';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userType: 'admin',
      currentView: 'User Admin',
    };

    this.onSectionBtnClick = this.onSectionBtnClick.bind(this);
    // this.toggleActiveTab = this.toggleActiveTab.bind(this);
  }

  componentDidMount() {
    const profile = auth0Client.getProfile();
    const namespace = 'https://api.llusbh.com/role';

    this.setState({ userType: profile[namespace][0] });
  }

  onSectionBtnClick(sectionValue) {
    this.setState({
      currentView: sectionValue,
    });
  }

  // toggleActiveTab(tabIndex) {
  //   this.setState({ activeTab: tabIndex });
  // }

  render() {
    const { userType } = this.state;
    return (
      <Container fluid>
        <Row>
          <Col xl={{ size: 10, offset: 1 }}>
            <h2 className="page-title mt-3">
              {userType === 'admin' ? 'ADMIN' : userType === 'faculty' ? 'FACULTY' : 'STAFF'}{' '}
              DASHBOARD
            </h2>
          </Col>
        </Row>
        {(() => {
          if (this.state.userType === 'admin' || this.state.userType === 'staff') {
            return (
              <Row>
                <Col xl={{ size: 2, offset: 1 }} md={3}>
                  <Button
                    color={this.state.currentView === 'User Admin' ? 'info' : 'secondary'}
                    size="lg"
                    onClick={() => this.onSectionBtnClick('User Admin')}
                    active={this.state.currentView === 'User Admin'}
                    block
                  >
                    User Admin
                  </Button>
                </Col>
                <Col xl={2} md={3}>
                  <Button
                    color={this.state.currentView === 'Facility Admin' ? 'info' : 'secondary'}
                    size="lg"
                    onClick={() => this.onSectionBtnClick('Facility Admin')}
                    active={this.state.currentView === 'Facility Admin'}
                    block
                  >
                    Facility Admin
                  </Button>
                </Col>
                <Col xl={2} md={3}>
                  <Button
                    color={this.state.currentView === 'Form Admin' ? 'info' : 'secondary'}
                    size="lg"
                    onClick={() => this.onSectionBtnClick('Form Admin')}
                    active={this.state.currentView === 'Form Admin'}
                    block
                  >
                    Form Admin
                  </Button>
                </Col>
                <Col xl={2} md={3}>
                  <Button
                    color={this.state.currentView === 'Reporting' ? 'info' : 'secondary'}
                    size="lg"
                    onClick={() => this.onSectionBtnClick('Reporting')}
                    active={this.state.currentView === 'Reporting'}
                    block
                  >
                    Reporting
                  </Button>
                </Col>
                {/* <Col lg={2}>
                  <Button
                    color={this.state.currentView === 'Add User' ? 'info' : 'secondary'}
                    size="lg"
                    onClick={() => this.onSectionBtnClick('Add User')}
                    active={this.state.currentView === 'Add User'}
                    block
                  >
                    Add User
                  </Button>
                </Col> */}
              </Row>
            );
          }
        })()}
        {(() => {
          if (this.state.userType === 'faculty') {
            return (
              <Row>
                <Col xl={{ size: 2, offset: 1 }} md={3}>
                  <Button
                    color={this.state.currentView === 'User Admin' ? 'info' : 'secondary'}
                    size="lg"
                    onClick={() => this.onSectionBtnClick('User Admin')}
                    active={this.state.currentView === 'User Admin'}
                    block
                  >
                    Students
                  </Button>
                </Col>
                <Col xl={2} md={3}>
                  <Button
                    color={this.state.currentView === 'Reporting' ? 'info' : 'secondary'}
                    size="lg"
                    onClick={() => this.onSectionBtnClick('Reporting')}
                    active={this.state.currentView === 'Reporting'}
                    block
                  >
                    Reporting
                  </Button>
                </Col>
              </Row>
            );
          }
        })()}
        {(() => {
          if (this.state.currentView === 'User Admin') {
            return <UserAdmin />;
          }
          if (this.state.currentView === 'Staff Admin') {
            return <StaffAdmin />;
          }
          if (this.state.currentView === 'Facility Admin') {
            return <FacilityAdmin />;
          }
          if (this.state.currentView === 'Form Admin') {
            return <FormAdmin />;
          }
          if (this.state.currentView === 'Reporting') {
            return <Reporting />;
          }
          if (this.state.currentView === 'Add User') {
            return <AddUser />;
          }
        })()}
      </Container>
    );
  }
}

export default Dashboard;
