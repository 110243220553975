/* eslint-disable class-methods-use-this */
/* eslint-disable consistent-return */
/* eslint-disable react/no-access-state-in-setstate */

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Alert,
  Progress,
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
} from 'reactstrap';
import axios from 'axios';
import auth0Client from '../../../Auth/Auth';

import Widget from '../../Widget/Widget';

import '../forms.scss';

import loadingSpinner from '../../../images/Eclipse-1s-200px.svg';

class asFacultyReadout extends React.Component {
  constructor(props) {
    super(props);
    this.getUploadedFiles = this.getUploadedFiles.bind(this);
    this.getForm = this.getForm.bind(this);
    this.renderUploadedFiles = this.renderUploadedFiles.bind(this);
    this.renderReadout = this.renderReadout.bind(this);

    this.state = {
      formData: [],
      emailAddress: '',
      yearGraduatedSelect: '',
      ethnicitySelect: '',
      raceSelect: '',
      postdoctoralProgramName: '',
      postdoctoralProgramAddress: '',
      otherEmphasis: '',
      employerName: '',
      employerAddress: '',
      jobTitle: '',
      otherPositionSetting: '',
      otherWorkSetting: '',
      stateSelect: '',
      dateSelect: '',
      licenseNumber: '',
      npiNumber: '',
      organizationName: '',
      publicationSelect: '',
      presentationsSelect: '',
      doWell: '',
      doneBetter: '',
      suggestions: '',
      uploadedFiles: [],
      studentID: '',
      studentName: '',
      currentSection: 0,
      fullAlumniSurvey: false,
      loading: true,
      error: false,
    };
  }

  componentDidMount() {
    this.getForm();
  }

  getUploadedFiles() {
    const {
      match: { params },
    } = this.props;
    const url = `${process.env.REACT_APP_API_URL}/file/${params.formid}`;

    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        this.setState({ uploadedFiles: response.data, loading: false });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        this.setState({
          loading: false,
          error: true,
        });
      });
  }

  getForm() {
    const {
      match: { params },
    } = this.props;
    const url = `${process.env.REACT_APP_API_URL}/form/${params.formid}`;

    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        // check if data is valid
        const { data, student, fullAlumniSurvey } = response.data;
        const { _id } = student;
        const studentName = `${student.firstName} ${student.lastName}`;

        let {
          formData,
          emailAddress,
          yearGraduatedSelect,
          ethnicitySelect,
          raceSelect,
          postdoctoralProgramName,
          postdoctoralProgramAddress,
          otherEmphasis,
          employerName,
          employerAddress,
          jobTitle,
          otherPositionSetting,
          otherWorkSetting,
          stateSelect,
          dateSelect,
          licenseNumber,
          npiNumber,
          organizationName,
          publicationSelect,
          presentationsSelect,
          doWell,
          doneBetter,
          suggestions,
          currentSection,
        } = this.state;

        // check if data was returned in a array
        if (data.length === 14) {
          formData = data;
        } else {
          // eslint-disable-next-line prefer-destructuring
          formData = data[0];
        }

        // * Demographics
        emailAddress = formData[0].data[0].value;
        if (formData[0].data[1].value !== '') {
          yearGraduatedSelect = formData[0].data[1].value;
        }
        if (formData[0].data[2].value !== '') {
          ethnicitySelect = formData[0].data[2].value;
        }
        if (formData[0].data[3].value !== '') {
          raceSelect = formData[0].data[3].value;
        }

        // * Formal Postdoctoral Training Program
        postdoctoralProgramName = formData[1].data[1].value;
        postdoctoralProgramAddress = formData[1].data[2].value;
        otherEmphasis = formData[1].data[5].value;

        // * Professional Employment
        employerName = formData[2].data[1].value;
        employerAddress = formData[2].data[2].value;
        jobTitle = formData[2].data[3].value;
        otherPositionSetting = formData[2].data[5].value;
        otherWorkSetting = formData[2].data[8].value;

        // * State Licensed Psychologist
        if (formData[3].data[1].value !== '') {
          stateSelect = formData[3].data[1].value;
        }
        if (formData[3].data[2].value !== '') {
          dateSelect = formData[3].data[2].value;
        }
        licenseNumber = formData[3].data[3].value;
        npiNumber = formData[3].data[4].value;

        // * Other Professional Activities
        organizationName = formData[4].data[1].value;
        if (formData[4].data[2].value !== '') {
          publicationSelect = formData[4].data[2].value;
        }
        if (formData[4].data[3].value !== '') {
          presentationsSelect = formData[4].data[3].value;
        }

        // * Program Feedback
        doWell = formData[5].data[0].value;
        doneBetter = formData[5].data[1].value;
        suggestions = formData[5].data[2].value;

        currentSection = 999;

        // form completed, set student info and formData
        this.setState({
          formData,
          emailAddress,
          yearGraduatedSelect,
          ethnicitySelect,
          raceSelect,
          postdoctoralProgramName,
          postdoctoralProgramAddress,
          otherEmphasis,
          employerName,
          employerAddress,
          jobTitle,
          otherPositionSetting,
          otherWorkSetting,
          stateSelect,
          dateSelect,
          licenseNumber,
          npiNumber,
          organizationName,
          publicationSelect,
          presentationsSelect,
          doWell,
          doneBetter,
          suggestions,
          studentID: _id,
          studentName,
          currentSection,
          fullAlumniSurvey,
        });
        this.getUploadedFiles();
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        this.setState({
          loading: false,
          error: true,
        });
      });
  }

  renderUploadedFiles(uploadType) {
    const { uploadedFiles } = this.state;

    // get files for selected uploadType
    const filteredFiles = uploadedFiles.filter((val) => val.uploadType === uploadType);

    return (
      <div>
        <FormGroup className="submitBlock">
          <Row className="justify-content-center">
            <Col md={6} className="text-center">
              <Label>
                <i className="fa fa-chevron-right" /> Uploaded files
              </Label>
              {filteredFiles.map((file) => (
                // eslint-disable-next-line no-underscore-dangle
                <InputGroup key={file._id} className="mt-2">
                  <Input type="text" id="uploadFile-input" placeholder={file.filename} disabled />
                </InputGroup>
              ))}
            </Col>
          </Row>
        </FormGroup>
      </div>
    );
  }

  renderReadout() {
    const {
      formData,
      emailAddress,
      yearGraduatedSelect,
      ethnicitySelect,
      raceSelect,
      postdoctoralProgramName,
      postdoctoralProgramAddress,
      otherEmphasis,
      employerName,
      employerAddress,
      jobTitle,
      otherPositionSetting,
      otherWorkSetting,
      stateSelect,
      dateSelect,
      licenseNumber,
      npiNumber,
      organizationName,
      publicationSelect,
      presentationsSelect,
      doWell,
      doneBetter,
      suggestions,
      fullAlumniSurvey,
    } = this.state;

    // set trainingProgramActivities array for Readout
    const trainingProgramActivities = [];
    if (formData[1].data[6].value[0].value === 1) {
      trainingProgramActivities.push('Administration');
    }
    if (formData[1].data[6].value[1].value === 1) {
      trainingProgramActivities.push('Assessment');
    }
    if (formData[1].data[6].value[2].value === 1) {
      trainingProgramActivities.push('Consultation');
    }
    if (formData[1].data[6].value[3].value === 1) {
      trainingProgramActivities.push('Psychotherapy');
    }
    if (formData[1].data[6].value[4].value === 1) {
      trainingProgramActivities.push('Research');
    }
    if (formData[1].data[6].value[5].value === 1) {
      trainingProgramActivities.push('Supervision');
    }
    if (formData[1].data[6].value[6].value === 1) {
      trainingProgramActivities.push('Teaching');
    }
    if (formData[1].data[6].value[7].value === 1) {
      trainingProgramActivities.push('Unknown');
    }
    if (formData[1].data[6].value[8].value === 1) {
      trainingProgramActivities.push('Other');
    }
    if (trainingProgramActivities.length === 0) {
      trainingProgramActivities.push('N/A');
    }

    // set employmentSettingTypes array for Readout
    const employmentSettingTypes = [];
    if (formData[2].data[4].value[0].value === 1) {
      employmentSettingTypes.push('Academic Teaching');
    }
    if (formData[2].data[4].value[1].value === 1) {
      employmentSettingTypes.push('Academic Medical Center');
    }
    if (formData[2].data[4].value[2].value === 1) {
      employmentSettingTypes.push('Area Health Education Center');
    }
    if (formData[2].data[4].value[3].value === 1) {
      employmentSettingTypes.push('Certified Community Behavioral Health Center (CCBHC)');
    }
    if (formData[2].data[4].value[4].value === 1) {
      employmentSettingTypes.push('Community Mental Health Center');
    }
    if (formData[2].data[4].value[5].value === 1) {
      employmentSettingTypes.push('Community Health Center (CHC)');
    }
    if (formData[2].data[4].value[6].value === 1) {
      employmentSettingTypes.push('Consortium');
    }
    if (formData[2].data[4].value[7].value === 1) {
      employmentSettingTypes.push('Correctional Facility');
    }
    if (formData[2].data[4].value[8].value === 1) {
      employmentSettingTypes.push('Critical Access Hospital');
    }
    if (formData[2].data[4].value[9].value === 1) {
      employmentSettingTypes.push('Federal Government');
    }
    if (formData[2].data[4].value[10].value === 1) {
      employmentSettingTypes.push('FQHC or Look-Alike');
    }
    if (formData[2].data[4].value[11].value === 1) {
      employmentSettingTypes.push('Health Department (local/state/tribal)');
    }
    if (formData[2].data[4].value[12].value === 1) {
      employmentSettingTypes.push('Health Maintenance Organization');
    }
    if (formData[2].data[4].value[13].value === 1) {
      employmentSettingTypes.push('Hospital (non-academic)');
    }
    if (formData[2].data[4].value[14].value === 1) {
      employmentSettingTypes.push('Indian Health Service (HIS)/Tribal/Urban Indian Health Center');
    }
    if (formData[2].data[4].value[15].value === 1) {
      employmentSettingTypes.push('Independent Practice');
    }
    if (formData[2].data[4].value[16].value === 1) {
      employmentSettingTypes.push('Nursing Home');
    }
    if (formData[2].data[4].value[17].value === 1) {
      employmentSettingTypes.push('Other Clinical Health Setting');
    }
    if (formData[2].data[4].value[18].value === 1) {
      employmentSettingTypes.push('Other Community-Based Organization');
    }
    if (formData[2].data[4].value[19].value === 1) {
      employmentSettingTypes.push('Other Long-term Care Facility');
    }
    if (formData[2].data[4].value[20].value === 1) {
      employmentSettingTypes.push('Other Specialty Clinic');
    }
    if (formData[2].data[4].value[21].value === 1) {
      employmentSettingTypes.push('Private Industry');
    }
    if (formData[2].data[4].value[22].value === 1) {
      employmentSettingTypes.push('Psychiatric Facility');
    }
    if (formData[2].data[4].value[23].value === 1) {
      employmentSettingTypes.push(
        'Residential Living Facility (including independent and assisted living)',
      );
    }
    if (formData[2].data[4].value[24].value === 1) {
      employmentSettingTypes.push('Rural Health Clinic');
    }
    if (formData[2].data[4].value[25].value === 1) {
      employmentSettingTypes.push('School District or System');
    }
    if (formData[2].data[4].value[26].value === 1) {
      employmentSettingTypes.push('State or Local Government');
    }
    if (formData[2].data[4].value[27].value === 1) {
      employmentSettingTypes.push('US Armed Forces');
    }
    if (formData[2].data[4].value[28].value === 1) {
      employmentSettingTypes.push('Veterans Affairs Healthcare (e.g., VA hospital or clinic)');
    }
    if (formData[2].data[4].value[29].value === 1) {
      employmentSettingTypes.push('Pursuing Additional Education or Training');
    }
    if (formData[2].data[4].value[30].value === 1) {
      employmentSettingTypes.push('University Counseling Center');
    }
    if (formData[2].data[4].value[31].value === 1) {
      employmentSettingTypes.push('Not Currently Employed');
    }
    if (formData[2].data[4].value[32].value === 1) {
      employmentSettingTypes.push('Other');
    }
    if (employmentSettingTypes.length === 0) {
      employmentSettingTypes.push('N/A');
    }

    // set employmentActivities array for Readout
    const employmentActivities = [];
    if (formData[2].data[6].value[0].value === 1) {
      employmentActivities.push('Administration');
    }
    if (formData[2].data[6].value[1].value === 1) {
      employmentActivities.push('Assessment');
    }
    if (formData[2].data[6].value[2].value === 1) {
      employmentActivities.push('Consultation');
    }
    if (formData[2].data[6].value[3].value === 1) {
      employmentActivities.push('Psychotherapy');
    }
    if (formData[2].data[6].value[4].value === 1) {
      employmentActivities.push('Research');
    }
    if (formData[2].data[6].value[5].value === 1) {
      employmentActivities.push('Supervision');
    }
    if (formData[2].data[6].value[6].value === 1) {
      employmentActivities.push('Teaching');
    }
    if (formData[2].data[6].value[7].value === 1) {
      employmentActivities.push('Unknown');
    }
    if (formData[2].data[6].value[8].value === 1) {
      employmentActivities.push('Other');
    }
    if (employmentActivities.length === 0) {
      employmentActivities.push('N/A');
    }

    // set employmentWorkSettings array for Readout
    const employmentWorkSettings = [];
    if (formData[2].data[7].value[0].value === 1) {
      employmentWorkSettings.push('Health Professional Shortage Area');
    }
    if (formData[2].data[7].value[1].value === 1) {
      employmentWorkSettings.push('Medically Underserved Community');
    }
    if (formData[2].data[7].value[2].value === 1) {
      employmentWorkSettings.push('National Health Service Corps (NHSC) - Approved Site');
    }
    if (formData[2].data[7].value[3].value === 1) {
      employmentWorkSettings.push('Primary Care Setting');
    }
    if (formData[2].data[7].value[4].value === 1) {
      employmentWorkSettings.push('Rural Area');
    }
    if (formData[2].data[7].value[5].value === 1) {
      employmentWorkSettings.push('None of the Above');
    }
    if (formData[2].data[7].value[6].value === 1) {
      employmentWorkSettings.push('Other');
    }
    if (employmentWorkSettings.length === 0) {
      employmentWorkSettings.push('N/A');
    }

    return (
      <div>
        <Row>
          <Col>
            {/* Demographics */}
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Demographics
                </h5>
              }
            >
              {/* Demographics - Permanent Email */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Permanent e-mail address: <b className="ml-2">{emailAddress}</b>
                  </p>
                </Col>
              </Row>
              {/* // Demographics - Permanent Email */}
              {/* Demographics - Year Graduated */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Year Graduated from LLU: <b className="ml-2">{yearGraduatedSelect}</b>
                  </p>
                </Col>
              </Row>
              {/* // Demographics - Year Graduated */}
              {/* Demographics - Ethnicity */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Ethnicity: <b className="ml-2">{ethnicitySelect}</b>
                  </p>
                </Col>
              </Row>
              {/* // Demographics - Ethnicity */}
              {/* Demographics - Race */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Race: <b className="ml-2">{raceSelect}</b>
                  </p>
                </Col>
              </Row>
              {/* // Demographics - Race */}
              {/* Demographics - 1st Gen College */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Are you the first generation in your family to graduate from college?{' '}
                    <b className="ml-2">{formData[0].data[4].value}</b>
                  </p>
                </Col>
              </Row>
              {/* // Demographics - 1st Gen College */}
              {/* Demographics - Veteran */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Are you a military veteran? <b className="ml-2">{formData[0].data[5].value}</b>
                  </p>
                </Col>
              </Row>
              {/* // Demographics - Veteran */}
            </Widget>

            {/* Formal Postdoctoral Training Program */}
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Formal Postdoctoral Training Program
                </h5>
              }
            >
              {/* Formal Postdoctoral Training Program - Q1 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q1</b>
                    </Label>{' '}
                    Are you enrolled in a formal postdoctoral training program?{' '}
                    <b className="ml-2">{formData[1].data[0].value}</b>
                  </p>
                </Col>
              </Row>
              {/* Formal Postdoctoral Training Program - Q1 - subQs */}
              {(() => {
                if (formData[1].data[0].value === 'Yes') {
                  return (
                    <div>
                      {/* Formal Postdoctoral Training Program - Post Doctoral Program Name */}
                      <Row className="mt-4">
                        <Col className="text-center">
                          <p className="readout">
                            <Label>
                              <i className="fa fa-chevron-right" />
                            </Label>{' '}
                            Program Name: <b className="ml-2">{postdoctoralProgramName}</b>
                          </p>
                        </Col>
                      </Row>
                      {/* // Formal Postdoctoral Training Program - Post Doctoral Program Name */}
                      {/* Formal Postdoctoral Training Program - Post Doctoral Program Address */}
                      <Row className="mt-4">
                        <Col className="text-center">
                          <p className="readout">
                            <Label>
                              <i className="fa fa-chevron-right" />
                            </Label>{' '}
                            Program Address: <b className="ml-2">{postdoctoralProgramAddress}</b>
                          </p>
                        </Col>
                      </Row>
                      {/* // Formal Postdoctoral Training Program - Post Doctoral Program Address */}
                      {/* // Formal Postdoctoral Training Program - Accredited */}
                      <Row className="mt-4">
                        <Col className="text-center">
                          <p className="readout">
                            <Label>
                              <i className="fa fa-chevron-right" />
                            </Label>{' '}
                            Is it accredited? <b className="ml-2">{formData[1].data[3].value}</b>
                          </p>
                        </Col>
                      </Row>
                      {/* // Formal Postdoctoral Training Program - Accredited */}
                      {/* Formal Postdoctoral Training Program - Emphasis */}
                      <Row className="mt-4">
                        <Col className="text-center">
                          <p className="readout">
                            <Label>
                              <i className="fa fa-chevron-right" />
                            </Label>{' '}
                            Emphasis: <b className="ml-2">{formData[1].data[4].value}</b>
                          </p>
                        </Col>
                      </Row>
                      {(() => {
                        if (formData[1].data[4].value === 'Other') {
                          return (
                            <div>
                              <Row className="mt-4">
                                <Col className="text-center">
                                  <p className="readout">
                                    Please specify the other emphasis:{' '}
                                    <b className="ml-2">{otherEmphasis}</b>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          );
                        }
                      })()}
                      {/* // Formal Postdoctoral Training Program - Emphasis */}
                      {/* Formal Postdoctoral Training Program - Activities */}
                      <Row className="mt-4">
                        <Col className="text-center">
                          <p className="readout">
                            <Label>
                              <i className="fa fa-chevron-right" />
                            </Label>{' '}
                            Select all of the activities that apply to the position:
                            <b className="ml-2">{trainingProgramActivities.join(', ')}</b>
                          </p>
                        </Col>
                      </Row>
                      {/* // Formal Postdoctoral Training Program - Activities */}
                    </div>
                  );
                }
              })()}
              {/* // Formal Postdoctoral Training Program - Q1 - subQs */}
              {/* // Formal Postdoctoral Training Program - Q1 */}
            </Widget>

            {/* Professional Employment */}
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Professional Employment
                </h5>
              }
            >
              {/* Professional Employment - Q2 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q2</b>
                    </Label>{' '}
                    Are you employed professionally?{' '}
                    <b className="ml-2">{formData[2].data[0].value}</b>
                  </p>
                </Col>
              </Row>
              {/* Professional Employment - Q2 - subQs */}
              {(() => {
                if (formData[2].data[0].value === 'Yes') {
                  return (
                    <div>
                      {/* Professional Employment - Q2 - Subquestions */}
                      {/* Professional Employment - Employer Name */}
                      <Row className="mt-4">
                        <Col className="text-center">
                          <p className="readout">
                            <Label>
                              <i className="fa fa-chevron-right" />
                            </Label>{' '}
                            Employer Name: <b className="ml-2">{employerName}</b>
                          </p>
                        </Col>
                      </Row>
                      {/* // Professional Employment - Employer Name */}
                      {/* Professional Employment - Employer Address */}
                      <Row className="mt-4">
                        <Col className="text-center">
                          <p className="readout">
                            <Label>
                              <i className="fa fa-chevron-right" />
                            </Label>{' '}
                            Employer Address: <b className="ml-2">{employerAddress}</b>
                          </p>
                        </Col>
                      </Row>
                      {/* // Professional Employment - Employer Address */}
                      {/* Professional Employment - Job Title */}
                      <Row className="mt-4">
                        <Col className="text-center">
                          <p className="readout">
                            <Label>
                              <i className="fa fa-chevron-right" />
                            </Label>{' '}
                            Job Title: <b className="ml-2">{jobTitle}</b>
                          </p>
                        </Col>
                      </Row>
                      {/* // Professional Employment - Job Title */}
                      {/* Professional Employment - Setting Types */}
                      <Row className="mt-4">
                        <Col className="text-center">
                          <p className="readout">
                            <Label>
                              <i className="fa fa-chevron-right" />
                            </Label>{' '}
                            Select all of the activities that apply to the position:
                            <b className="ml-2">{employmentSettingTypes.join(', ')}</b>
                          </p>
                        </Col>
                      </Row>
                      {(() => {
                        if (formData[2].data[4].value[32].value === 1) {
                          return (
                            <div>
                              <Row className="mt-4">
                                <Col className="text-center">
                                  <p className="readout">
                                    Please specify the other setting type:{' '}
                                    <b className="ml-2">{otherPositionSetting}</b>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          );
                        }
                      })()}
                      {/* // Professional Employment - Setting Types */}
                      {/* Professional Employment - Activities */}
                      <Row className="mt-4">
                        <Col className="text-center">
                          <p className="readout">
                            <Label>
                              <i className="fa fa-chevron-right" />
                            </Label>{' '}
                            Select all of the activities that apply to the position:
                            <b className="ml-2">{employmentActivities.join(', ')}</b>
                          </p>
                        </Col>
                      </Row>
                      {/* // Professional Employment - Activities */}
                      {/* Professional Employment - Work Settings */}
                      <Row className="mt-4">
                        <Col className="text-center">
                          <p className="readout">
                            <Label>
                              <i className="fa fa-chevron-right" />
                            </Label>{' '}
                            Do you work in any of the following settings?
                            <b className="ml-2">{employmentWorkSettings.join(', ')}</b>
                          </p>
                        </Col>
                      </Row>
                      {(() => {
                        if (formData[2].data[7].value[6].value === 1) {
                          return (
                            <div>
                              <Row className="mt-4">
                                <Col className="text-center">
                                  <p className="readout">
                                    Please specify the other setting:{' '}
                                    <b className="ml-2">{otherWorkSetting}</b>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          );
                        }
                      })()}
                      {/* // Professional Employment - Work Settings */}
                    </div>
                  );
                }
              })()}
              {/* // Professional Employment - Q2 - subQs */}
              {/* // Professional Employment - Q2 */}
            </Widget>

            {/* State Licensed Psychologist */}
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> State Licensed Psychologist
                </h5>
              }
            >
              {/* State Licensed Psychologist - Q3 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q3</b>
                    </Label>{' '}
                    Did you obtain a license as a psychologist?{' '}
                    <b className="ml-2">{formData[3].data[0].value}</b>
                  </p>
                </Col>
              </Row>
              {/* State Licensed Psychologist - Q3 - subQs */}
              {(() => {
                if (formData[3].data[0].value === 'Yes') {
                  return (
                    <div>
                      {/* State Licensed Psychologist - State */}
                      <Row className="mt-4">
                        <Col className="text-center">
                          <p className="readout">
                            <Label>
                              <i className="fa fa-chevron-right" />
                            </Label>{' '}
                            State: <b className="ml-2">{stateSelect}</b>
                          </p>
                        </Col>
                      </Row>
                      {/* // State Licensed Psychologist - State */}
                      {/* State Licensed Psychologist - License Date */}
                      <Row className="mt-4">
                        <Col className="text-center">
                          <p className="readout">
                            <Label>
                              <i className="fa fa-chevron-right" />
                            </Label>{' '}
                            License Date:{' '}
                            {/* <b className="ml-2">{dateSelect.toLocaleDateString('en-US')}</b> */}
                            <b className="ml-2">{dateSelect}</b>
                          </p>
                        </Col>
                      </Row>
                      {/* // State Licensed Psychologist - License Date */}
                      {/* State Licensed Psychologist - License Number */}
                      <Row className="mt-4">
                        <Col className="text-center">
                          <p className="readout">
                            <Label>
                              <i className="fa fa-chevron-right" />
                            </Label>{' '}
                            License Number: <b className="ml-2">{licenseNumber}</b>
                          </p>
                        </Col>
                      </Row>
                      {/* // State Licensed Psychologist - License Number */}
                      {/* State Licensed Psychologist - NPI Number */}
                      <Row className="mt-4">
                        <Col className="text-center">
                          <p className="readout">
                            <Label>
                              <i className="fa fa-chevron-right" />
                            </Label>{' '}
                            NPI Number: <b className="ml-2">{npiNumber}</b>
                          </p>
                        </Col>
                      </Row>
                      {/* // State Licensed Psychologist - NPI Number */}
                    </div>
                  );
                }
              })()}
              {/* // State Licensed Psychologist - Q3 - subQs */}
              {/* // State Licensed Psychologist - Q3 */}
            </Widget>

            {/* Other Professional Activities */}
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Other Professional Activities
                </h5>
              }
            >
              {/* Other Professional Activities - Q4 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q4</b>
                    </Label>{' '}
                    Are you a member of a professional/scientific organization?{' '}
                    <b className="ml-2">{formData[4].data[0].value}</b>
                  </p>
                </Col>
              </Row>
              {/* Other Professional Activities - Q4 - subQs */}
              {(() => {
                if (formData[4].data[0].value === 'Yes') {
                  return (
                    <div>
                      <Row className="mt-4">
                        <Col className="text-center">
                          <p className="readout">
                            <Label>
                              <i className="fa fa-chevron-right" />
                            </Label>{' '}
                            Which one? <b className="ml-2">{organizationName}</b>
                          </p>
                        </Col>
                      </Row>
                    </div>
                  );
                }
              })()}
              {/* // Other Professional Activities - Q4 - subQs */}
              {/* // Other Professional Activities - Q4 */}
              {/* Other Professional Activities - Q5 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q5</b>
                    </Label>{' '}
                    Number of publications since the year you graduated:{' '}
                    <b className="ml-2">{publicationSelect}</b>
                  </p>
                </Col>
              </Row>
              {/* // Other Professional Activities - Q5 */}
              {/* Other Professional Activities - Q6 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q6</b>
                    </Label>{' '}
                    Number of conference presentations since the year you graduated:{' '}
                    <b className="ml-2">{presentationsSelect}</b>
                  </p>
                </Col>
              </Row>
              {/* // Other Professional Activities - Q5 */}
              {/* Other Professional Activities - Q7 */}
              <Row className="mt-4">
                <Col sm={12} className="text-center">
                  <Label>
                    <b>
                      <u>Please upload your most up to date CV</u>
                    </b>
                  </Label>
                  {this.renderUploadedFiles('cv')}
                </Col>
              </Row>
              {/* // Other Professional Activities - Q7 */}
            </Widget>

            {/* Program Feedback */}
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Program Feedback
                </h5>
              }
            >
              {/* Program Feedback - Q7 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q7</b>
                    </Label>{' '}
                    <u>
                      What did LLU do particularly well in preparing you for your current
                      employment?
                    </u>
                  </p>
                  <p className="readoutComments">{doWell}</p>
                </Col>
              </Row>
              {/* // Program Feedback - Q7 */}
              {/* Program Feedback - Q8 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q8</b>
                    </Label>{' '}
                    <u>
                      What do you think LLU could have done better to prepare you for your current
                      employment?
                    </u>
                  </p>
                  <p className="readoutComments">{doneBetter}</p>
                </Col>
              </Row>
              {/* // Program Feedback - Q8 */}
              {/* Program Feedback - Q9 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q9</b>
                    </Label>{' '}
                    <u>
                      Do you have any suggestion for us that would help us better prepare our
                      graduates for future employment?
                    </u>
                  </p>
                  <p className="readoutComments">{suggestions}</p>
                </Col>
              </Row>
              {/* // Program Feedback - Q9 */}
            </Widget>

            {/* ONLY DISPLAY IF FULL SURVEY */}
            {fullAlumniSurvey && (
              <div>
                {/* Research and Ethical Standards */}
                <Widget
                  title={
                    <h5>
                      <i className="fa fa-align-left" /> Research and Ethical Standards
                    </h5>
                  }
                >
                  <Row className="mt-4">
                    <Col lg={2} />
                    <Col lg={8} xs={12}>
                      <Alert color="primary">
                        <p>
                          For the next set of questions, please use the following guidelines to rate
                          how effective the program was in meeting each training goal:
                        </p>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>0</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Not effective at all
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>1</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Minimally effective
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>2</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Somewhat effective
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>3</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Moderately effective
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>4</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Very effective
                          </Col>
                        </Row>
                      </Alert>
                    </Col>
                  </Row>
                  {/* Research and Ethical Standards - Q8 */}
                  <Row className="mt-5">
                    <Col className="text-center">
                      <p className="readout">
                        <Label>
                          <b>Q10</b>
                        </Label>{' '}
                        Teaching you how to independently formulate research or other scholarly
                        activities (e.g. critical literature reviews, dissertation, efficacy
                        studies, clinical case studies, theoretical papers, program evaluation
                        projects, program development projects) that are of sufficient quality and
                        rigor to have the potential to contribute to the scientific, psychological
                        or professional knowledge base:{' '}
                        <b className="ml-2">{formData[6].data[0].value}</b>
                      </p>
                    </Col>
                  </Row>
                  {/* // Research and Ethical Standards - Q8 */}
                  {/* Research and Ethical Standards - Q9 */}
                  <Row className="mt-4">
                    <Col className="text-center">
                      <p className="readout">
                        <Label>
                          <b>Q11</b>
                        </Label>{' '}
                        Teaching you how to critically evaluate research:{' '}
                        <b className="ml-2">{formData[6].data[1].value}</b>
                      </p>
                    </Col>
                  </Row>
                  {/* // Research and Ethical Standards - Q9 */}
                  {/* Research and Ethical Standards - Q10 */}
                  <Row className="mt-4">
                    <Col className="text-center">
                      <p className="readout">
                        <Label>
                          <b>Q12</b>
                        </Label>{' '}
                        Teaching you how to disseminate research or other scholarly activities via
                        professional presentations or publications. These can be at the local,
                        regional or national level:{' '}
                        <b className="ml-2">{formData[6].data[2].value}</b>
                      </p>
                    </Col>
                  </Row>
                  {/* // Research and Ethical Standards - Q10 */}
                </Widget>

                {/* Legal and Ethical Standards */}
                <Widget
                  title={
                    <h5>
                      <i className="fa fa-align-left" /> Legal and Ethical Standards
                    </h5>
                  }
                >
                  <Row className="mt-4">
                    <Col lg={2} />
                    <Col lg={8} xs={12}>
                      <Alert color="primary">
                        <p>
                          For the next set of questions, please use the following guidelines to rate
                          how effective the program was in meeting each training goal:
                        </p>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>0</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Not effective at all
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>1</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Minimally effective
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>2</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Somewhat effective
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>3</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Moderately effective
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>4</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Very effective
                          </Col>
                        </Row>
                      </Alert>
                    </Col>
                  </Row>
                  {/* Legal and Ethical Standards - Q13 */}
                  <Row className="mt-5">
                    <Col className="text-center">
                      <p className="readout">
                        <Label>
                          <b>Q13</b>
                        </Label>{' '}
                        Teaching you how to be knowledgeable of an act in accordance with the
                        relevant laws, regulations, rules and policies governing health service
                        psychology at the organizational, local, state, regional and federal levels;
                        and relevant professional standards and guidelines:{' '}
                        <b className="ml-2">{formData[7].data[0].value}</b>
                      </p>
                    </Col>
                  </Row>
                  {/* // Legal and Ethical Standards - Q13 */}
                  {/* Legal and Ethical Standards - Q14 */}
                  <Row className="mt-4">
                    <Col className="text-center">
                      <p className="readout">
                        <Label>
                          <b>Q14</b>
                        </Label>{' '}
                        Teaching you how to recognize ethical dilemmas as they arise and apply
                        ethical decision-making processes in order to resolve the dilemmas:{' '}
                        <b className="ml-2">{formData[7].data[1].value}</b>
                      </p>
                    </Col>
                  </Row>
                  {/* // Legal and Ethical Standards - Q14 */}
                  {/* Legal and Ethical Standards - Q15 */}
                  <Row className="mt-4">
                    <Col className="text-center">
                      <p className="readout">
                        <Label>
                          <b>Q15</b>
                        </Label>{' '}
                        Teaching you to conduct yourself in an ethical manner in all professional
                        activities: <b className="ml-2">{formData[7].data[2].value}</b>
                      </p>
                    </Col>
                  </Row>
                  {/* // Legal and Ethical Standards - Q15 */}
                </Widget>

                {/* Individual and Cultural Diversity */}
                <Widget
                  title={
                    <h5>
                      <i className="fa fa-align-left" /> Individual and Cultural Diversity
                    </h5>
                  }
                >
                  <Row className="mt-4">
                    <Col lg={2} />
                    <Col lg={8} xs={12}>
                      <Alert color="primary">
                        <p>
                          For the next set of questions, please use the following guidelines to rate
                          how effective the program was in meeting each training goal:
                        </p>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>0</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Not effective at all
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>1</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Minimally effective
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>2</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Somewhat effective
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>3</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Moderately effective
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>4</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Very effective
                          </Col>
                        </Row>
                      </Alert>
                    </Col>
                  </Row>
                  {/* Individual and Cultural Diversity - Q16 */}
                  <Row className="mt-5">
                    <Col className="text-center">
                      <p className="readout">
                        <Label>
                          <b>Q16</b>
                        </Label>{' '}
                        Teaching you how to understand how your own personal/cultural history,
                        attitudes and biases may affect how you understand and interact with people
                        different than yourself: <b className="ml-2">{formData[8].data[0].value}</b>
                      </p>
                    </Col>
                  </Row>
                  {/* // Individual and Cultural Diversity - Q16 */}
                  {/* Individual and Cultural Diversity - Q17 */}
                  <Row className="mt-4">
                    <Col className="text-center">
                      <p className="readout">
                        <Label>
                          <b>Q17</b>
                        </Label>{' '}
                        Teaching you how the current theoretical and empirical knowledge base as it
                        relates to addressing diversity in all professional activities, including
                        research, training, supervision, consultation and service:{' '}
                        <b className="ml-2">{formData[8].data[1].value}</b>
                      </p>
                    </Col>
                  </Row>
                  {/* // Individual and Cultural Diversity - Q17 */}
                  {/* Individual and Cultural Diversity - Q18 */}
                  <Row className="mt-4">
                    <Col className="text-center">
                      <p className="readout">
                        <Label>
                          <b>Q18</b>
                        </Label>{' '}
                        Teaching you how to integrate awareness and knowledge of individual and
                        cultural differences in the conduct of your professional roles (e.g.
                        research, services and other professional activities):{' '}
                        <b className="ml-2">{formData[8].data[2].value}</b>
                      </p>
                    </Col>
                  </Row>
                  {/* // Individual and Cultural Diversity - Q18 */}
                  {/* Individual and Cultural Diversity - Q19 */}
                  <Row className="mt-4">
                    <Col className="text-center">
                      <p className="readout">
                        <Label>
                          <b>Q19</b>
                        </Label>{' '}
                        Teaching you the requisite knowledge base and ability to articulate an
                        approach to working effectively with diverse individuals and groups, and
                        then apply this approach effectively in your professional work:{' '}
                        <b className="ml-2">{formData[8].data[3].value}</b>
                      </p>
                    </Col>
                  </Row>
                  {/* // Individual and Cultural Diversity - Q17 */}
                </Widget>

                {/* Professional Values and Attitudes */}
                <Widget
                  title={
                    <h5>
                      <i className="fa fa-align-left" /> Professional Values and Attitudes
                    </h5>
                  }
                >
                  <Row className="mt-4">
                    <Col lg={2} />
                    <Col lg={8} xs={12}>
                      <Alert color="primary">
                        <p>
                          For the next set of questions, please use the following guidelines to rate
                          how effective the program was in meeting each training goal:
                        </p>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>0</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Not effective at all
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>1</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Minimally effective
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>2</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Somewhat effective
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>3</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Moderately effective
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>4</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Very effective
                          </Col>
                        </Row>
                      </Alert>
                    </Col>
                  </Row>
                  {/*  Professional Values and Attitudes - Q20 */}
                  <Row className="mt-5">
                    <Col className="text-center">
                      <p className="readout">
                        <Label>
                          <b>Q20</b>
                        </Label>{' '}
                        Teaching you to behave in ways that reflect the values and attitudes of
                        psychology, including integrity, deportment, professional identity,
                        accountability, lifelong learning and concern for the welfare of others:{' '}
                        <b className="ml-2">{formData[9].data[0].value}</b>
                      </p>
                    </Col>
                  </Row>
                  {/*  // Professional Values and Attitudes - Q20 */}
                  {/*  Professional Values and Attitudes - Q21 */}
                  <Row className="mt-4">
                    <Col className="text-center">
                      <p className="readout">
                        <Label>
                          <b>Q21</b>
                        </Label>{' '}
                        Teaching you to engage in self-reflection regarding your personal and
                        professional functioning:{' '}
                        <b className="ml-2">{formData[9].data[1].value}</b>
                      </p>
                    </Col>
                  </Row>
                  {/*  // Professional Values and Attitudes - Q21 */}
                  {/*  Professional Values and Attitudes - Q22 */}
                  <Row className="mt-4">
                    <Col className="text-center">
                      <p className="readout">
                        <Label>
                          <b>Q22</b>
                        </Label>{' '}
                        Teaching you to engage in activities to maintain and improve performance,
                        well-being and professional effectiveness:{' '}
                        <b className="ml-2">{formData[9].data[2].value}</b>
                      </p>
                    </Col>
                  </Row>
                  {/*  // Professional Values and Attitudes - Q22 */}
                  {/*  Professional Values and Attitudes - Q23 */}
                  <Row className="mt-4">
                    <Col className="text-center">
                      <p className="readout">
                        <Label>
                          <b>Q23</b>
                        </Label>{' '}
                        Teaching you to actively seek and demonstrate openness and responsiveness to
                        feedback and supervision:{' '}
                        <b className="ml-2">{formData[9].data[3].value}</b>
                      </p>
                    </Col>
                  </Row>
                  {/*  Professional Values and Attitudes - Q23 */}
                  {/*  Professional Values and Attitudes - Q24 */}
                  <Row className="mt-4">
                    <Col className="text-center">
                      <p className="readout">
                        <Label>
                          <b>Q24</b>
                        </Label>{' '}
                        Teaching you to respond professionally to increasingly complex situations
                        with a greater degree of independence as you progressed across levels of
                        training: <b className="ml-2">{formData[9].data[4].value}</b>
                      </p>
                    </Col>
                  </Row>
                  {/*  // Professional Values and Attitudes - Q24 */}
                </Widget>

                {/* Communication and Interpersonal Skills */}
                <Widget
                  title={
                    <h5>
                      <i className="fa fa-align-left" /> Communication and Interpersonal Skills
                    </h5>
                  }
                >
                  <Row className="mt-4">
                    <Col lg={2} />
                    <Col lg={8} xs={12}>
                      <Alert color="primary">
                        <p>
                          For the next set of questions, please use the following guidelines to rate
                          how effective the program was in meeting each training goal:
                        </p>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>0</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Not effective at all
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>1</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Minimally effective
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>2</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Somewhat effective
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>3</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Moderately effective
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>4</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Very effective
                          </Col>
                        </Row>
                      </Alert>
                    </Col>
                  </Row>
                  {/* Communication and Interpersonal Skills - Q25 */}
                  <Row className="mt-5">
                    <Col className="text-center">
                      <p className="readout">
                        <Label>
                          <b>Q25</b>
                        </Label>{' '}
                        Teaching you how to develop and maintain effective relationships with a wide
                        range of individuals, including colleagues, communities, organizations,
                        supervisors, supervisees and those receiving professional services:{' '}
                        <b className="ml-2">{formData[10].data[0].value}</b>
                      </p>
                    </Col>
                  </Row>
                  {/* // Communication and Interpersonal Skills - Q25 */}
                  {/*  Communication and Interpersonal Skills - Q26 */}
                  <Row className="mt-4">
                    <Col className="text-center">
                      <p className="readout">
                        <Label>
                          <b>Q26</b>
                        </Label>{' '}
                        Teaching you to produce and comprehend oral, nonverbal and written
                        communications that are informative and well-integrated:{' '}
                        <b className="ml-2">{formData[10].data[1].value}</b>
                      </p>
                    </Col>
                  </Row>
                  {/*  // Communication and Interpersonal Skills - Q26 */}
                  {/* Communication and Interpersonal Skills - Q27 */}
                  <Row className="mt-4">
                    <Col className="text-center">
                      <p className="readout">
                        <Label>
                          <b>Q27</b>
                        </Label>{' '}
                        Teaching you to demonstrate a thorough grasp of professional language and
                        concepts: <b className="ml-2">{formData[10].data[2].value}</b>
                      </p>
                    </Col>
                  </Row>
                  {/* // Communication and Interpersonal Skills - Q27 */}
                  {/* Communication and Interpersonal Skills - Q28 */}
                  <Row className="mt-4">
                    <Col className="text-center">
                      <p className="readout">
                        <Label>
                          <b>Q28</b>
                        </Label>{' '}
                        Teaching you to demonstrate effective interpersonal skills and the ability
                        to manage difficult communication well:{' '}
                        <b className="ml-2">{formData[10].data[3].value}</b>
                      </p>
                    </Col>
                  </Row>
                  {/* // Communication and Interpersonal Skills - Q26 */}
                </Widget>

                {/* Assessment */}
                <Widget
                  title={
                    <h5>
                      <i className="fa fa-align-left" /> Assessment
                    </h5>
                  }
                >
                  <Row className="mt-4">
                    <Col lg={2} />
                    <Col lg={8} xs={12}>
                      <Alert color="primary">
                        <p>
                          For the next set of questions, please use the following guidelines to rate
                          how effective the program was in meeting each training goal:
                        </p>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>0</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Not effective at all
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>1</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Minimally effective
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>2</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Somewhat effective
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>3</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Moderately effective
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>4</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Very effective
                          </Col>
                        </Row>
                      </Alert>
                    </Col>
                  </Row>
                  {/* Assessment - Q29 */}
                  <Row className="mt-4">
                    <Col className="text-center">
                      <p className="readout">
                        <Label>
                          <b>Q29</b>
                        </Label>{' '}
                        Teaching you to select and apply assessment methods that draw from the best
                        available empirical literature and that reflect the science of measurement
                        and psychometrics: <b className="ml-2">{formData[11].data[0].value}</b>
                      </p>
                    </Col>
                  </Row>
                  {/* // Assessment - Q29 */}
                  {/*  Assessment - Q30 */}
                  <Row className="mt-4">
                    <Col className="text-center">
                      <p className="readout">
                        <Label>
                          <b>Q30</b>
                        </Label>{' '}
                        Teaching you to collect relevant assessment data using multiple sources and
                        methods appropriate to the identified goals and questions of the assessment,
                        as well as relevant diversity characteristics of the service recipient:{' '}
                        <b className="ml-2">{formData[11].data[1].value}</b>
                      </p>
                    </Col>
                  </Row>
                  {/*  // Assessment - Q30 */}
                  {/* Assessment - Q31 */}
                  <Row className="mt-4">
                    <Col className="text-center">
                      <p className="readout">
                        <Label>
                          <b>Q31</b>
                        </Label>{' '}
                        Teaching you to interpret assessment results, following current research and
                        professional standards and guidelines, to inform case conceptualization,
                        classification and recommendations, while guarding against decision-making
                        biases, distinguishing the aspects of assessment that are subjective from
                        those that are objective:{' '}
                        <b className="ml-2">{formData[11].data[2].value}</b>
                      </p>
                    </Col>
                  </Row>
                  {/* // Assessment - Q31 */}
                  {/* Assessment - Q32 */}
                  <Row className="mt-4">
                    <Col className="text-center">
                      <p className="readout">
                        <Label>
                          <b>Q32</b>
                        </Label>{' '}
                        Teaching you to communicate orally and in written documents the findings and
                        implications of the assessment in an accurate and effective manner sensitive
                        to a range of audiences:{' '}
                        <b className="ml-2">{formData[11].data[3].value}</b>
                      </p>
                    </Col>
                  </Row>
                  {/* // Assessment - Q32 */}
                </Widget>

                {/* Intervention */}
                <Widget
                  title={
                    <h5>
                      <i className="fa fa-align-left" /> Intervention
                    </h5>
                  }
                >
                  <Row className="mt-4">
                    <Col lg={2} />
                    <Col lg={8} xs={12}>
                      <Alert color="primary">
                        <p>
                          For the next set of questions, please use the following guidelines to rate
                          how effective the program was in meeting each training goal:
                        </p>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>0</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Not effective at all
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>1</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Minimally effective
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>2</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Somewhat effective
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>3</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Moderately effective
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>4</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Very effective
                          </Col>
                        </Row>
                      </Alert>
                    </Col>
                  </Row>
                  {/* Intervention - Q33 */}
                  <Row className="mt-5">
                    <Col className="text-center">
                      <p className="readout">
                        <Label>
                          <b>Q33</b>
                        </Label>{' '}
                        Teaching you to establish and maintain effective relationships with the
                        recipients of psychological services:{' '}
                        <b className="ml-2">{formData[12].data[0].value}</b>
                      </p>
                    </Col>
                  </Row>
                  {/* // Intervention - Q33 */}
                  {/* Intervention - Q34 */}
                  <Row className="mt-4">
                    <Col className="text-center">
                      <p className="readout">
                        <Label>
                          <b>Q34</b>
                        </Label>{' '}
                        Teaching you to develop evidence-based Intervention plans specific to the
                        service delivery goals: <b className="ml-2">{formData[12].data[1].value}</b>
                      </p>
                    </Col>
                  </Row>
                  {/* // Intervention - Q34 */}
                  {/* Intervention - Q35 */}
                  <Row className="mt-4">
                    <Col className="text-center">
                      <p className="readout">
                        <Label>
                          <b>Q35</b>
                        </Label>{' '}
                        Teaching you to implement interventions informed by the current scientific
                        literature, assessment findings, diversity characteristics and contextual
                        variables: <b className="ml-2">{formData[12].data[2].value}</b>
                      </p>
                    </Col>
                  </Row>
                  {/* // Intervention - Q35 */}
                  {/* Intervention - Q36 */}
                  <Row className="mt-4">
                    <Col className="text-center">
                      <p className="readout">
                        <Label>
                          <b>Q36</b>
                        </Label>{' '}
                        Teaching you to demonstrate the ability to apply the relevant research
                        literature to clinical decision making:{' '}
                        <b className="ml-2">{formData[12].data[3].value}</b>
                      </p>
                    </Col>
                  </Row>
                  {/* // Intervention - Q36 */}
                  {/* Intervention - Q37 */}
                  <Row className="mt-4">
                    <Col className="text-center">
                      <p className="readout">
                        <Label>
                          <b>Q37</b>
                        </Label>{' '}
                        Teaching you to modify and adapt evidence-based approaches effectively when
                        a clear evidence-base is lacking:{' '}
                        <b className="ml-2">{formData[12].data[4].value}</b>
                      </p>
                    </Col>
                  </Row>
                  {/* // Intervention - Q37 */}
                </Widget>

                {/* Supervision, Consultation and Interpersonal/Interdisciplinary Skills */}
                <Widget
                  title={
                    <h5>
                      <i className="fa fa-align-left" /> Supervision, Consultation and
                      Interpersonal/Interdisciplinary Skills
                    </h5>
                  }
                >
                  <Row className="mt-4">
                    <Col lg={2} />
                    <Col lg={8} xs={12}>
                      <Alert color="primary">
                        <p>
                          For the next set of questions, please use the following guidelines to rate
                          how effective the program was in meeting each training goal:
                        </p>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>0</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Not effective at all
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>1</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Minimally effective
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>2</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Somewhat effective
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>3</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Moderately effective
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={1} xs={2} className="text-center">
                            <strong>4</strong>
                          </Col>
                          <Col sm={11} xs={10}>
                            Very effective
                          </Col>
                        </Row>
                      </Alert>
                    </Col>
                  </Row>
                  {/* Supervision - Q38 */}
                  <Row className="mt-5">
                    <Col className="text-center">
                      <p className="readout">
                        <Label>
                          <b>Q38</b>
                        </Label>{' '}
                        Teaching knowledge of supervision models and practices:{' '}
                        <b className="ml-2">{formData[13].data[0].value}</b>
                      </p>
                    </Col>
                  </Row>
                  {/* // Supervision - Q38 */}
                  {/* Supervision - Q39 */}
                  <Row className="mt-4">
                    <Col className="text-center">
                      <p className="readout">
                        <Label>
                          <b>Q39</b>
                        </Label>{' '}
                        Teaching knowledge and respect for the roles and perspectives of other
                        professions: <b className="ml-2">{formData[13].data[1].value}</b>
                      </p>
                    </Col>
                  </Row>
                  {/* // Supervision - Q39 */}
                  {/* Supervision - Q40 */}
                  <Row className="mt-4">
                    <Col className="text-center">
                      <p className="readout">
                        <Label>
                          <b>Q40</b>
                        </Label>{' '}
                        Teaching knowledge of consultation models and practices:{' '}
                        <b className="ml-2">{formData[13].data[2].value}</b>
                      </p>
                    </Col>
                  </Row>
                  {/* // Supervision - Q40 */}
                </Widget>
              </div>
            )}
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    const { loading, error, currentSection, studentID, studentName } = this.state;

    if (!loading && !error) {
      return (
        <div>
          <Row className="mt-4">
            <Col xs={{ size: 10, offset: 1 }}>
              <Row>
                <Col xs="12">
                  <div className="crumbs">
                    <Breadcrumb>
                      <BreadcrumbItem>
                        <Link to="/dashboard">Dashboard</Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem>
                        <Link to={`/student/${studentID}`}>{studentName}</Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem active>Alumni Survey</BreadcrumbItem>
                    </Breadcrumb>
                  </div>
                </Col>
              </Row>
              <h2 className="page-title">
                Alumni Survey: <small>{studentName}</small>
              </h2>
              {currentSection === 999 && (
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="progress-title">
                        <strong>Progress</strong>
                      </div>
                      <Progress color="info" value={100} />
                    </div>
                  </Col>
                </Row>
              )}
              {currentSection === 999 && this.renderReadout()}
            </Col>
          </Row>
        </div>
      );
    }
    if (loading) {
      return (
        <div>
          <Row className="mt-4">
            <Col xs={{ size: 10, offset: 1 }}>
              <h2 className="page-title">Student Progress Report</h2>
            </Col>
          </Row>
          <Row className="text-center">
            <Col>
              <img src={loadingSpinner} alt="loading spinner" className="img-responsive" />
            </Col>
          </Row>
        </div>
      );
    }
    if (error) {
      return (
        <div>
          <Row className="mt-4">
            <Col xs={{ size: 10, offset: 1 }}>
              <Widget>
                <Row className="mt-4 mb-4 text-center">
                  <Col>
                    <h4>
                      There was an error loading the form, please reload the page and try again.
                    </h4>
                  </Col>
                </Row>
              </Widget>
            </Col>
          </Row>
        </div>
      );
    }
  }
}

asFacultyReadout.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withRouter(asFacultyReadout);
