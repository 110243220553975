/* eslint-disable react/function-component-definition */
import React from 'react';
import { Row, Col } from 'reactstrap';
import AddStudent from '../AddStudent/AddStudent';
import StudentList from '../StudentList/StudentList';
import StudentSearch from '../StudentSearch/StudentSearch';

const StudentAdmin = () => {
  return (
    <div>
      <Row>
        <Col>
          <StudentSearch />
          <AddStudent />
          <StudentList />
        </Col>
      </Row>
    </div>
  );
};

export default StudentAdmin;
