/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-bitwise */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import {
  // Alert,
  // Breadcrumb,
  // BreadcrumbItem,
  Table,
  Container,
  Row,
  Col,
  Collapse,
  CardBody,
  Card,
  // Form,
  // FormGroup,
  // Label,
  // Input,
  // ButtonGroup,
  // Button,
  // InputGroup,
  // InputGroupButton,
  // UncontrolledButtonDropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
  Badge,
} from 'reactstrap';
import axios from 'axios';

import Widget from '../Widget/Widget';
// import Accordion from '../Accordian/Accordion';
// import Comment from '../comment';

import '../Forms/forms.scss';

// import iUser from '../../images/user.png';
import loadingSpinner from '../../images/Eclipse-1s-200px.svg';

// import { StudentData } from '../account/StudentData';

class FacultyProfile extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);

    this.state = {
      collapse: true,
      profile: {},
      loading: true,
      students: [],
    };
  }

  componentDidMount() {
    const { userProfile, getProfile } = this.props.auth;
    if (!userProfile) {
      getProfile((err, profile) => {
        this.setState({ profile });
        this.getStudents(profile);
      });
    } else {
      this.setState({ profile: userProfile });
      this.getStudents(userProfile);
    }
  }

  getStudents(profile) {
    const { getAccessToken } = this.props.auth;
    const config = {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    };

    if (profile.email === 'gracelee@llu.edu') {
      const url = `${process.env.REACT_APP_API_URL}/student`;
      axios
        .get(url, config)
        .then((response) => {
          const students = response.data;
          // console.log(students);
          this.setState({ loading: false, students });
        })
        .catch((error) => {
          console.error(error);
          // this.setState({ currentView: 'maintenance' });
        });
    } else {
      const url = `${process.env.REACT_APP_API_URL}/student/search`;
      axios
        .post(
          url,
          {
            searchType: 'advisor',
            searchValue: profile.email,
          },
          config,
        )
        .then((response) => {
          const students = response.data;
          // console.log(students);
          this.setState({ loading: false, students });
        })
        .catch((error) => {
          console.error(error);
          // this.setState({ currentView: 'maintenance' });
        });
    }
  }

  toggle() {
    this.setState((state) => ({ collapse: !state.collapse }));
  }

  generateUUID() {
    // Public Domain/MIT
    let d = new Date().getTime();

    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
      d += performance.now(); // use high-precision timer if available
    }

    // eslint-disable-next-line func-names
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
  }

  renderStudents(year, program) {
    // const yearStr = this.state.displayYearSelect;
    // const lastChar = yearStr[yearStr.length - 1];
    // const yearInt = parseInt(lastChar, 10);
    const { students } = this.state;

    const filteredStudents = students.filter((val) => {
      return val.year === year && val.currentProgram === program;
    });

    if (filteredStudents.length > 0) {
      return (
        <div>
          <ul className="newsList newsListNoHover">
            {filteredStudents.map((student) => (
              <li key={this.generateUUID()}>
                {/* <img src={iUser} alt="" className="float-left rounded-circle" /> */}
                <div className="newsItemInfo">
                  <div className="name pb-3" style={{ fontSize: '1.2rem' }}>
                    <Link to={`/form/${student.forms[0]._id}`}>
                      {student.firstName} {student.lastName}
                    </Link>
                    &nbsp;
                    {student.forms[0].status === 'new' && (
                      <Badge className="ml-2" color="success">
                        New
                      </Badge>
                    )}
                    {student.forms[0].status === 'inProgress' && (
                      <Badge className="ml-2" color="warning">
                        In Progress
                      </Badge>
                    )}
                    {student.forms[0].status === 'completed' && (
                      <Badge className="ml-2" color="info">
                        Completed
                      </Badge>
                    )}
                  </div>
                  {/* <div className="position">{student.professionalConcentration}</div> */}
                </div>
              </li>
            ))}
          </ul>
        </div>
      );
    }
    return (
      <div>
        <p>No Students in this Year</p>
      </div>
    );
  }

  renderLoading() {
    return (
      <Container>
        <Row>
          <Col lg={{ size: 10, offset: 1 }} xs={12}>
            <h2 className="page-title mt-2">Faculty Profile</h2>
          </Col>
        </Row>
        <Row className="text-center">
          <Col>
            <img src={loadingSpinner} alt="loading spinner" className="img-responsive" />
          </Col>
        </Row>
      </Container>
    );
  }

  render() {
    const { profile, loading } = this.state;
    // console.log(profile);

    if (!loading) {
      return (
        <Container>
          {/* <Row>
            <Col lg={{ size: 10, offset: 1 }} xs={12}>
              <div className="crumbs">
                <Breadcrumb>
                  <BreadcrumbItem>
                    <a href="/">Dashboard</a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="/">User Admin</a>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>Faculty Profile</BreadcrumbItem>
                </Breadcrumb>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={{ size: 8, offset: 2 }}>
              <Alert color="inverse">
                Thanks so much for filling out the <strong>Student Satisfaction Survey</strong>.
              </Alert>
            </Col>
          </Row>
          <Row>
            <Col lg={{ size: 10, offset: 1 }} xs={12}>
              <h2 className="page-title mt-2">Faculty Profile</h2>
            </Col>
          </Row> */}
          <Row>
            <Col xs={{ size: 10, offset: 1 }}>
              <Widget>
                <Row>
                  <Col md={4}>
                    <div className="text-center">
                      <img
                        className="rounded-circle"
                        style={{ height: `${112} + px` }}
                        // src={iUser}
                        src={profile.picture}
                        alt="64x64"
                      />
                    </div>
                  </Col>
                  <Col md={8}>
                    {/* <h3 className="mt-md mb-xs">Grace Lee, PhD, MA</h3> */}
                    <h3 className="mt-md mb-xs">{profile.name}</h3>
                    <Table hover>
                      <tbody>
                        <tr>
                          <th scope="row">EMAIL:</th>
                          <td />
                          {/* <td>gracelee@llu.edu</td> */}
                          <td>{profile.email}</td>
                        </tr>
                        {/* <tr>
                          <th scope="row">PROFESSIONAL CONCENTRATION:</th>
                          <td />
                          <td>Optional (Effective Fall 2014)</td>
                        </tr> */}
                        <tr>
                          <th scope="row">Student Profile:</th>
                          <td />
                          <td>
                            <Link to="/account">Account</Link>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Widget>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <p>
                <br />
              </p>
              {/* <Comment /> */}
            </Col>
          </Row>
          <Row>
            <Col xs={{ size: 10, offset: 1 }}>
              {/* <Button
                color="inverse"
                size="lg"
                onClick={this.toggle}
                style={{ marginBottom: '1rem' }}
                block
              >
                Students
              </Button> */}
              <Collapse isOpen={this.state.collapse}>
                <Card>
                  <CardBody>
                    <h4 className="pl-4 pb-2" style={{ borderBottom: '1px solid black' }}>
                      Year 1
                    </h4>
                    <Row className="mt-4">
                      <Col md={6}>
                        <h5>Ph.D.</h5>
                        {this.renderStudents(1, 'Ph.D.')}
                      </Col>
                      <Col md={6}>
                        <h5>Psy.D.</h5>
                        {this.renderStudents(1, 'Psy.D.')}
                      </Col>
                    </Row>
                    <h4 className="pl-4 pb-2" style={{ borderBottom: '1px solid black' }}>
                      Year 2
                    </h4>
                    <Row className="mt-4">
                      <Col md={6}>
                        <h5>Ph.D.</h5>
                        {this.renderStudents(2, 'Ph.D.')}
                      </Col>
                      <Col md={6}>
                        <h5>Psy.D.</h5>
                        {this.renderStudents(2, 'Psy.D.')}
                      </Col>
                    </Row>
                    <h4 className="pl-4 pb-2" style={{ borderBottom: '1px solid black' }}>
                      Year 3
                    </h4>
                    <Row className="mt-4">
                      <Col md={6}>
                        <h5>Ph.D.</h5>
                        {this.renderStudents(3, 'Ph.D.')}
                      </Col>
                      <Col md={6}>
                        <h5>Psy.D.</h5>
                        {this.renderStudents(3, 'Psy.D.')}
                      </Col>
                    </Row>
                    <h4 className="pl-4 pb-2" style={{ borderBottom: '1px solid black' }}>
                      Year 4
                    </h4>
                    <Row className="mt-4">
                      <Col md={6}>
                        <h5>Ph.D.</h5>
                        {this.renderStudents(4, 'Ph.D.')}
                      </Col>
                      <Col md={6}>
                        <h5>Psy.D.</h5>
                        {this.renderStudents(4, 'Psy.D.')}
                      </Col>
                    </Row>
                    <h4 className="pl-4 pb-2" style={{ borderBottom: '1px solid black' }}>
                      Year 5
                    </h4>
                    <Row className="mt-4">
                      <Col md={6}>
                        <h5>Ph.D.</h5>
                        {this.renderStudents(5, 'Ph.D.')}
                      </Col>
                      <Col md={6}>
                        <h5>Psy.D.</h5>
                        {this.renderStudents(5, 'Psy.D.')}
                      </Col>
                    </Row>
                    <h4 className="pl-4 pb-2" style={{ borderBottom: '1px solid black' }}>
                      Year 6
                    </h4>
                    <Row className="mt-4">
                      <Col md={6}>
                        <h5>Ph.D.</h5>
                        {this.renderStudents(6, 'Ph.D.')}
                      </Col>
                      <Col md={6}>
                        <h5>Psy.D.</h5>
                        {this.renderStudents(6, 'Psy.D.')}
                      </Col>
                    </Row>
                    <h4 className="pl-4 pb-2" style={{ borderBottom: '1px solid black' }}>
                      Year 7
                    </h4>
                    <Row className="mt-4">
                      <Col md={6}>
                        <h5>Ph.D.</h5>
                        {this.renderStudents(7, 'Ph.D.')}
                      </Col>
                      <Col md={6}>
                        <h5>Psy.D.</h5>
                        {this.renderStudents(7, 'Psy.D.')}
                      </Col>
                    </Row>
                    <h4 className="pl-4 pb-2" style={{ borderBottom: '1px solid black' }}>
                      Year 8
                    </h4>
                    <Row className="mt-4">
                      <Col md={6}>
                        <h5>Ph.D.</h5>
                        {this.renderStudents(8, 'Ph.D.')}
                      </Col>
                      <Col md={6}>
                        <h5>Psy.D.</h5>
                        {this.renderStudents(8, 'Psy.D.')}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Collapse>
            </Col>
          </Row>
          {/* <Row className="mt-4">
            <Col lg={{ size: 10, offset: 1 }} xs={12}>
              <Button color="inverse" size="lg" className="back">
                <i className="fa fa-angle-left" /> Back
              </Button>
            </Col>
          </Row>
          <Row>
            <Col lg={12} xs={12}>
              <div className="crumbs">
                <Breadcrumb>
                  <BreadcrumbItem>
                    <a href="/">Dashboard</a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="/">User Admin</a>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>Faculty Profile</BreadcrumbItem>
                </Breadcrumb>
              </div>
              <p>
                <br />
              </p>
            </Col>
          </Row> */}
        </Container>
      );
    }
    return <div>{this.renderLoading()}</div>;
  }
}

export default FacultyProfile;
