import React from 'react';
import { Row, Col, Button, Form, FormGroup, Input, Label } from 'reactstrap';
import axios from 'axios';
import auth0Client from '../../Auth/Auth';

import Widget from '../Widget/Widget';

import loadingSpinner from '../../images/Eclipse-1s-200px.svg';

class AddClinicalSupervisor extends React.Component {
  constructor(props) {
    super(props);
    this.addClinicalSupervisor = this.addClinicalSupervisor.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onAddNewClinicalSupervisorBtnClick = this.onAddNewClinicalSupervisorBtnClick.bind(this);
    this.onNewUserSubmitBtnClick = this.onNewUserSubmitBtnClick.bind(this);
    this.onNewUserCancelBtnClick = this.onNewUserCancelBtnClick.bind(this);
    this.onAddAnotherUserBtnClick = this.onAddAnotherUserBtnClick.bind(this);
    this.renderClinicalSupervisorForm = this.renderClinicalSupervisorForm.bind(this);
    this.renderUserAddedConfirmation = this.renderUserAddedConfirmation.bind(this);

    this.state = {
      addNewClinicalSupervisor: false,
      firstName: '',
      lastName: '',
      title: '',
      phoneNumber: '',
      emailAddress: '',
      showConfirmation: false,
      loading: false,
      errorAddingClinicalSupervisor: false,
    };
  }

  handleInputChange(e) {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  onAddNewClinicalSupervisorBtnClick() {
    const { addNewClinicalSupervisor } = this.state;
    this.setState({
      addNewClinicalSupervisor: !addNewClinicalSupervisor,
    });
  }

  onNewUserSubmitBtnClick() {
    this.setState({
      loading: true,
    });
    this.addClinicalSupervisor();
  }

  onNewUserCancelBtnClick() {
    this.setState({
      addNewClinicalSupervisor: false,
      firstName: '',
      lastName: '',
      title: '',
      phoneNumber: '',
      emailAddress: '',
      showConfirmation: false,
      errorAddingClinicalSupervisor: false,
    });
  }

  onAddAnotherUserBtnClick() {
    this.setState({
      addNewClinicalSupervisor: true,
      firstName: '',
      lastName: '',
      title: '',
      phoneNumber: '',
      emailAddress: '',
      showConfirmation: false,
      errorAddingClinicalSupervisor: false,
    });
  }

  addClinicalSupervisor() {
    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    const url = `${process.env.REACT_APP_API_URL}/clinical-supervisor`;

    const { firstName, lastName, title, emailAddress, phoneNumber } = this.state;

    const email = [
      {
        emailAddress,
        default: true,
      },
    ];

    const phone = [
      {
        phoneNumber,
        default: true,
      },
    ];

    axios
      .post(url, { firstName, lastName, title, email, phone }, config)
      // eslint-disable-next-line no-unused-vars
      .then((response) => {
        this.setState({ loading: false, showConfirmation: true });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ loading: false, errorAddingClinicalSupervisor: true });
      });
  }

  renderClinicalSupervisorForm() {
    const { firstName, lastName, title, emailAddress, phoneNumber, errorAddingClinicalSupervisor } =
      this.state;
    return (
      <div>
        <Form>
          {/* Add Clinical Supervisor - Q1 */}
          <Row>
            <Col md={{ size: 3, offset: 1 }} className="text-md-right">
              <Label for="firstName">First name</Label>
            </Col>
            <Col sm={4}>
              <Input
                type="text"
                id="firstName"
                name="firstName"
                value={firstName}
                onChange={this.handleInputChange}
              />
            </Col>
          </Row>
          {/* // Add Clinical Supervisor - Q1 */}
          {/* Add Clinical Supervisor - Q2 */}
          <Row className="mt-3">
            <Col md={{ size: 3, offset: 1 }} className="text-md-right">
              <Label for="lastName">Last name</Label>
            </Col>
            <Col sm={4}>
              <Input
                type="text"
                id="lastName"
                name="lastName"
                value={lastName}
                onChange={this.handleInputChange}
              />
            </Col>
          </Row>
          {/* // Add Clinical Supervisor - Q2 */}
          {/* Add Clinical Supervisor - Q3 */}
          <Row className="mt-3">
            <Col md={{ size: 3, offset: 1 }} className="text-md-right">
              <Label for="title">Title</Label>
            </Col>
            <Col sm={4}>
              <Input
                type="text"
                id="title"
                name="title"
                value={title}
                onChange={this.handleInputChange}
              />
            </Col>
          </Row>
          {/* // Add Clinical Supervisor - Q3 */}
          {/* Add Clinical Supervisor - Q4 */}
          <Row className="mt-3">
            <Col md={{ size: 3, offset: 1 }} className="text-md-right">
              <Label for="emailAddress">Email</Label>
            </Col>
            <Col sm={4}>
              <Input
                type="text"
                id="emailAddress"
                name="emailAddress"
                value={emailAddress}
                onChange={this.handleInputChange}
              />
            </Col>
          </Row>
          {/* // Add Clinical Supervisor - Q4 */}
          {/* Add Clinical Supervisor - Q5 */}
          <Row className="mt-3">
            <Col md={{ size: 3, offset: 1 }} className="text-md-right">
              <Label for="phoneNumber">Phone Number</Label>
            </Col>
            <Col sm={4}>
              <Input
                type="text"
                id="phoneNumber"
                name="phoneNumber"
                value={phoneNumber}
                onChange={this.handleInputChange}
              />
            </Col>
          </Row>
          {/* // Add Clinical Supervisor - Q5 */}
          <FormGroup row className="submitBlock mt-4">
            <Col md={12} className="text-center">
              <Button color="info" onClick={this.onNewUserSubmitBtnClick} className="ml-xs">
                Add User
              </Button>
              <Button color="inverse" onClick={this.onNewUserCancelBtnClick} className="ml-xs">
                Cancel
              </Button>
            </Col>
          </FormGroup>
        </Form>
        {errorAddingClinicalSupervisor && (
          <Row className="mt-4 text-center">
            <Col>
              <p className="text-danger">
                There was an error adding the Clinical Supervisor. Please verify the information is
                correct and try again.
              </p>
            </Col>
          </Row>
        )}
      </div>
    );
  }

  renderUserAddedConfirmation() {
    return (
      <div>
        <Row>
          <Col className="text-center">
            <p>
              <h3>Clinical Supervisor Successfully Added!</h3>
            </p>
            <Form>
              <FormGroup row className="submitBlock">
                <Col md={12} className="text-center">
                  <Button color="info" onClick={this.onAddAnotherUserBtnClick} className="ml-xs">
                    Click Here to Add Another Clinical Supervisor
                  </Button>
                </Col>
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    const { loading, showConfirmation, addNewClinicalSupervisor } = this.state;
    return (
      <div>
        <Row>
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-plus" /> Add New Clinical Supervisor
                </h5>
              }
            >
              {loading && (
                <div>
                  <Row className="text-center">
                    <Col>
                      <img src={loadingSpinner} alt="loading spinner" className="img-responsive" />
                    </Col>
                  </Row>
                </div>
              )}
              {(() => {
                if (!loading && !showConfirmation) {
                  return (
                    <div>
                      <Row className="mt-4">
                        <Col>
                          <Button
                            color={addNewClinicalSupervisor ? 'dark' : 'inverse'}
                            onClick={this.onAddNewClinicalSupervisorBtnClick}
                            active={addNewClinicalSupervisor}
                          >
                            Add Clinical Supervisor Form
                          </Button>
                        </Col>
                      </Row>
                      {addNewClinicalSupervisor && (
                        <Row>
                          <Col>{this.renderClinicalSupervisorForm()}</Col>
                        </Row>
                      )}
                    </div>
                  );
                }
                if (!loading && showConfirmation) {
                  return this.renderUserAddedConfirmation();
                }
                return null;
              })()}
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }
}

export default AddClinicalSupervisor;
