/* eslint-disable react/function-component-definition */
import React from 'react';
import { Row, Col } from 'reactstrap';
import AddFacility from '../AddFacility/AddFacility';
import FacilityList from '../FacilityList/FacilityList';
import FacilitySearch from '../FacilitySearch/FacilitySearch';

const FacilityAdmin = () => {
  return (
    <div>
      <Row>
        {/* Selection */}
        <Col xl={{ size: 10, offset: 1 }}>
          <FacilitySearch />
          <AddFacility />
          <FacilityList />
        </Col>
      </Row>
    </div>
  );
};

export default FacilityAdmin;
