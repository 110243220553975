/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["student"] }] */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-plusplus */
import React from 'react';
import { Link } from 'react-router-dom';
import { Input, Row, Col, Button } from 'reactstrap';
import axios from 'axios';
import auth0Client from '../../Auth/Auth';

import Widget from '../Widget/Widget';

import loadingSpinner from '../../images/Eclipse-1s-200px.svg';
import iUser from '../../images/user.png';

class StudentList extends React.Component {
  constructor(props) {
    super(props);
    this.onStatusBtnClick = this.onStatusBtnClick.bind(this);
    this.getStudents = this.getStudents.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.renderStudents = this.renderStudents.bind(this);

    this.state = {
      students: [],
      studentYears: [],
      displayStatus: 'current',
      displayYearSelect: '0',
      loading: true,
    };
  }

  componentDidMount() {
    this.getStudents();
  }

  handleInputChange(e) {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  handleRefresh(e) {
    e.target.blur();
    this.setState({ loading: true });
    this.getStudents();
  }

  onStatusBtnClick(sectionValue) {
    this.setState({
      displayStatus: sectionValue,
    });
  }

  getStudents() {
    // const { displayYearSelect } = this.state;
    // const profile = auth0Client.getProfile();
    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    // const namespace = 'https://api.llusbh.com/mongo_id';

    const url = `${process.env.REACT_APP_API_URL}/student`;
    axios
      .get(url, config)
      .then((response) => {
        const students = response.data;
        // console.log(students);

        // set studentYears
        let i;
        const studentYears = [];
        for (i = 0; i < students.length; i++) {
          if (studentYears.indexOf(students[i].year) === -1) {
            studentYears.push(students[i].year);
          }
        }

        // sort studentYears
        studentYears.sort((a, b) => a - b);

        this.setState({ loading: false, students, studentYears });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }

  renderStudents(program) {
    const { students, displayStatus, displayYearSelect } = this.state;
    let filteredStudents;

    if (displayStatus === 'current') {
      // const yearArr = displayYearSelect.split(' ');
      // const yearInt = parseInt(yearArr[yearArr.length - 1], 10);
      const yearInt = parseInt(displayYearSelect, 10);

      if (yearInt === 0) {
        filteredStudents = students.filter((val) => {
          return val.currentProgram === program && val.status === displayStatus;
        });
      } else {
        filteredStudents = students.filter((val) => {
          return (
            val.year === yearInt && val.currentProgram === program && val.status === displayStatus
          );
        });
      }
    } else {
      filteredStudents = students.filter((val) => {
        return val.currentProgram === program && val.status === displayStatus;
      });

      if (displayStatus === 'alumni') {
        filteredStudents.forEach((student) => {
          if (
            student.forms.some(
              (form) =>
                form.academicYear === 2024 && form.type === 'AS' && form.status === 'completed',
            )
          ) {
            student.completedAS = true;
          } else {
            student.completedAS = false;
          }
        });
      }
    }

    return (
      <div>
        {filteredStudents.length === 0 && (
          <Row>
            <Col>
              <p className="text-danger">No Students</p>
            </Col>
          </Row>
        )}
        <ul className="newsList newsListNoHover">
          {filteredStudents.map((student) => (
            <li key={student._id}>
              <img src={iUser} alt="" className="rounded-circle float-left" />
              <div className="newsItemInfo">
                <div className="name">
                  <Link to={`/student/${student._id}`}>
                    {student.firstName} {student.lastName}
                    {displayStatus === 'alumni' && student.completedAS === false && (
                      <i className="fa fa-circle text-danger ml-2" />
                    )}
                    {displayStatus === 'alumni' && student.completedAS === true && (
                      <i className="fa fa-circle text-success ml-2" />
                    )}
                  </Link>
                </div>
                <div className="position">{student.professionalConcentration}</div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  render() {
    const { loading, displayStatus, displayYearSelect, studentYears } = this.state;
    return (
      <div>
        <Row>
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-columns" /> Students
                  <Button className="pull-right" color="link" onClick={this.handleRefresh}>
                    <i className="fa fa-repeat pull-right" />
                  </Button>
                </h5>
              }
            >
              <Row className="mt-4">
                <Col xs={12}>
                  <h5>Academic Status</h5>
                </Col>
                <Col>
                  <Button
                    className="mt-2"
                    color={displayStatus === 'current' ? 'dark' : 'inverse'}
                    onClick={() => this.onStatusBtnClick('current')}
                    active={displayStatus === 'current'}
                  >
                    Current
                  </Button>
                  <Button
                    className="mt-2 ml-2"
                    color={displayStatus === 'leaveOfAbsence' ? 'dark' : 'inverse'}
                    onClick={() => this.onStatusBtnClick('leaveOfAbsence')}
                    active={displayStatus === 'leaveOfAbsence'}
                  >
                    Leave of Absence
                  </Button>
                  <Button
                    className="mt-2 ml-2"
                    color={displayStatus === 'leftProgram' ? 'dark' : 'inverse'}
                    onClick={() => this.onStatusBtnClick('leftProgram')}
                    active={displayStatus === 'leftProgram'}
                  >
                    Left Program
                  </Button>
                  <Button
                    className="mt-2 ml-2"
                    color={displayStatus === 'other' ? 'dark' : 'inverse'}
                    onClick={() => this.onStatusBtnClick('other')}
                    active={displayStatus === 'other'}
                  >
                    Other
                  </Button>
                  <Button
                    className="mt-2 ml-2"
                    color={displayStatus === 'alumni' ? 'dark' : 'inverse'}
                    onClick={() => this.onStatusBtnClick('alumni')}
                    active={displayStatus === 'alumni'}
                  >
                    Alumni
                  </Button>
                </Col>
              </Row>
              {loading && (
                <div>
                  <Row className="text-center">
                    <Col>
                      <img src={loadingSpinner} alt="loading spinner" className="img-responsive" />
                    </Col>
                  </Row>
                </div>
              )}
              {!loading && (
                <div>
                  {displayStatus === 'current' && (
                    <Row className="mt-3">
                      <Col>
                        <Input
                          type="select"
                          name="displayYearSelect"
                          id="displayYearSelect"
                          value={displayYearSelect}
                          onChange={this.handleInputChange}
                        >
                          <option value="0">All Students</option>
                          {studentYears.map((year) => (
                            <option key={year.toString()} value={year}>
                              Year {year}
                            </option>
                          ))}
                        </Input>
                      </Col>
                    </Row>
                  )}
                  <div>
                    <Row className="mt-4">
                      <Col md={6}>
                        <h5>Ph.D.</h5>
                        {this.renderStudents('Ph.D.')}
                      </Col>
                      <Col md={6}>
                        <h5>Psy.D.</h5>
                        {this.renderStudents('Psy.D.')}
                      </Col>
                    </Row>
                  </div>
                </div>
              )}
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }
}

export default StudentList;
