/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-underscore-dangle */
/* eslint class-methods-use-this: ["error", { "exceptMethods": ["renderLoading"] }] */

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Table,
  Container,
  Row,
  Col,
  Collapse,
  CardBody,
  Card,
  Button,
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import axios from 'axios';
import auth0Client from '../../Auth/Auth';
import Widget from '../Widget/Widget';
import AddPracticumSite from '../AddPracticumSite/AddPracticumSite';

import '../Forms/forms.scss';

import iUser from '../../images/user.png';
import loadingSpinner from '../../images/Eclipse-1s-200px.svg';
import loadingSpinnerRed from '../../images/DualRing-1s-200px.svg';

const currentAcademicYear = 2024;

class StudentProfile extends React.Component {
  constructor(props) {
    super(props);

    this.onEditAdvisorBtnClick = this.onEditAdvisorBtnClick.bind(this);
    this.onSaveEditAdvisorBtnClick = this.onSaveEditAdvisorBtnClick.bind(this);
    this.onCancelEditAdvisorBtnClick = this.onCancelEditAdvisorBtnClick.bind(this);
    this.onEditProgramBtnClick = this.onEditProgramBtnClick.bind(this);
    this.onConfirmEditProgramBtnClick = this.onConfirmEditProgramBtnClick.bind(this);
    this.onCancelEditProgramBtnClick = this.onCancelEditProgramBtnClick.bind(this);
    this.onEditStatusBtnClick = this.onEditStatusBtnClick.bind(this);
    this.onSaveEditStatusBtnClick = this.onSaveEditStatusBtnClick.bind(this);
    this.onCancelEditStatusBtnClick = this.onCancelEditStatusBtnClick.bind(this);
    this.onEditConcentrationBtnClick = this.onEditConcentrationBtnClick.bind(this);
    this.onSaveEditConcentrationBtnClick = this.onSaveEditConcentrationBtnClick.bind(this);
    this.onCancelEditConcentrationBtnClick = this.onCancelEditConcentrationBtnClick.bind(this);
    this.onEditSpecializationBtnClick = this.onEditSpecializationBtnClick.bind(this);
    this.onSaveEditSpecializationBtnClick = this.onSaveEditSpecializationBtnClick.bind(this);
    this.onCancelEditSpecializationBtnClick = this.onCancelEditSpecializationBtnClick.bind(this);
    this.onEditPermanentEmailBtnClick = this.onEditPermanentEmailBtnClick.bind(this);
    this.onSaveEditPermanentEmailBtnClick = this.onSaveEditPermanentEmailBtnClick.bind(this);
    this.onCancelEditPermanentEmailBtnClick = this.onCancelEditPermanentEmailBtnClick.bind(this);
    this.getStudentInformation = this.getStudentInformation.bind(this);
    this.getPracticumSites = this.getPracticumSites.bind(this);
    this.getFaculty = this.getFaculty.bind(this);
    this.updateAdvisor = this.updateAdvisor.bind(this);
    this.updateProgram = this.updateProgram.bind(this);
    this.updateConcentration = this.updateConcentration.bind(this);
    this.updatePermanentEmail = this.updatePermanentEmail.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.renderAcademicYear = this.renderAcademicYear.bind(this);
    this.renderPracticumSites = this.renderPracticumSites.bind(this);
    this.renderProfile = this.renderProfile.bind(this);
    this.renderLoading = this.renderLoading.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);

    this.state = {
      collapseDoc: false,
      showFacultyList: false,
      advisorSelect: '',
      facultyList: [],
      showConcentrationList: false,
      concentrationSelect: '',
      showSpecializationList: false,
      specializationSelect: '',
      showStatusList: false,
      statusSelect: '',
      permanentEmailInput: '',
      loading: true,
      loadingFacultyList: false,
      loadingEditProgram: false,
      loadingConcentrationList: false,
      loadingSpecializationList: false,
      loadingStatusList: false,
    };
  }

  componentDidMount() {
    this.getStudentInformation();
  }

  handleRefresh(e) {
    e.target.blur();
    // this.setState({ loading: true });
    this.getPracticumSites();
  }

  handleInputChange(e) {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  onEditAdvisorBtnClick() {
    this.setState({
      loadingFacultyList: true,
      errorEditingAdvisor: false,
    });
    this.getFaculty();
  }

  onSaveEditAdvisorBtnClick() {
    this.setState({
      loadingFacultyList: true,
      errorEditingAdvisor: false,
    });
    this.updateAdvisor();
  }

  onCancelEditAdvisorBtnClick() {
    this.setState({
      showFacultyList: false,
      errorEditingAdvisor: false,
    });
  }

  onEditProgramBtnClick() {
    this.setState({
      showProgramModal: true,
      errorEditingProgram: false,
    });
  }

  onConfirmEditProgramBtnClick() {
    this.setState({
      showProgramModal: false,
      loadingEditProgram: true,
    });
    this.updateProgram();
  }

  onCancelEditProgramBtnClick() {
    this.setState({
      showProgramModal: false,
      errorEditingProgram: false,
    });
  }

  onEditConcentrationBtnClick() {
    this.setState({
      showConcentrationList: true,
      errorEditingConcentration: false,
    });
  }

  onSaveEditConcentrationBtnClick() {
    this.setState({
      loadingConcentrationList: true,
      showConcentrationList: false,
      errorEditingConcentration: false,
    });
    this.updateConcentration();
  }

  onCancelEditConcentrationBtnClick() {
    this.setState({
      showConcentrationList: false,
      errorEditingConcentration: false,
    });
  }

  onEditSpecializationBtnClick() {
    this.setState({
      showSpecializationList: true,
      errorEditingSpecialization: false,
    });
  }

  onSaveEditSpecializationBtnClick() {
    this.setState({
      loadingSpecializationList: true,
      showSpecializationList: false,
      errorEditingSpecialization: false,
    });
    this.updateSpecialization();
  }

  onCancelEditSpecializationBtnClick() {
    this.setState({
      showSpecializationList: false,
      errorEditingSpecialization: false,
    });
  }

  onEditStatusBtnClick() {
    this.setState({
      showStatusList: true,
      errorEditingStatus: false,
    });
  }

  onSaveEditStatusBtnClick() {
    this.setState({
      loadingStatusList: true,
      showStatusList: false,
      errorEditingStatus: false,
    });
    this.updateStatus();
  }

  onCancelEditStatusBtnClick() {
    this.setState({
      showStatusList: false,
      errorEditingStatus: false,
    });
  }

  onEditPermanentEmailBtnClick() {
    this.setState({
      editPermanentEmail: true,
      errorEditingPermanentEmail: false,
      errorInvalidPermanentEmail: false,
    });
  }

  onSaveEditPermanentEmailBtnClick() {
    const { permanentEmailInput } = this.state;
    let invalidPermanentEmail = false;

    // check if email is empty
    if (permanentEmailInput.length === 0) {
      // set invalidPermanentEmail to true
      invalidPermanentEmail = true;
    }

    // check if email is valid
    if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        permanentEmailInput,
      )
    ) {
      // set invalidPermanentEmail to true
      invalidPermanentEmail = true;
    }

    if (invalidPermanentEmail) {
      this.setState({
        errorInvalidPermanentEmail: true,
      });
    } else {
      this.setState({
        loadingPermanentEmail: true,
        errorEditingPermanentEmail: false,
        errorInvalidPermanentEmail: false,
      });
      this.updatePermanentEmail();
    }
  }

  onCancelEditPermanentEmailBtnClick() {
    this.setState({
      editPermanentEmail: false,
      errorEditingPermanentEmail: false,
      errorInvalidPermanentEmail: false,
    });
  }

  getStudentInformation() {
    const {
      match: { params },
    } = this.props;

    const url = `${process.env.REACT_APP_API_URL}/student/${params.studentid}`;

    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        const {
          advisor,
          currentProgram,
          email,
          firstName,
          forms,
          idNumber,
          internship,
          lastName,
          professionalConcentration,
          startYear,
          transferredYear,
          year,
          hasPracticumSites,
          status,
          permanentEmail,
        } = response.data;

        let { professionalSpecialization } = response.data;

        // set formYears
        let i;
        const formYears = [];
        for (i = 0; i < forms.length; i += 1) {
          if (formYears.indexOf(forms[i].academicYear) === -1) {
            formYears.push(forms[i].academicYear);
          }
        }

        // add current year to formYears if current student & no forms active for the current year
        if (status === 'current' && formYears.indexOf(currentAcademicYear) === -1) {
          formYears.push(currentAcademicYear);
        }

        // sort formYears
        formYears.sort((a, b) => b - a);

        // set collapse state for current year
        if (formYears.length > 0) {
          const collapseStr = `collapse${formYears[0]}`;
          this.setState({ [collapseStr]: true });
        }

        // check if student has professionalSpecialization
        if (professionalSpecialization === null) {
          // professionalSpecialization is null, set to 'None'
          professionalSpecialization = 'None';
        }

        this.setState({
          advisor: advisor.email,
          currentProgram,
          email,
          firstName,
          forms,
          idNumber,
          internship,
          lastName,
          professionalConcentration,
          concentrationSelect: professionalConcentration,
          professionalSpecialization,
          specializationSelect: professionalSpecialization,
          startYear,
          transferredYear,
          year,
          status,
          statusSelect: status,
          permanentEmail: permanentEmail?.length > 0 ? permanentEmail : 'None',
          formYears,
          // loading: false,
        });
        if (hasPracticumSites) {
          this.getPracticumSites();
        } else {
          this.setState({
            practicumSiteList: [],
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getPracticumSites() {
    const {
      match: { params },
    } = this.props;

    const url = `${process.env.REACT_APP_API_URL}/practicum-site/student/${params.studentid}`;

    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        const practicumSiteList = response.data;
        this.setState({
          practicumSiteList,
          loading: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getFaculty() {
    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    const url = `${process.env.REACT_APP_API_URL}/faculty`;

    axios
      .get(url, config)
      .then((response) => {
        const facultyList = response.data;
        this.setState({
          loadingFacultyList: false,
          showFacultyList: true,
          advisorSelect: facultyList[0]._id,
          facultyList,
          errorEditingAdvisor: false,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ loadingFacultyList: false, errorEditingAdvisor: true });
      });
  }

  updateAdvisor() {
    const {
      match: { params },
    } = this.props;

    const { advisorSelect, facultyList } = this.state;

    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    const url = `${process.env.REACT_APP_API_URL}/student/updateAdvisor`;
    const id = `${params.studentid}`;

    axios
      .post(
        url,
        {
          id,
          advisor: advisorSelect,
        },
        config,
      )
      .then((response) => {
        // get email for updated advisor
        const filteredAdvisor = facultyList.filter((val) => {
          return val._id === response.data.advisor;
        });

        // update state to display updated advisor's email
        this.setState({
          loadingFacultyList: false,
          showFacultyList: false,
          advisor: filteredAdvisor[0].email,
          errorEditingAdvisor: false,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ loadingFacultyList: false, errorEditingAdvisor: true });
      });
  }

  updateProgram() {
    const {
      match: { params },
    } = this.props;

    const { currentProgram } = this.state;

    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    const url = `${process.env.REACT_APP_API_URL}/student/updateProgram`;
    const id = `${params.studentid}`;

    axios
      .post(
        url,
        {
          id,
          currentProgram,
        },
        config,
      )
      .then((response) => {
        // update state to display updated program
        this.setState({
          loadingEditProgram: false,
          currentProgram: response.data.currentProgram,
          errorEditingProgram: false,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ loadingEditProgram: false, errorEditingProgram: true });
      });
  }

  updateConcentration() {
    const {
      match: { params },
    } = this.props;

    const { concentrationSelect } = this.state;

    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    const url = `${process.env.REACT_APP_API_URL}/student/updateConcentration`;
    const id = `${params.studentid}`;

    axios
      .post(
        url,
        {
          id,
          professionalConcentration: concentrationSelect,
        },
        config,
      )
      .then((response) => {
        // update state to display updated status
        this.setState({
          loadingConcentrationList: false,
          showConcentrationList: false,
          professionalConcentration: response.data.professionalConcentration,
          errorEditingConcentration: false,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ loadingConcentrationList: false, errorEditingConcentration: true });
      });
  }

  updateSpecialization() {
    const {
      match: { params },
    } = this.props;

    const { specializationSelect } = this.state;

    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    const url = `${process.env.REACT_APP_API_URL}/student/updateSpecialization`;
    const id = `${params.studentid}`;

    axios
      .post(
        url,
        {
          id,
          professionalSpecialization: specializationSelect,
        },
        config,
      )
      .then((response) => {
        // update state to display updated status
        this.setState({
          loadingSpecializationList: false,
          showSpecializationList: false,
          professionalSpecialization: response.data.professionalSpecialization,
          errorEditingSpecialization: false,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ showSpecializationList: false, errorEditingSpecialization: true });
      });
  }

  updateStatus() {
    const {
      match: { params },
    } = this.props;

    const { statusSelect } = this.state;

    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    const url = `${process.env.REACT_APP_API_URL}/student/updateStatus`;
    const id = `${params.studentid}`;

    axios
      .post(
        url,
        {
          id,
          status: statusSelect,
        },
        config,
      )
      .then((response) => {
        // update state to display updated status
        this.setState({
          loadingStatusList: false,
          showStatusList: false,
          status: response.data.status,
          errorEditingStatus: false,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ loadingStatusList: false, errorEditingStatus: true });
      });
  }

  updatePermanentEmail() {
    const {
      match: { params },
    } = this.props;

    const { permanentEmailInput } = this.state;

    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    const url = `${process.env.REACT_APP_API_URL}/student/updatePermanentEmail`;
    const id = `${params.studentid}`;

    axios
      .post(
        url,
        {
          id,
          permanentEmail: permanentEmailInput,
        },
        config,
      )
      .then((response) => {
        // update state to display updated status
        this.setState({
          loadingPermanentEmail: false,
          editPermanentEmail: false,
          permanentEmail: response.data.permanentEmail,
          errorEditingPermanentEmail: false,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ loadingPermanentEmail: false, errorEditingPermanentEmail: true });
      });
  }

  toggleCollapse(card) {
    this.setState((state) => ({ [card]: !state[card] }));
  }

  renderAcademicYear(year) {
    const collapseStr = `collapse${year}`;
    const { forms } = this.state;
    // eslint-disable-next-line react/destructuring-assignment
    const collapsed = this.state[collapseStr];

    // get forms for selected year
    const filteredForms = forms.filter((val) => {
      return val.academicYear === year;
    });

    // set index of forms
    const idxAS = filteredForms.findIndex((form) => form.type === 'AS');
    const idxASE = filteredForms.findIndex((form) => form.type === 'ASE');
    const idxSPR = filteredForms.findIndex((form) => form.type === 'SPR');

    return (
      <Row key={year} className="mt-3">
        <Col lg={{ size: 10, offset: 1 }} xs={12}>
          <Button
            color="inverse"
            size="lg"
            onClick={() => this.toggleCollapse(`collapse${year}`)}
            style={{ marginBottom: '1rem' }}
            block
          >
            {`Year ${year}-${year + 1}`}
          </Button>
          <Collapse isOpen={collapsed}>
            <Card>
              <CardBody>
                {idxAS !== -1 && (
                  <Row>
                    <Col lg={6} xs={12}>
                      {filteredForms[idxAS].status === 'completed' && (
                        <Link to={`/form/${filteredForms[idxAS].type}/${filteredForms[idxAS]._id}`}>
                          <p>
                            Alumni Survey{' '}
                            <Badge className="ml-2" color="success">
                              Completed
                            </Badge>
                          </p>
                        </Link>
                      )}
                      {filteredForms[idxAS].status !== 'completed' && (
                        <p>
                          Alumni Survey{' '}
                          {filteredForms[idxAS].status === 'new' && (
                            <Badge className="ml-2" color="info">
                              New
                            </Badge>
                          )}
                          {filteredForms[idxAS].status === 'inProgress' && (
                            <Badge className="ml-2" color="warning">
                              In Progress
                            </Badge>
                          )}
                        </p>
                      )}
                    </Col>
                  </Row>
                )}
                {idxASE !== -1 && (
                  <Row>
                    <Col lg={6} xs={12}>
                      <Link to={`/form/${filteredForms[idxASE].type}/${filteredForms[idxASE]._id}`}>
                        <p>
                          Annual Student Evaluation{' '}
                          {filteredForms[idxASE].status === 'new' && (
                            <Badge className="ml-2" color="info">
                              New
                            </Badge>
                          )}
                          {filteredForms[idxASE].status === 'inProgress' && (
                            <Badge className="ml-2" color="warning">
                              In Progress
                            </Badge>
                          )}
                          {filteredForms[idxASE].status === 'completed' && (
                            <Badge className="ml-2" color="success">
                              Completed
                            </Badge>
                          )}
                        </p>
                      </Link>
                    </Col>
                  </Row>
                )}
                {idxSPR !== -1 && (
                  <Row>
                    <Col lg={6} xs={12}>
                      {filteredForms[idxSPR].status === 'completed' && (
                        <Link
                          to={`/form/${filteredForms[idxSPR].type}/${filteredForms[idxSPR]._id}`}
                        >
                          <p>
                            Student Progress Report{' '}
                            <Badge className="ml-2" color="success">
                              Completed
                            </Badge>
                          </p>
                        </Link>
                      )}
                      {filteredForms[idxSPR].status !== 'completed' && (
                        <p>
                          Student Progress Report{' '}
                          {filteredForms[idxSPR].status === 'new' && (
                            <Badge className="ml-2" color="info">
                              New
                            </Badge>
                          )}
                          {filteredForms[idxSPR].status === 'inProgress' && (
                            <Badge className="ml-2" color="warning">
                              In Progress
                            </Badge>
                          )}
                        </p>
                      )}
                    </Col>
                  </Row>
                )}
                {this.renderPracticumSites(year)}
              </CardBody>
            </Card>
          </Collapse>
        </Col>
      </Row>
    );
  }

  renderPracticumSites(year) {
    const { practicumSiteList } = this.state;

    const {
      match: { params },
    } = this.props;

    const { studentid } = params;
    const { currentProgram, professionalConcentration } = this.state;

    // get forms for selected year
    const filteredSites = practicumSiteList.filter((val) => {
      return val.academicYear === year;
    });

    return (
      <div>
        <Row>
          <Col>
            <p>
              <br />
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4 className="float-left">Practicum Sites</h4>
            <Button className="float-right" color="link" onClick={this.handleRefresh}>
              <i className="fa fa-repeat pull-right" />
            </Button>
            <Table hover>
              <thead>
                <tr>
                  <th scope="col">Term</th>
                  <th scope="col">Facility</th>
                  <th scope="col">Supervisor</th>
                  <th scope="col">Form Status</th>
                </tr>
              </thead>
              <tbody>
                {filteredSites.map((pracSite) => (
                  <tr key={pracSite._id}>
                    <td>{pracSite.term.charAt(0).toUpperCase() + pracSite.term.slice(1)}</td>
                    <td>
                      {pracSite.facility.name} | {pracSite.facility.department}
                    </td>
                    <td>
                      {pracSite.supervisor.firstName} {pracSite.supervisor.lastName}
                    </td>
                    <td>
                      {pracSite.formSent === false && <div className="text-danger">Not Sent</div>}
                      {pracSite.formSent === true && pracSite.form.status === 'new' && (
                        <div className="text-info">Sent</div>
                      )}
                      {pracSite.formSent === true && pracSite.form.status === 'inProgress' && (
                        <div className="text-warning">In Progress</div>
                      )}
                      {pracSite.formSent === true &&
                        pracSite.form.status === 'completed' &&
                        pracSite.form.type === 'SACE' && (
                          <Link to={`/form/SACE/${pracSite.form._id}`}>
                            <div className="text-success">Completed</div>
                          </Link>
                        )}
                      {pracSite.formSent === true &&
                        pracSite.form.status === 'completed' &&
                        pracSite.form.type === 'SACEv2' && (
                          <Link to={`/form/SACEv2/${pracSite.form._id}`}>
                            <div className="text-success">Completed</div>
                          </Link>
                        )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <AddPracticumSite
          student={studentid}
          academicYear={year}
          currentProgram={currentProgram}
          professionalConcentration={professionalConcentration}
        />
      </div>
    );
  }

  renderProfile() {
    const {
      advisor,
      currentProgram,
      email,
      firstName,
      idNumber,
      // internship,
      lastName,
      professionalConcentration,
      professionalSpecialization,
      // startYear,
      // transferredYear,
      year,
      status,
      permanentEmail,
      permanentEmailInput,
      loadingFacultyList,
      showFacultyList,
      facultyList,
      advisorSelect,
      loadingStatusList,
      showStatusList,
      loadingConcentrationList,
      showConcentrationList,
      concentrationSelect,
      loadingSpecializationList,
      showSpecializationList,
      specializationSelect,
      statusSelect,
      editPermanentEmail,
      loadingPermanentEmail,
      formYears,
      collapseDoc,
      showProgramModal,
      loadingEditProgram,
      errorEditingAdvisor,
      errorEditingProgram,
      errorEditingConcentration,
      errorEditingSpecialization,
      errorEditingStatus,
      errorEditingPermanentEmail,
      errorInvalidPermanentEmail,
    } = this.state;

    return (
      <Container fluid>
        <Row>
          <Col lg={{ size: 10, offset: 1 }}>
            <div className="crumbs">
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to="/dashboard">Dashboard</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>Student Profile</BreadcrumbItem>
              </Breadcrumb>
            </div>
          </Col>
        </Row>
        {/* <Row>
          <Col sm={{ size: 8, offset: 2 }}>
            <Alert color="inverse">
              Thanks so much for filling out the <strong>Student Satisfaction Survey</strong>.
            </Alert>
          </Col>
        </Row> */}
        <div>
          <Modal isOpen={showProgramModal} toggle={this.onCancelEditProgramBtnClick}>
            <ModalHeader toggle={this.onCancelEditProgramBtnClick}>Switch Program</ModalHeader>
            <ModalBody>
              <b>
                <h5>
                  You are about to switch {firstName} {lastName}&apos;s current program from{' '}
                  <b>
                    {currentProgram} to {currentProgram === 'Ph.D.' && 'Psy.D.'}
                    {currentProgram === 'Psy.D.' && 'Ph.D.'}
                  </b>
                </h5>
                <h4 className="text-danger text-center mt-3">Are you sure?</h4>
              </b>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.onConfirmEditProgramBtnClick}>
                Okay
              </Button>
              <Button color="secondary" onClick={this.onCancelEditProgramBtnClick}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
        <Row>
          <Col lg={{ size: 10, offset: 1 }}>
            <h2 className="page-title">Student Profile</h2>
          </Col>
        </Row>
        <Row>
          <Col lg={{ size: 10, offset: 1 }}>
            <Widget>
              <Row>
                <Col md={4}>
                  <div className="text-center">
                    <img
                      className="rounded-circle"
                      style={{ height: `${112} + px` }}
                      src={iUser}
                      alt="64x64"
                    />
                  </div>
                </Col>
                <Col md={8}>
                  <h3 className="mt-md mb-xs">{`${firstName} ${lastName}`}</h3>
                  <Table hover>
                    <tbody>
                      <tr>
                        <th scope="row">ID:</th>
                        <td width="5" />
                        <td>{idNumber}</td>
                      </tr>
                      <tr>
                        <th scope="row">EMAIL:</th>
                        <td />
                        <td>{email}</td>
                      </tr>
                      <tr>
                        <th scope="row">YEAR:</th>
                        <td />
                        <td>{year}</td>
                      </tr>
                      <tr>
                        <th scope="row">ADVISOR:</th>
                        <td />
                        <td>
                          {!showFacultyList && (
                            <div>
                              {advisor}
                              {!loadingFacultyList && (
                                <Button
                                  className="float-right"
                                  color="link"
                                  onClick={this.onEditAdvisorBtnClick}
                                >
                                  <i className="fa fa-edit pull-right" />
                                </Button>
                              )}
                              {loadingFacultyList && (
                                <img
                                  src={loadingSpinnerRed}
                                  alt="loading spinner"
                                  className="float-right"
                                  style={{ maxWidth: 20 }}
                                />
                              )}
                            </div>
                          )}
                          {showFacultyList && (
                            <div className="d-flex">
                              <Input
                                type="select"
                                name="advisorSelect"
                                id="advisorSelect"
                                value={advisorSelect}
                                onChange={this.handleInputChange}
                                style={{ width: '80%' }}
                              >
                                {facultyList.map((fac) => (
                                  <option
                                    key={fac._id}
                                    value={fac._id}
                                  >{`${fac.firstName} ${fac.middleInitial} ${fac.lastName}, ${fac.title}`}</option>
                                ))}
                              </Input>
                              {!loadingFacultyList && (
                                <div className="d-flex">
                                  <Button
                                    className="float-right ml-2"
                                    color="link"
                                    onClick={this.onSaveEditAdvisorBtnClick}
                                  >
                                    <i className="fa fa-floppy-o" />
                                  </Button>
                                  <Button
                                    className="float-right"
                                    color="link"
                                    onClick={this.onCancelEditAdvisorBtnClick}
                                  >
                                    <i className="fa fa-ban" />
                                  </Button>
                                </div>
                              )}
                              {loadingFacultyList && (
                                <img
                                  src={loadingSpinnerRed}
                                  alt="loading spinner"
                                  className="img-fluid ml-5"
                                  style={{ maxWidth: 20 }}
                                />
                              )}
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">PROGRAM:</th>
                        <td />
                        <td>
                          <div>
                            {currentProgram}
                            {!loadingEditProgram && (
                              <Button
                                className="float-right"
                                color="link"
                                onClick={this.onEditProgramBtnClick}
                              >
                                <i className="fa fa-edit pull-right" />
                              </Button>
                            )}
                            {loadingEditProgram && (
                              <img
                                src={loadingSpinnerRed}
                                alt="loading spinner"
                                className="float-right"
                                style={{ maxWidth: 20 }}
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">PROFESSIONAL CONCENTRATION:</th>
                        <td />
                        <td>
                          {!showConcentrationList && (
                            <div>
                              {professionalConcentration}
                              <Button
                                className="float-right"
                                color="link"
                                onClick={this.onEditConcentrationBtnClick}
                              >
                                <i className="fa fa-edit pull-right" />
                              </Button>
                            </div>
                          )}
                          {showConcentrationList && (
                            <div className="d-flex">
                              <Input
                                type="select"
                                name="concentrationSelect"
                                id="concentrationSelect"
                                value={concentrationSelect}
                                onChange={this.handleInputChange}
                              >
                                <option>Clinical Child</option>
                                <option>Clinical Health Psychology</option>
                                <option>Health Psychology</option>
                                <option>Neuroscience & Neuropsychology</option>
                                <option>Optional (Effective Fall 2014)</option>
                                <option>Primary Care</option>
                                <option>Social Cultural Behavioral Health</option>
                              </Input>
                              {!loadingConcentrationList && (
                                <div className="d-flex">
                                  <Button
                                    className="float-right ml-2"
                                    color="link"
                                    onClick={this.onSaveEditConcentrationBtnClick}
                                  >
                                    <i className="fa fa-floppy-o" />
                                  </Button>
                                  <Button
                                    className="float-right"
                                    color="link"
                                    onClick={this.onCancelEditConcentrationBtnClick}
                                  >
                                    <i className="fa fa-ban" />
                                  </Button>
                                </div>
                              )}
                              {loadingConcentrationList && (
                                <img
                                  src={loadingSpinnerRed}
                                  alt="loading spinner"
                                  className="img-fluid ml-5"
                                  style={{ maxWidth: 20 }}
                                />
                              )}
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">PROFESSIONAL SPECIALIZATION:</th>
                        <td />
                        <td>
                          {!showSpecializationList && (
                            <div>
                              {professionalSpecialization}
                              <Button
                                className="float-right"
                                color="link"
                                onClick={this.onEditSpecializationBtnClick}
                              >
                                <i className="fa fa-edit pull-right" />
                              </Button>
                            </div>
                          )}
                          {showSpecializationList && (
                            <div className="d-flex">
                              <Input
                                type="select"
                                name="specializationSelect"
                                id="specializationSelect"
                                value={specializationSelect}
                                onChange={this.handleInputChange}
                              >
                                <option>Child and Adolescent Clinical Psychology</option>
                                <option>Clinical Generalist</option>
                                <option>Clinical Neuropsychology</option>
                                <option>Health Psychology and Integrated Care</option>
                                <option>None</option>
                              </Input>
                              {!loadingSpecializationList && (
                                <div className="d-flex">
                                  <Button
                                    className="float-right ml-2"
                                    color="link"
                                    onClick={this.onSaveEditSpecializationBtnClick}
                                  >
                                    <i className="fa fa-floppy-o" />
                                  </Button>
                                  <Button
                                    className="float-right"
                                    color="link"
                                    onClick={this.onCancelEditSpecializationBtnClick}
                                  >
                                    <i className="fa fa-ban" />
                                  </Button>
                                </div>
                              )}
                              {loadingSpecializationList && (
                                <img
                                  src={loadingSpinnerRed}
                                  alt="loading spinner"
                                  className="img-fluid ml-5"
                                  style={{ maxWidth: 20 }}
                                />
                              )}
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">STATUS:</th>
                        <td />
                        <td>
                          {!showStatusList && (
                            <div>
                              {status === 'current' && 'Current'}
                              {status === 'scheduledToGraduate' && 'Scheduled to Graduate'}
                              {status === 'alumni' && 'Alumni'}
                              {status === 'leaveOfAbsence' && 'Leave of Absence'}
                              {status === 'leftProgram' && 'Left Program'}
                              {status === 'other' && 'Other'}
                              <Button
                                className="float-right"
                                color="link"
                                onClick={this.onEditStatusBtnClick}
                              >
                                <i className="fa fa-edit pull-right" />
                              </Button>
                            </div>
                          )}
                          {showStatusList && (
                            <div className="d-flex">
                              <Input
                                type="select"
                                name="statusSelect"
                                id="statusSelect"
                                value={statusSelect}
                                onChange={this.handleInputChange}
                                style={{ width: '80%' }}
                              >
                                <option key="current" value="current">
                                  Current
                                </option>
                                <option key="scheduledToGraduate" value="scheduledToGraduate">
                                  Scheduled to Graduate
                                </option>
                                <option key="alumni" value="alumni">
                                  Alumni
                                </option>
                                <option key="leaveOfAbsence" value="leaveOfAbsence">
                                  Leave of Absence
                                </option>
                                <option key="leftProgram" value="leftProgram">
                                  Left Program
                                </option>
                                <option key="other" value="other">
                                  Other
                                </option>
                              </Input>
                              {!loadingStatusList && (
                                <div className="d-flex">
                                  <Button
                                    className="float-right ml-2"
                                    color="link"
                                    onClick={this.onSaveEditStatusBtnClick}
                                  >
                                    <i className="fa fa-floppy-o" />
                                  </Button>
                                  <Button
                                    className="float-right"
                                    color="link"
                                    onClick={this.onCancelEditStatusBtnClick}
                                  >
                                    <i className="fa fa-ban" />
                                  </Button>
                                </div>
                              )}
                              {loadingStatusList && (
                                <img
                                  src={loadingSpinnerRed}
                                  alt="loading spinner"
                                  className="img-fluid ml-5"
                                  style={{ maxWidth: 20 }}
                                />
                              )}
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">PERMANENT EMAIL:</th>
                        <td />
                        <td>
                          {!editPermanentEmail && (
                            <div>
                              {permanentEmail}
                              <Button
                                className="float-right"
                                color="link"
                                onClick={this.onEditPermanentEmailBtnClick}
                              >
                                <i className="fa fa-edit pull-right" />
                              </Button>
                            </div>
                          )}
                          {editPermanentEmail && (
                            <div className="d-flex">
                              <Input
                                type="text"
                                name="permanentEmailInput"
                                id="permanentEmailInput"
                                value={permanentEmailInput}
                                onChange={this.handleInputChange}
                                style={{ width: '80%' }}
                                disabled={loadingPermanentEmail}
                              />
                              {!loadingPermanentEmail && (
                                <div className="d-flex">
                                  <Button
                                    className="float-right ml-2"
                                    color="link"
                                    onClick={this.onSaveEditPermanentEmailBtnClick}
                                  >
                                    <i className="fa fa-floppy-o" />
                                  </Button>
                                  <Button
                                    className="float-right"
                                    color="link"
                                    onClick={this.onCancelEditPermanentEmailBtnClick}
                                  >
                                    <i className="fa fa-ban" />
                                  </Button>
                                </div>
                              )}
                              {loadingPermanentEmail && (
                                <img
                                  src={loadingSpinnerRed}
                                  alt="loading spinner"
                                  className="img-fluid ml-5"
                                  style={{ maxWidth: 20 }}
                                />
                              )}
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">STUDENT ADMISSION DATA:</th>
                        <td />
                        <td>
                          <div className="text-danger">Incomplete</div>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          {/* <Link to="#">
                            <div className="text-danger">Incomplete</div>
                          </Link> */}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              {errorEditingAdvisor && (
                <Row>
                  <Col>
                    <div className="text-danger text-center">
                      <b>
                        <p>ERROR UPDATING ADVISOR, PLEASE TRY AGAIN</p>
                      </b>
                    </div>
                  </Col>
                </Row>
              )}
              {errorEditingProgram && (
                <Row>
                  <Col>
                    <div className="text-danger text-center">
                      <b>
                        <p>ERROR UPDATING PROGRAM, PLEASE TRY AGAIN</p>
                      </b>
                    </div>
                  </Col>
                </Row>
              )}
              {errorEditingConcentration && (
                <Row>
                  <Col>
                    <div className="text-danger text-center">
                      <b>
                        <p>ERROR UPDATING PROFESSIONAL CONCENTRATION, PLEASE TRY AGAIN</p>
                      </b>
                    </div>
                  </Col>
                </Row>
              )}
              {errorEditingSpecialization && (
                <Row>
                  <Col>
                    <div className="text-danger text-center">
                      <b>
                        <p>ERROR UPDATING PROFESSIONAL SPECIALIZATION, PLEASE TRY AGAIN</p>
                      </b>
                    </div>
                  </Col>
                </Row>
              )}
              {errorEditingStatus && (
                <Row>
                  <Col>
                    <div className="text-danger text-center">
                      <b>
                        <p>ERROR UPDATING STATUS, PLEASE TRY AGAIN</p>
                      </b>
                    </div>
                  </Col>
                </Row>
              )}
              {errorEditingPermanentEmail && (
                <Row>
                  <Col>
                    <div className="text-danger text-center">
                      <b>
                        <p>ERROR UPDATING PERMANENT EMAIL, PLEASE TRY AGAIN</p>
                      </b>
                    </div>
                  </Col>
                </Row>
              )}
              {errorInvalidPermanentEmail && (
                <Row>
                  <Col>
                    <div className="text-danger text-center">
                      <b>
                        <p>INVALID EMAIL ADDRESS</p>
                      </b>
                    </div>
                  </Col>
                </Row>
              )}
              {formYears.map((academicYear) => this.renderAcademicYear(academicYear))}
              <Row>
                <Col lg={12}>
                  <p>
                    <br />
                  </p>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col lg={{ size: 10, offset: 1 }}>
                  <Button
                    color="inverse"
                    size="lg"
                    onClick={() => this.toggleCollapse('collapseDoc')}
                    style={{ marginBottom: '1rem' }}
                    block
                  >
                    Uploaded Documents
                  </Button>
                  <Collapse isOpen={collapseDoc}>
                    <Card>
                      <CardBody className="text-center">
                        <strong>No Files Uploaded</strong>
                      </CardBody>
                    </Card>
                  </Collapse>
                </Col>
              </Row>
            </Widget>
          </Col>
        </Row>
        <Row>
          <Col lg={{ size: 10, offset: 1 }}>
            <Link to="/dashboard">
              <Button color="inverse" size="lg" className="back">
                <i className="fa fa-angle-left" /> Back
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    );
  }

  renderLoading() {
    return (
      <Container>
        <Row>
          <Col lg={{ size: 10, offset: 1 }}>
            <div className="crumbs">
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to="/dashboard">Dashboard</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>Student Profile</BreadcrumbItem>
              </Breadcrumb>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={{ size: 10, offset: 1 }}>
            <h2 className="page-title">Student Profile</h2>
          </Col>
        </Row>
        <Row className="text-center">
          <Col>
            <img src={loadingSpinner} alt="loading spinner" className="img-responsive" />
          </Col>
        </Row>
      </Container>
    );
  }

  render() {
    const { loading } = this.state;

    if (!loading) {
      return <div>{this.renderProfile()}</div>;
    }
    return <div>{this.renderLoading()}</div>;
  }
}

StudentProfile.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withRouter(StudentProfile);
