/* eslint-disable react/function-component-definition */
import React from 'react';
import { Row, Col } from 'reactstrap';
import AddClinicalSupervisor from '../AddClinicalSupervisor/AddClinicalSupervisor';
import ClinicalSuperList from '../ClinicalSuperList/ClinicalSuperList';
import ClinicalSuperSearch from '../ClinicalSuperSearch/ClinicalSuperSearch';

const FacilityAdmin = () => {
  return (
    <div>
      <Row>
        <Col>
          <ClinicalSuperSearch />
          <AddClinicalSupervisor />
          <ClinicalSuperList />
        </Col>
      </Row>
    </div>
  );
};

export default FacilityAdmin;
