/* eslint-disable consistent-return */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
import React from 'react';
import {
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  // Input,
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import axios from 'axios';
import auth0Client from '../../Auth/Auth';

import Widget from '../Widget/Widget';

import '../Forms/forms.scss';

// import loadingSpinnerRed from '../../images/DualRing-1s-200px.svg';

function calcPercentage(x, y) {
  const percent = (x / y) * 100;

  if (!Number.isNaN(percent)) {
    return Number(percent.toFixed());
  }
  return 0;
}

class FormAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleMultipleInputChange = this.handleMultipleInputChange.bind(this);
    this.onActivateSACEBtnClick = this.onActivateSACEBtnClick.bind(this);
    this.getFormStatistics = this.getFormStatistics.bind(this);
    this.toggleModal = this.toggleModal.bind(this);

    this.state = {
      selectedForm: '',
      selectedYear: '',
      selectedTerm: '',
      allStudents: 'Yes',
      selectedStudents: [],
      asTotal: 0,
      asNew: 0,
      asInProgress: 0,
      asCompleted: 0,
      aseTotal: 0,
      aseNew: 0,
      aseInProgress: 0,
      aseCompleted: 0,
      pseTotal: 0,
      pseNew: 0,
      pseInProgress: 0,
      pseCompleted: 0,
      saceFallTotal: 0,
      saceFallNew: 0,
      saceFallInProgress: 0,
      saceFallCompleted: 0,
      saceSpringTotal: 0,
      saceSpringNew: 0,
      saceSpringInProgress: 0,
      saceSpringCompleted: 0,
      sprTotal: 0,
      sprNew: 0,
      sprInProgress: 0,
      sprCompleted: 0,
      sssTotal: 0,
      sssNew: 0,
      sssInProgress: 0,
      sssCompleted: 0,
      modal: false,
    };
  }

  componentDidMount() {
    this.getFormStatistics();
  }

  handleInputChange(e) {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  handleMultipleInputChange(e) {
    // assuming you initialized the default state to hold selected values
    this.setState({
      selectedStudents: [].slice.call(e.target.selectedOptions).map((o) => {
        return o.value;
      }),
    });
  }

  onActivateSACEBtnClick() {
    return null;
  }

  getFormStatistics() {
    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    const url = `${process.env.REACT_APP_API_URL}/form/statistics`;

    axios
      .post(url, { academicYear: 2024 }, config)
      .then((response) => {
        const forms = response.data;

        const asTotal = forms.reduce((n, item) => (item.type === 'AS' ? n + 1 : n), 0);
        const asNew = forms.reduce(
          (n, item) => (item.type === 'AS' && item.status === 'new' ? n + 1 : n),
          0,
        );
        const asInProgress = forms.reduce(
          (n, item) => (item.type === 'AS' && item.status === 'inProgress' ? n + 1 : n),
          0,
        );
        const asCompleted = forms.reduce(
          (n, item) => (item.type === 'AS' && item.status === 'completed' ? n + 1 : n),
          0,
        );

        const saceFallTotal = forms.reduce(
          (n, item) => (item.type === 'SACE' && item.term === 'fall' ? n + 1 : n),
          0,
        );
        const saceFallNew = forms.reduce(
          (n, item) =>
            item.type === 'SACE' && item.term === 'fall' && item.status === 'new' ? n + 1 : n,
          0,
        );
        const saceFallInProgress = forms.reduce(
          (n, item) =>
            item.type === 'SACE' && item.term === 'fall' && item.status === 'inProgress'
              ? n + 1
              : n,
          0,
        );
        const saceFallCompleted = forms.reduce(
          (n, item) =>
            item.type === 'SACE' && item.term === 'fall' && item.status === 'completed' ? n + 1 : n,
          0,
        );

        const saceSpringTotal = forms.reduce(
          (n, item) =>
            (item.type === 'SACE' || item.type === 'SACEv2') && item.term === 'spring' ? n + 1 : n,
          0,
        );
        const saceSpringNew = forms.reduce(
          (n, item) =>
            (item.type === 'SACE' || item.type === 'SACEv2') &&
            item.term === 'spring' &&
            item.status === 'new'
              ? n + 1
              : n,
          0,
        );
        const saceSpringInProgress = forms.reduce(
          (n, item) =>
            (item.type === 'SACE' || item.type === 'SACEv2') &&
            item.term === 'spring' &&
            item.status === 'inProgress'
              ? n + 1
              : n,
          0,
        );
        const saceSpringCompleted = forms.reduce(
          (n, item) =>
            (item.type === 'SACE' || item.type === 'SACEv2') &&
            item.term === 'spring' &&
            item.status === 'completed'
              ? n + 1
              : n,
          0,
        );

        const pseTotal = forms.reduce((n, item) => (item.type === 'PSE' ? n + 1 : n), 0);
        const pseNew = forms.reduce(
          (n, item) => (item.type === 'PSE' && item.status === 'new' ? n + 1 : n),
          0,
        );
        const pseInProgress = forms.reduce(
          (n, item) => (item.type === 'PSE' && item.status === 'inProgress' ? n + 1 : n),
          0,
        );
        const pseCompleted = forms.reduce(
          (n, item) => (item.type === 'PSE' && item.status === 'completed' ? n + 1 : n),
          0,
        );

        const sssTotal = forms.reduce((n, item) => (item.type === 'SSS' ? n + 1 : n), 0);
        const sssNew = forms.reduce(
          (n, item) => (item.type === 'SSS' && item.status === 'new' ? n + 1 : n),
          0,
        );
        const sssInProgress = forms.reduce(
          (n, item) => (item.type === 'SSS' && item.status === 'inProgress' ? n + 1 : n),
          0,
        );
        const sssCompleted = forms.reduce(
          (n, item) => (item.type === 'SSS' && item.status === 'completed' ? n + 1 : n),
          0,
        );

        axios.post(url, { academicYear: 2023 }, config).then((responsePreviousYear) => {
          const formsPreviousYear = responsePreviousYear.data;

          const aseTotal = formsPreviousYear.reduce(
            (n, item) => (item.type === 'ASE' ? n + 1 : n),
            0,
          );
          const aseNew = formsPreviousYear.reduce(
            (n, item) => (item.type === 'ASE' && item.status === 'new' ? n + 1 : n),
            0,
          );
          const aseInProgress = formsPreviousYear.reduce(
            (n, item) => (item.type === 'ASE' && item.status === 'inProgress' ? n + 1 : n),
            0,
          );
          const aseCompleted = formsPreviousYear.reduce(
            (n, item) => (item.type === 'ASE' && item.status === 'completed' ? n + 1 : n),
            0,
          );

          const sprTotal = formsPreviousYear.reduce(
            (n, item) => (item.type === 'SPR' ? n + 1 : n),
            0,
          );
          const sprNew = formsPreviousYear.reduce(
            (n, item) => (item.type === 'SPR' && item.status === 'new' ? n + 1 : n),
            0,
          );
          const sprInProgress = formsPreviousYear.reduce(
            (n, item) => (item.type === 'SPR' && item.status === 'inProgress' ? n + 1 : n),
            0,
          );
          const sprCompleted = formsPreviousYear.reduce(
            (n, item) => (item.type === 'SPR' && item.status === 'completed' ? n + 1 : n),
            0,
          );

          this.setState({
            asTotal,
            asNew,
            asInProgress,
            asCompleted,
            aseTotal,
            aseNew,
            aseInProgress,
            aseCompleted,
            pseTotal,
            pseNew,
            pseInProgress,
            pseCompleted,
            saceFallTotal,
            saceFallNew,
            saceFallInProgress,
            saceFallCompleted,
            saceSpringTotal,
            saceSpringNew,
            saceSpringInProgress,
            saceSpringCompleted,
            sprTotal,
            sprNew,
            sprInProgress,
            sprCompleted,
            sssTotal,
            sssNew,
            sssInProgress,
            sssCompleted,
          });
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }

  toggleModal() {
    const { modal } = this.state;

    this.setState({ modal: !modal });
  }

  render() {
    const {
      asTotal,
      asNew,
      asInProgress,
      asCompleted,
      aseTotal,
      aseNew,
      aseInProgress,
      aseCompleted,
      pseTotal,
      pseNew,
      pseInProgress,
      pseCompleted,
      saceFallTotal,
      saceFallNew,
      saceFallInProgress,
      saceFallCompleted,
      saceSpringTotal,
      saceSpringNew,
      saceSpringInProgress,
      saceSpringCompleted,
      sprTotal,
      sprNew,
      sprInProgress,
      sprCompleted,
      sssTotal,
      sssNew,
      sssInProgress,
      sssCompleted,
      modal,
    } = this.state;

    return (
      <div>
        <div>
          <Modal isOpen={modal} toggle={this.toggleModal}>
            <ModalHeader toggle={this.toggleModal}>Activate New School Year</ModalHeader>
            <ModalBody>
              <p>
                Would you like to activate the new school year? This will start the new school year
                and activate the following forms:
              </p>
              <p>
                <b>Alumni Survey</b>
                <br />
                <b>Annual Student Evaluation</b>
                <br />
                <b>Dissertation/Doctoral Project Research Evaluation</b>
                <br />
                <b>Practicum Site Evaluation</b>
                <br />
                <b>Student Progress Report</b>
                <br />
                <b>Student Satisfaction Survey</b>
              </p>
              <p>
                Please make sure all graduating students are set as Alumni or Scheduled to Graduate
                before continuing.
              </p>
            </ModalBody>
            <ModalFooter>
              <Button
                id="btn-ActivateNewSchoolYear"
                color="primary"
                onClick={this.toggleModal}
                disabled
              >
                Activate
              </Button>
              <Button color="secondary" onClick={this.toggleModal} className="ml-3">
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
        <Row>
          {/* Selection */}
          <Col xl={{ size: 10, offset: 1 }} xs={12}>
            <Widget
              title={
                <h5>
                  {' '}
                  <i className="fa fa-align-left" /> Form Activation for 2024-2025 Academic Year
                </h5>
              }
            >
              <Form className="mt-4">
                <FormGroup row>
                  <Label md={4} className="text-md-right">
                    Semi-Annual Clinical Evaluation (Fall)
                  </Label>
                  <Col md={4}>
                    <Button
                      color="dark"
                      onClick={this.onActivateSACEBtnClick}
                      className="ml-xs"
                      disabled
                    >
                      Activate
                    </Button>
                  </Col>
                </FormGroup>
                {/* <FormGroup row>
                  <Label md={4} className="text-md-right">
                    Semi-Annual Clinical Evaluation (Spring)
                  </Label>
                  <Col md={4}>
                    <Button color="dark" onClick={this.onActivateSACEBtnClick} className="ml-xs">
                      Activate Form
                    </Button>
                  </Col>
                </FormGroup> */}
                <FormGroup row>
                  <Label md={4} className="text-md-right">
                    Semi-Annual Clinical Evaluation (Spring)
                  </Label>
                  <Col md={4}>
                    <Button
                      color="dark"
                      onClick={this.onActivateSACEBtnClick}
                      className="ml-xs"
                      disabled
                    >
                      Activate
                    </Button>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label md={4} className="text-md-right">
                    Activate New School Year
                  </Label>
                  <Col md={4}>
                    <Button color="dark" onClick={this.toggleModal} className="ml-xs" disabled>
                      Activate
                    </Button>
                  </Col>
                </FormGroup>
              </Form>
            </Widget>

            <Widget
              title={
                <h5>
                  {' '}
                  <i className="fa fa-align-left" /> Form Statistics for 2024-2025 Academic Year{' '}
                </h5>
              }
            >
              <Table hover>
                <thead>
                  <tr>
                    <th className="text-left">Name</th>
                    <th className="text-right">Total</th>
                    <th className="text-right">Not Started</th>
                    <th className="text-right">In Progress</th>
                    <th className="text-right">Completed</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Alumni Survey</th>
                    <td className="text-right">{asTotal}</td>
                    <td className="text-right">
                      {asNew}{' '}
                      <Badge className="statisticsBadge" color="danger">
                        {calcPercentage(asNew, asTotal)}%
                      </Badge>
                    </td>
                    <td className="text-right">
                      {asInProgress}{' '}
                      <Badge className="statisticsBadge" color="warning">
                        {calcPercentage(asInProgress, asTotal)}%
                      </Badge>
                    </td>
                    <td className="text-right">
                      {asCompleted}{' '}
                      <Badge className="statisticsBadge" color="success">
                        {calcPercentage(asCompleted, asTotal)}%
                      </Badge>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Annual Student Evaluation</th>
                    <td className="text-right">{aseTotal}</td>
                    <td className="text-right">
                      {aseNew}{' '}
                      <Badge className="statisticsBadge" color="danger">
                        {calcPercentage(aseNew, aseTotal)}%
                      </Badge>
                    </td>
                    <td className="text-right">
                      {aseInProgress}{' '}
                      <Badge className="statisticsBadge" color="warning">
                        {calcPercentage(aseInProgress, aseTotal)}%
                      </Badge>
                    </td>
                    <td className="text-right">
                      {aseCompleted}{' '}
                      <Badge className="statisticsBadge" color="success">
                        {calcPercentage(aseCompleted, aseTotal)}%
                      </Badge>
                    </td>
                  </tr>
                  {/* <tr>
                    <th scope="row">Dissertation/Doctoral Project Research Evaluation</th>
                    <td className="text-right">24</td>
                    <td className="text-right">
                      6 <Badge className="statisticsBadge" color="danger">25%</Badge>
                    </td>
                    <td className="text-right">
                      10 <Badge className="statisticsBadge" color="warning">42%</Badge>
                    </td>
                    <td className="text-right">
                      8 <Badge className="statisticsBadge" color="success">33%</Badge>
                    </td>
                  </tr> */}
                  <tr>
                    <th scope="row">Practicum Site Evaluation</th>
                    <td className="text-right">{pseTotal}</td>
                    <td className="text-right">
                      {pseNew}{' '}
                      <Badge className="statisticsBadge" color="danger">
                        {calcPercentage(pseNew, pseTotal)}%
                      </Badge>
                    </td>
                    <td className="text-right">
                      {pseInProgress}{' '}
                      <Badge className="statisticsBadge" color="warning">
                        {calcPercentage(pseInProgress, pseTotal)}%
                      </Badge>
                    </td>
                    <td className="text-right">
                      {pseCompleted}{' '}
                      <Badge className="statisticsBadge" color="success">
                        {calcPercentage(pseCompleted, pseTotal)}%
                      </Badge>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Semi-Annual Clinical Evaluation - Fall</th>
                    <td className="text-right">{saceFallTotal}</td>
                    <td className="text-right">
                      {saceFallNew}{' '}
                      <Badge className="statisticsBadge" color="danger">
                        {calcPercentage(saceFallNew, saceFallTotal)}%
                      </Badge>
                    </td>
                    <td className="text-right">
                      {saceFallInProgress}{' '}
                      <Badge className="statisticsBadge" color="warning">
                        {calcPercentage(saceFallInProgress, saceFallTotal)}%
                      </Badge>
                    </td>
                    <td className="text-right">
                      {saceFallCompleted}{' '}
                      <Badge className="statisticsBadge" color="success">
                        {calcPercentage(saceFallCompleted, saceFallTotal)}%
                      </Badge>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Semi-Annual Clinical Evaluation - Spring</th>
                    <td className="text-right">{saceSpringTotal}</td>
                    <td className="text-right">
                      {saceSpringNew}{' '}
                      <Badge className="statisticsBadge" color="danger">
                        {calcPercentage(saceSpringNew, saceSpringTotal)}%
                      </Badge>
                    </td>
                    <td className="text-right">
                      {saceSpringInProgress}{' '}
                      <Badge className="statisticsBadge" color="warning">
                        {calcPercentage(saceSpringInProgress, saceSpringTotal)}%
                      </Badge>
                    </td>
                    <td className="text-right">
                      {saceSpringCompleted}{' '}
                      <Badge className="statisticsBadge" color="success">
                        {calcPercentage(saceSpringCompleted, saceSpringTotal)}%
                      </Badge>
                    </td>
                  </tr>
                  {/* <tr>
                    <th scope="row">Student Admission Data</th>
                    <td className="text-right">24</td>
                    <td className="text-right">
                      6 <Badge className="statisticsBadge" color="danger">25%</Badge>
                    </td>
                    <td className="text-right">
                      10 <Badge className="statisticsBadge" color="warning">42%</Badge>
                    </td>
                    <td className="text-right">
                      8 <Badge className="statisticsBadge" color="success">33%</Badge>
                    </td>
                  </tr> */}
                  <tr>
                    <th scope="row">Student Progress Report</th>
                    <td className="text-right">{sprTotal}</td>
                    <td className="text-right">
                      {sprNew}{' '}
                      <Badge className="statisticsBadge" color="danger">
                        {calcPercentage(sprNew, sprTotal)}%
                      </Badge>
                    </td>
                    <td className="text-right">
                      {sprInProgress}{' '}
                      <Badge className="statisticsBadge" color="warning">
                        {calcPercentage(sprInProgress, sprTotal)}%
                      </Badge>
                    </td>
                    <td className="text-right">
                      {sprCompleted}{' '}
                      <Badge className="statisticsBadge" color="success">
                        {calcPercentage(sprCompleted, sprTotal)}%
                      </Badge>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Student Satisfaction Survey</th>
                    <td className="text-right">{sssTotal}</td>
                    <td className="text-right">
                      {sssNew}{' '}
                      <Badge className="statisticsBadge" color="danger">
                        {calcPercentage(sssNew, sssTotal)}%
                      </Badge>
                    </td>
                    <td className="text-right">
                      {sssInProgress}{' '}
                      <Badge className="statisticsBadge" color="warning">
                        {calcPercentage(sssInProgress, sssTotal)}%
                      </Badge>
                    </td>
                    <td className="text-right">
                      {sssCompleted}{' '}
                      <Badge className="statisticsBadge" color="success">
                        {calcPercentage(sssCompleted, sssTotal)}%
                      </Badge>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Widget>
          </Col>
        </Row>
        <p>
          <br />
        </p>
      </div>
    );
  }
}

export default FormAdmin;
