/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Form, FormGroup, Input, Label } from 'reactstrap';
import axios from 'axios';
import auth0Client from '../../Auth/Auth';

import loadingSpinner from '../../images/Eclipse-1s-200px.svg';

class AddPracticumSite extends React.Component {
  constructor(props) {
    super(props);
    this.addPracticumSite = this.addPracticumSite.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSupervisorChange = this.handleSupervisorChange.bind(this);
    this.onAddNewPracticumSiteBtnClick = this.onAddNewPracticumSiteBtnClick.bind(this);
    this.onNewSiteSubmitBtnClick = this.onNewSiteSubmitBtnClick.bind(this);
    this.onNewSiteCancelBtnClick = this.onNewSiteCancelBtnClick.bind(this);
    this.onAddAnotherSiteBtnClick = this.onAddAnotherSiteBtnClick.bind(this);
    this.renderPracticumSiteForm = this.renderPracticumSiteForm.bind(this);
    this.renderSiteAddedConfirmation = this.renderSiteAddedConfirmation.bind(this);

    this.state = {
      addNewPracticumSite: false,
      facility: '',
      supervisor: '',
      emailAddress: '',
      phoneNumber: '',
      term: 'fall',
      facilityList: '',
      supervisorList: '',
      showConfirmation: false,
      loading: false,
      errorAddingPracticumSite: false,
    };
  }

  handleInputChange(e) {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  handleSupervisorChange(e) {
    const { target } = e;
    const { value } = target;
    const { supervisorList } = this.state;

    const selectedSupervisor = supervisorList.filter((sup) => sup._id === value);

    this.setState({
      supervisor: value,
      emailAddress: selectedSupervisor[0].email[0].emailAddress,
      phoneNumber: selectedSupervisor[0].phone[0].phoneNumber,
    });
  }

  onAddNewPracticumSiteBtnClick() {
    const { addNewPracticumSite } = this.state;
    this.setState({
      addNewPracticumSite: !addNewPracticumSite,
      loading: true,
    });
    this.getFacilitiesSupervisors();
  }

  onNewSiteSubmitBtnClick() {
    this.setState({
      loading: true,
    });
    this.addPracticumSite();
  }

  onNewSiteCancelBtnClick() {
    this.setState({
      addNewPracticumSite: false,
      facility: '',
      supervisor: '',
      emailAddress: '',
      phoneNumber: '',
      term: 'fall',
      facilityList: '',
      supervisorList: '',
      showConfirmation: false,
      errorAddingPracticumSite: false,
    });
  }

  onAddAnotherSiteBtnClick() {
    const { facilityList, supervisorList } = this.state;

    this.setState({
      addNewPracticumSite: true,
      facility: facilityList[0]._id,
      supervisor: supervisorList[0]._id,
      emailAddress: supervisorList[0].email[0].emailAddress,
      phoneNumber: supervisorList[0].phone[0].phoneNumber,
      term: 'fall',
      showConfirmation: false,
      errorAddingPracticumSite: false,
    });
  }

  getFacilitiesSupervisors() {
    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    const urlFac = `${process.env.REACT_APP_API_URL}/facility`;
    const urlSup = `${process.env.REACT_APP_API_URL}/clinical-supervisor`;

    const getFacilities = axios.get(urlFac, config);
    const getSupervisors = axios.get(urlSup, config);

    axios
      .all([getFacilities, getSupervisors])
      .then(
        axios.spread((...responses) => {
          const facilityList = responses[0].data;
          const supervisorList = responses[1].data;
          this.setState({
            loading: false,
            facilityList,
            supervisorList,
            facility: facilityList[0]._id,
            supervisor: supervisorList[0]._id,
            emailAddress: supervisorList[0].email[0].emailAddress,
            phoneNumber: supervisorList[0].phone[0].phoneNumber,
          });
        }),
      )
      .catch((error) => {
        console.error(error);
      });
  }

  addPracticumSite() {
    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    const url = `${process.env.REACT_APP_API_URL}/practicum-site`;

    const { facility, supervisor, emailAddress, term } = this.state;
    const { student, academicYear, currentProgram, professionalConcentration } = this.props;

    axios
      .post(
        url,
        {
          student,
          facility,
          supervisor,
          email: emailAddress,
          academicYear,
          term,
          currentProgram,
          professionalConcentration,
        },
        config,
      )
      // eslint-disable-next-line no-unused-vars
      .then((response) => {
        this.setState({ loading: false, showConfirmation: true });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ loading: false, errorAddingPracticumSite: true });
      });
  }

  renderPracticumSiteForm() {
    const {
      facilityList,
      supervisorList,
      term,
      facility,
      supervisor,
      emailAddress,
      phoneNumber,
      errorAddingPracticumSite,
    } = this.state;

    const selectedSupervisor = supervisorList.filter((sup) => sup._id === supervisor);

    return (
      <div className="mt-5">
        {(facilityList.length === 0 || supervisorList.length === 0) && (
          <p>Please add Facilities and/or Clinical Supervisors before adding a Practicum Site</p>
        )}
        <Form>
          {/* Add Practicum Site - Q1 */}
          <Row>
            <Col md={{ size: 3, offset: 1 }} className="text-md-right">
              <Label for="term">Term</Label>
            </Col>
            <Col sm={4}>
              <Input
                type="select"
                id="term"
                name="term"
                value={term}
                onChange={this.handleInputChange}
              >
                <option value="fall">Fall</option>
                {/* <option value="winter">Winter</option> */}
                <option value="spring">Spring</option>
                {/* <option value="summer">Summer</option> */}
              </Input>
            </Col>
          </Row>
          {/* // Add Practicum Site - Q1 */}
          {/* Add Practicum Site - Q2 */}
          <Row className="mt-3">
            <Col md={{ size: 3, offset: 1 }} className="text-md-right">
              <Label for="facility">Facility</Label>
            </Col>
            <Col sm={4}>
              <Input
                type="select"
                id="facility"
                name="facility"
                value={facility}
                onChange={this.handleInputChange}
              >
                {facilityList.map((fac) => (
                  <option key={fac._id} value={fac._id}>{`${fac.name} | ${fac.department}`}</option>
                ))}
              </Input>
            </Col>
          </Row>
          {/* // Add Practicum Site - Q2 */}
          {/* Add Practicum Site - Q3 */}
          <Row className="mt-3">
            <Col md={{ size: 3, offset: 1 }} className="text-md-right">
              <Label for="supervisor">Supervisor Name</Label>
            </Col>
            <Col sm={4}>
              <Input
                type="select"
                id="supervisor"
                name="supervisor"
                value={supervisor}
                onChange={this.handleSupervisorChange}
              >
                {supervisorList.map((sup) => (
                  <option
                    key={sup._id}
                    value={sup._id}
                  >{`${sup.firstName} ${sup.lastName} | ${sup.title}`}</option>
                ))}
              </Input>
            </Col>
          </Row>
          {/* // Add Practicum Site - Q3 */}
          {/* Add Practicum Site - Q4 */}
          <Row className="mt-3">
            <Col md={{ size: 3, offset: 1 }} className="text-md-right">
              <Label for="emailAddress">Supervisor Email</Label>
            </Col>
            <Col sm={4}>
              <Input
                type="select"
                id="emailAddress"
                name="emailAddress"
                value={emailAddress}
                onChange={this.handleInputChange}
              >
                {selectedSupervisor[0].email.map((email) => (
                  <option
                    key={email._id}
                    value={email.emailAddress}
                  >{`${email.emailAddress}`}</option>
                ))}
              </Input>
            </Col>
          </Row>
          {/* // Add Practicum Site - Q4 */}
          {/* Add Practicum Site - Q5 */}
          <Row className="mt-3">
            <Col md={{ size: 3, offset: 1 }} className="text-md-right">
              <Label for="phoneNumber">Supervisor Phone Number</Label>
            </Col>
            <Col sm={4}>
              <Input
                type="select"
                id="phoneNumber"
                name="phoneNumber"
                value={phoneNumber}
                onChange={this.handleInputChange}
              >
                {selectedSupervisor[0].phone.map((phone) => (
                  <option
                    key={phone._id}
                    value={phone.phoneNumber}
                  >{`${phone.phoneNumber}`}</option>
                ))}
              </Input>
            </Col>
          </Row>
          {/* // Add Practicum Site - Q5 */}
          <FormGroup row className="submitBlock mt-4">
            <Col md={12} className="text-center">
              <Button color="info" onClick={this.onNewSiteSubmitBtnClick} className="ml-xs">
                Add Site
              </Button>
              <Button color="inverse" onClick={this.onNewSiteCancelBtnClick} className="ml-xs">
                Cancel
              </Button>
            </Col>
          </FormGroup>
        </Form>
        {errorAddingPracticumSite && (
          <Row className="mt-4 text-center">
            <Col>
              <p className="text-danger">
                There was an error adding the Practicum Site. Please verify the information is
                correct and try again.
              </p>
            </Col>
          </Row>
        )}
      </div>
    );
  }

  renderSiteAddedConfirmation() {
    return (
      <div>
        <Row>
          <Col className="text-center">
            <h3>Practicum Site Successfully Added!</h3>
            <Form>
              <FormGroup row className="submitBlock">
                <Col md={12} className="text-center">
                  <Button color="info" onClick={this.onAddAnotherSiteBtnClick} className="ml-xs">
                    Click Here to Add Another Practicum Site
                  </Button>
                </Col>
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    const { loading, showConfirmation, addNewPracticumSite } = this.state;
    return (
      <div>
        <Row>
          <Col>
            {loading && (
              <div>
                <Row className="text-center">
                  <Col>
                    <img src={loadingSpinner} alt="loading spinner" className="img-responsive" />
                  </Col>
                </Row>
              </div>
            )}
            {(() => {
              if (!loading && !showConfirmation) {
                return (
                  <div>
                    <Row className="mt-4">
                      <Col>
                        <Button
                          color={addNewPracticumSite ? 'dark' : 'inverse'}
                          onClick={this.onAddNewPracticumSiteBtnClick}
                          active={addNewPracticumSite}
                        >
                          Add Practicum Site Form
                        </Button>
                      </Col>
                    </Row>
                    {addNewPracticumSite && (
                      <Row>
                        <Col>{this.renderPracticumSiteForm()}</Col>
                      </Row>
                    )}
                  </div>
                );
              }
              if (!loading && showConfirmation) {
                return this.renderSiteAddedConfirmation();
              }
              return null;
            })()}
          </Col>
        </Row>
      </div>
    );
  }
}

AddPracticumSite.propTypes = {
  student: PropTypes.string.isRequired,
  academicYear: PropTypes.number.isRequired,
  currentProgram: PropTypes.string.isRequired,
  professionalConcentration: PropTypes.string.isRequired,
};

export default AddPracticumSite;
