/* eslint-disable no-underscore-dangle */
import React from 'react';
// import { Link } from 'react-router-dom';
import {
  Form,
  FormGroup,
  Input,
  // InputGroup,
  // InputGroupAddon,
  Label,
  Row,
  Col,
  Button,
} from 'reactstrap';
import axios from 'axios';
import auth0Client from '../../Auth/Auth';

import Widget from '../Widget/Widget';

import loadingSpinner from '../../images/Eclipse-1s-200px.svg';

const currentDate = new Date();

class AddStudent extends React.Component {
  constructor(props) {
    super(props);
    this.onAddNewUserBtnClick = this.onAddNewUserBtnClick.bind(this);
    this.onCurrentProgramBtnClick = this.onCurrentProgramBtnClick.bind(this);
    this.onNewUserSubmitBtnClick = this.onNewUserSubmitBtnClick.bind(this);
    this.onNewUserCancelBtnClick = this.onNewUserCancelBtnClick.bind(this);
    this.onAddAnotherUserBtnClick = this.onAddAnotherUserBtnClick.bind(this);
    this.getFaculty = this.getFaculty.bind(this);
    this.addStudent = this.addStudent.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.renderStudentForm = this.renderStudentForm.bind(this);
    this.renderUserAddedConfirmation = this.renderUserAddedConfirmation.bind(this);

    this.state = {
      firstName: '',
      lastName: '',
      internshipSelect: 'No',
      advisorSelect: '',
      concentrationSelect: 'Optional (Effective Fall 2014)',
      currentProgram: '',
      startYearSelect: currentDate.getFullYear(),
      yearSelect: '1',
      idNumber: '',
      emailAddress: '',
      facultyList: '',
      userPassword: '',
      addNewStudent: false,
      showConfirmation: false,
      loading: false,
      errorAddingStudent: false,
    };
  }

  handleInputChange(e) {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  onAddNewUserBtnClick() {
    const { addNewStudent } = this.state;
    this.setState({
      addNewStudent: !addNewStudent,
      loading: true,
    });
    this.getFaculty();
  }

  onCurrentProgramBtnClick(sectionValue) {
    this.setState({
      currentProgram: sectionValue,
    });
  }

  onNewUserSubmitBtnClick() {
    this.setState({
      loading: true,
    });
    this.addStudent();
  }

  onNewUserCancelBtnClick() {
    this.setState({
      firstName: '',
      lastName: '',
      internshipSelect: 'No',
      advisorSelect: '',
      concentrationSelect: 'Optional (Effective Fall 2014)',
      currentProgram: '',
      startYearSelect: currentDate.getFullYear(),
      yearSelect: '1',
      idNumber: '',
      emailAddress: '',
      facultyList: '',
      userPassword: '',
      addNewStudent: false,
      showConfirmation: false,
      loading: false,
      errorAddingStudent: false,
    });
  }

  onAddAnotherUserBtnClick() {
    const { facultyList } = this.state;

    this.setState({
      firstName: '',
      lastName: '',
      internshipSelect: 'No',
      advisorSelect: facultyList[0]._id,
      concentrationSelect: 'Optional (Effective Fall 2014)',
      currentProgram: '',
      startYearSelect: currentDate.getFullYear(),
      yearSelect: '1',
      idNumber: '',
      emailAddress: '',
      userPassword: '',
      addNewStudent: true,
      showConfirmation: false,
      errorAddingStudent: false,
    });
  }

  getFaculty() {
    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    const url = `${process.env.REACT_APP_API_URL}/faculty`;

    axios
      .get(url, config)
      .then((response) => {
        const facultyList = response.data;
        this.setState({ loading: false, advisorSelect: facultyList[0]._id, facultyList });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ loading: false, errorAddingStudent: true });
      });
  }

  addStudent() {
    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    const url = `${process.env.REACT_APP_API_URL}/student`;

    const {
      firstName,
      lastName,
      internshipSelect,
      advisorSelect,
      concentrationSelect,
      currentProgram,
      startYearSelect,
      yearSelect,
      idNumber,
      emailAddress,
    } = this.state;

    const internship = internshipSelect === 'Yes';

    axios
      .post(
        url,
        {
          firstName,
          lastName,
          email: emailAddress,
          idNumber,
          currentProgram,
          advisor: advisorSelect,
          professionalConcentration: concentrationSelect,
          startYear: startYearSelect,
          year: yearSelect,
          internship,
        },
        config,
      )
      .then((response) => {
        console.log(response);
        this.setState({
          loading: false,
          userPassword: response.data.password,
          showConfirmation: true,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ loading: false, errorAddingStudent: true });
      });
  }

  renderStudentForm() {
    const {
      firstName,
      lastName,
      internshipSelect,
      advisorSelect,
      concentrationSelect,
      currentProgram,
      startYearSelect,
      yearSelect,
      idNumber,
      emailAddress,
      facultyList,
      errorAddingStudent,
    } = this.state;

    const yearArr = [];
    const currentYear = currentDate.getFullYear();
    yearArr.push(currentYear);

    for (let i = 1; i < 15; i += 1) {
      yearArr.push(currentYear - i);
    }

    return (
      <div className="mt-4">
        {/* Add Student - Q1 */}
        <Form>
          <Row>
            <Col sm={3} className="text-md-right">
              <Label for="firstName">First Name</Label>
            </Col>
            <Col sm={6}>
              <Input
                type="text"
                id="firstName"
                name="firstName"
                value={firstName}
                onChange={this.handleInputChange}
              />
            </Col>
          </Row>
          {/* // Add Student - Q1 */}
          {/* Add Student - Q2 */}
          <Row className="mt-3">
            <Col sm={3} className="text-md-right">
              <Label for="lastName">Last Name</Label>
            </Col>
            <Col sm={6}>
              <Input
                type="text"
                id="lastName"
                name="lastName"
                value={lastName}
                onChange={this.handleInputChange}
              />
            </Col>
          </Row>
          {/* // Add Student - Q2 */}
          {/* Add Student - Q3 */}
          <Row className="mt-3">
            <Col sm={3} className="text-md-right">
              <Label for="internshipSelect">Internship</Label>
            </Col>
            <Col sm={6}>
              <Input
                type="select"
                name="internshipSelect"
                id="internshipSelect"
                value={internshipSelect}
                onChange={this.handleInputChange}
              >
                <option>No</option>
                <option>Yes</option>
              </Input>
            </Col>
          </Row>
          {/* // Add Student - Q3 */}
          {/* Add Student - Q4 */}
          <Row className="mt-3">
            <Col sm={3} className="text-md-right">
              <Label for="advisorSelect">Faculty Advisor</Label>
            </Col>
            <Col sm={6}>
              <Input
                type="select"
                name="advisorSelect"
                id="advisorSelect"
                value={advisorSelect}
                onChange={this.handleInputChange}
              >
                {facultyList.map((fac) => (
                  <option
                    key={fac._id}
                    value={fac._id}
                  >{`${fac.firstName} ${fac.middleInitial} ${fac.lastName}, ${fac.title}`}</option>
                ))}
              </Input>
            </Col>
          </Row>
          {/* // Add Student - Q4 */}
          {/* Add Student - Q5 */}
          <Row className="mt-3">
            <Col sm={3} className="text-md-right">
              <Label for="concentrationSelect">Professional Concentration</Label>
            </Col>
            <Col sm={6}>
              <Input
                type="select"
                name="concentrationSelect"
                id="concentrationSelect"
                value={concentrationSelect}
                onChange={this.handleInputChange}
              >
                <option>Clinical Child</option>
                <option>Clinical Health Psychology</option>
                <option>Health Psychology</option>
                <option>Neuroscience & Neuropsychology</option>
                <option>Optional (Effective Fall 2014)</option>
                <option>Primary Care</option>
                <option>Social Cultural Behavioral Health</option>
              </Input>
            </Col>
          </Row>
          {/* // Add Student - Q5 */}
          {/* Add Student - Q6 */}
          <Row className="mt-3">
            <Col sm={3} className="text-md-right">
              <Label>Current Program</Label>
            </Col>
            <Col sm={6}>
              <Button
                className="btn-form"
                color={currentProgram === 'Ph.D.' ? 'dark' : 'inverse'}
                onClick={() => this.onCurrentProgramBtnClick('Ph.D.')}
                active={currentProgram === 'Ph.D.'}
              >
                Ph.D.
              </Button>
              <Button
                className="btn-form"
                color={currentProgram === 'Psy.D.' ? 'dark' : 'inverse'}
                onClick={() => this.onCurrentProgramBtnClick('Psy.D.')}
                active={currentProgram === 'Psy.D.'}
              >
                Psy.D.
              </Button>
            </Col>
          </Row>
          {/* // Add Student - Q6 */}
          {/* Add Student - Q7 */}
          <Row className="mt-3">
            <Col sm={3} className="text-md-right">
              <Label for="startYearSelect">Enrollment Year</Label>
            </Col>
            <Col sm={6}>
              <Input
                type="select"
                name="startYearSelect"
                id="startYearSelect"
                value={startYearSelect}
                onChange={this.handleInputChange}
              >
                {yearArr.map((year) => (
                  <option key={year} value={year}>{`${year}`}</option>
                ))}
              </Input>
            </Col>
          </Row>
          {/* // Add Student - Q7 */}
          {/* Add Student - Q8 */}
          <Row className="mt-3">
            <Col sm={3} className="text-md-right">
              <Label for="yearSelect">Year</Label>
            </Col>
            <Col sm={6}>
              <Input
                type="select"
                name="yearSelect"
                id="yearSelect"
                value={yearSelect}
                onChange={this.handleInputChange}
              >
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
              </Input>
            </Col>
          </Row>
          {/* // Add Student - Q8 */}
          {/* Add Student - Q9 */}
          <Row className="mt-3">
            <Col sm={3} className="text-md-right">
              <Label for="idNumber">ID Number</Label>
            </Col>
            <Col sm={6}>
              <Input
                type="text"
                id="idNumber"
                name="idNumber"
                maxLength="8"
                value={idNumber}
                onChange={this.handleInputChange}
              />
            </Col>
          </Row>
          {/* // Add Student - Q9 */}
          {/* Add Student - Q10 */}
          <Row className="mt-3">
            <Col sm={3} className="text-md-right">
              <Label for="emailAddress">Email</Label>
            </Col>
            <Col sm={6}>
              <Input
                type="text"
                id="emailAddress"
                name="emailAddress"
                value={emailAddress}
                onChange={this.handleInputChange}
              />
            </Col>
          </Row>
          {/* // Add Student - Q10 */}
          <FormGroup row className="submitBlock">
            <Col md={12} className="text-center">
              <Button color="info" onClick={() => this.onNewUserSubmitBtnClick()} className="ml-xs">
                Add User
              </Button>
              <Button
                color="inverse"
                onClick={() => this.onNewUserCancelBtnClick()}
                className="ml-xs"
              >
                Cancel
              </Button>
            </Col>
          </FormGroup>
        </Form>
        {errorAddingStudent && (
          <Row className="mt-4 text-center">
            <Col>
              <p className="text-danger">
                There was an error adding the Student. Please verify the information is correct and
                try again.
              </p>
            </Col>
          </Row>
        )}
      </div>
    );
  }

  renderUserAddedConfirmation() {
    const { userPassword } = this.state;
    return (
      <div>
        <Row className="mt-4">
          <Col className="text-center">
            <h3>User Added!</h3>
            <p>A confirmation email containing the login information has been sent to the user.</p>
            <p>
              Password is <strong>{userPassword}</strong>
            </p>
            <Form>
              <FormGroup row className="submitBlock">
                <Col className="text-center">
                  <Button
                    color="info"
                    onClick={() => this.onAddAnotherUserBtnClick()}
                    className="ml-xs"
                  >
                    Click Here to Add Another User
                  </Button>
                </Col>
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    const { loading, showConfirmation, addNewStudent } = this.state;

    return (
      <div>
        <Row>
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-plus" /> Add New Student
                </h5>
              }
            >
              {loading && (
                <div>
                  <Row className="text-center">
                    <Col>
                      <img src={loadingSpinner} alt="loading spinner" className="img-responsive" />
                    </Col>
                  </Row>
                </div>
              )}
              {(() => {
                if (!loading && !showConfirmation) {
                  return (
                    <div>
                      <Row className="mt-4">
                        <Col>
                          <Button
                            color={addNewStudent ? 'dark' : 'inverse'}
                            onClick={this.onAddNewUserBtnClick}
                            active={addNewStudent}
                          >
                            Add Student Form
                          </Button>
                        </Col>
                      </Row>
                      {addNewStudent && (
                        <Row>
                          <Col>{this.renderStudentForm()}</Col>
                        </Row>
                      )}
                      {!addNewStudent && (
                        <Row className="mt-4">
                          <Col>
                            {/* <Button className="ml-2" color="inverse" onClick={selectFile}>
                            Import Students From Excel Template
                          </Button> */}
                            <div>
                              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                              <label>
                                <input type="file" style={{ display: 'none' }} />
                                <i className="fa fa-cloud-upload" /> Import Students From Excel
                                Template
                              </label>
                            </div>
                          </Col>
                          <Col className="text-right">
                            <a
                              href={`${process.env.PUBLIC_URL}/addStudent.xlsx`}
                              download="addStudent.xlsx"
                            >
                              <h6 className="pt-2">Download Excel Template</h6>
                            </a>
                          </Col>
                        </Row>
                      )}
                    </div>
                  );
                }
                if (!loading && showConfirmation) {
                  return this.renderUserAddedConfirmation();
                }
                return null;
              })()}
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }
}

export default AddStudent;
