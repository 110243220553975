/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/function-component-definition */
import React from 'react';
import { Container, Button } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import auth0Client from '../../Auth/Auth';

function Home(props) {
  if (auth0Client.isAuthenticated()) {
    props.history.replace('/dashboard');
    return <div />;
  }

  return (
    <Container>
      {auth0Client.isAuthenticated() && (
        <h4 className="text-center">
          You are logged in! You can now view your <Link to="/profile">profile</Link>.
        </h4>
      )}
      {!auth0Client.isAuthenticated() && (
        <h3 className="mt-4 text-center text-white">
          {' '}
          <Button
            style={{ fontSize: '1.5rem', color: '#f8f8f8' }}
            color="link"
            onClick={auth0Client.login}
          >
            You are not logged in! Please login to continue.
          </Button>
        </h3>
      )}
    </Container>
  );
}

export default withRouter(Home);
